import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultParamKey } from "src/shared/constants/urlParams";

const useCheckUrlParam = ({ paramKey = defaultParamKey, paramValue }) => {
  const [searchParams] = useSearchParams();
  const [isActiveParam, setIsActiveParam] = useState(searchParams.get(paramKey) === paramValue);
  const currentParam = searchParams.get(paramKey);

  useEffect(() => {
    if (currentParam === paramValue) {
      return setIsActiveParam(true);
    }

    setIsActiveParam(false);
  }, [currentParam, paramValue]);

  return isActiveParam;
};

export default useCheckUrlParam;

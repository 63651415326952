import React, { useCallback } from "react";
import { ReactComponent as CreditCardIcon } from "../../../../images/credit-card.svg";
import { ReactComponent as CheckIcon } from "../../../../images/check.svg";
import { t } from "i18next";
import styles from "./styles.module.scss";
import { calculateLastDigits } from "src/components/MoneyManagement/helpers/calculateLastDigits";

const CardItem = (props) => {
	const { card, setNewCard } = props;

	const selectedCardId = localStorage.getItem("selectedCardId");
	const selectedCard = selectedCardId === card.id;

	const selectCard = useCallback(() => {
		setNewCard(card);
		localStorage.setItem("selectedCardId", card.id);
	}, [card, setNewCard]);

	return (
		<ul className={styles.cardList}>
			<li
				className={styles.navCard}
				key={card.id}
				onClick={() => selectCard(card)}
			>
				<div className={styles.cardsSection}>
					<div className={styles.cardsItems}>
						<CreditCardIcon className={styles.creditCard} />
						<p className={styles.titleCard}>{calculateLastDigits(card)}</p>
						{selectedCard && <p className={styles.selectedCard}>{t("main")}</p>}
					</div>
					<div>
						{selectedCard && <CheckIcon className={styles.checkIcon} />}
					</div>
				</div>
			</li>
		</ul>
	);
};

export default CardItem;

import {
  GET_HISTORY,
  GET_HISTORY_ITEM,
  SET_ORDER_ID,
  ERROR_MSG,
  CLEAR_ERROR,
  } from './history.actions'
   
  const initialState = {
    doneOrders: [],
    doneOrder: {},
    orderId: "",
    orderStatus: false,
    errorStatus: false,
    errorMsg: "",
  }
  
  function history(state = initialState, action) {
    switch (action.type) { 
      case GET_HISTORY:
        return {...state, doneOrders: action.payload, orderStatus: false }
      case GET_HISTORY_ITEM:
        return {...state, doneOrder: action.payload, orderStatus: true }
      case SET_ORDER_ID:
        return {...state, orderId: action.payload, orderStatus: false }
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      default:
        return { ...state }
    }
  }
  export default history
  
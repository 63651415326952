import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { ReactComponent as BackIcon } from '../../images/back.svg'

import { t } from 'i18next'

import './apkpolicy.scss'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'

class ApkPolicy extends Component {
  render() {
    const { loginStatus } = this.props
    return (
      <Fragment>
        <div className="container-fluid p-0 m-0">
          <div className="row m-0">
            <div className="apkpolicy-section col-sm-12 p-0">
            <MobileTopBarHeight/>
              <div className="top-nav">
                <div className="top-nav_back">
                  <NavLink to={loginStatus ? '/support' : '/registration'}>
                    <BackIcon className="ArrowLeft" />
                  </NavLink>
                </div>
                <div className="top-nav_text">
                  <p>{t('privacy_policy')}</p>
                </div>
              </div>
              <div className="main-text">
                <p className="new-line">{t('main_text_new_line_first')}</p>
                <p className="new-line">{t('main_text_new_line_second')}</p>
                <p className="new-line">{t('main_text_new_line_third')}</p>
                <p className="new-line">{t('main_text_new_line_fourth')}</p>
                <p className="new-line">{t('main_text_new_line_fifth')}</p>
                <p className="new-line">{t('main_text_new_line_sixth')}</p>
                <p className="new-line">{t('main_text_new_line_seventh')}</p>
                <p className="new-line">{t('main_text_new_line_eighth')}</p>
                <p className="paragraph">{t('main_text_new_line_ninth')}</p>
                <p className="new-line">{t('main_text_new_line_tenth')}</p>
                <p className="new-line">{t('main_text_new_line_eleventh')}</p>
                <p className="new-line">{t('main_text_new_line_twelfth')}</p>
                <p className="new-line">{t('main_text_new_line_thirtieth')}</p>
                <p className="paragraph">{t('information_disclosure')}</p>
                <p className="new-line">{t('main_text_new_line_fourteenth')}</p>
                <p className="new-line">{t('main_text_new_line_fifteenth')}</p>
                <p className="paragraph">{t('information_storage')}</p>
                <p className="new-line">{t('main_text_new_line_sixteenth')}</p>
                <p className="paragraph">{t('security')}</p>
                <p className="new-line">
                  {t('main_text_new_line_seventeenth')}
                </p>
                <p className="new-line">{t('main_text_new_line_eighteenth')}</p>
                <p className="new-line">{t('main_text_new_line_nineteenth')}</p>
                <p className="new-line">{t('main_text_new_line_twentieth')}</p>
                <p className="new-line">
                  {t('main_text_new_line_twentyFirst')}
                </p>
                <p className="new-line">
                  {t('main_text_new_line_twentySecond')}
                </p>
                <p className="paragraph">{t('changing_privacy_policy')}</p>
                <p className="new-line">
                  {t('main_text_new_line_twentyThird')}
                </p>
                <p className="new-line">
                  {t('main_text_new_line_twentyFourth')}
                </p>
                {/* {loginStatus ? null : (
                  <button className="confirm">
                    <NavLink to="/registration">{t('accept')}</NavLink>
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.login.loginStatus,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ApkPolicy)

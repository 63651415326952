import React, { memo, useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import useDataCards from "src/hooks/useDataCards";
import OperationType from "./components/OperationType";
import { maxLimitPaymentCards } from "src/config-in-git";
import { getValuteSymbol } from "src/services/getValuteSymbol";

const userPhone = localStorage.getItem("userPhone");

const FinancialOperation = (props) => {
  const {
    profinfo,
    operationParams,
    userCardsList,
    setNewCard,
    cardFormData,
    newCard,
    showModalAlert,
    getMoney,
    showMAlert,
    sendMoney,
    convertBonuses,
    getMStatus,
    setMStatus,
    bonusStatus,
    getDriverInfo,
    ClearM,
    getUserCards,
    addCardData,
		profLoading
  } = props;

  const { balance, bonuses, currency, hasWithdrawRequests } = profinfo || {};

  const [mainAlertText, setMainAlertText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [amountValue, setAmountValue] = useState("");

  const { cards } = useDataCards(userCardsList);

  const isLimitedCards = userCardsList?.totalCards > maxLimitPaymentCards;

  const handleTransferAmountChange = useCallback(
    (event) => {
      const { value } = event.target;

      const updatedAmount = value.replace(/^0+/, "").slice(0, 7);

      if (updatedAmount === "") {
        setAmountValue("");
      }

      if (/^\d+$/.test(updatedAmount)) {
        if (updatedAmount <= balance && operationParams === "withdraw") {
          setAmountValue(updatedAmount);
        }

        if (updatedAmount <= bonuses && operationParams === "bonuses") {
          setAmountValue(updatedAmount);
        }

        if (operationParams === "replenish") {
          setAmountValue(updatedAmount);
        }
      }
    },
    [balance, bonuses, operationParams],
  );

  const getBalanceDriver = useCallback(() => {
    if (operationParams === "bonuses") {
      return `${bonuses ? bonuses : 0}${getValuteSymbol(currency)}`;
    }

    const formattedBalance = balance ? parseFloat(balance).toFixed(2) : 0;

    return `${formattedBalance}${getValuteSymbol(currency)}`;
  }, [balance, bonuses, operationParams, currency]);

  // Function to handle money operation
  const handleMoneyOperation = useCallback(
    async (operation, alertMessageKey) => {
      const hasWithdrawOperation = operationParams === "withdraw";

      if (amountValue > balance && hasWithdrawOperation) {
        setMainAlertText(t(alertMessageKey));
        showModalAlert(!showMAlert);
      } else {
        const driverId = localStorage.getItem("driverId");
        const selectedCardId = localStorage.getItem("selectedCardId");

        const data = {
          driverId,
          cardId: selectedCardId,
          amount: amountValue,
        };

        await operation(data);
        await getDriverInfo(true);

        setAmountValue("");
      }
    },
    [operationParams, amountValue, balance, showModalAlert, showMAlert, getDriverInfo],
  );

  // Callback functions for different money operations
  const tryGetM = useCallback(
    () => handleMoneyOperation(getMoney, "not_enough_funds_on_balance"),
    [getMoney, handleMoneyOperation],
  );
  const trySendM = useCallback(() => handleMoneyOperation(sendMoney), [handleMoneyOperation, sendMoney]);
  const tryConvertBonuses = useCallback(
    () => handleMoneyOperation(convertBonuses),
    [convertBonuses, handleMoneyOperation],
  );

  // Set in store seleted card id
  useEffect(() => {
    const findSelectedCard = () => {
      const selectedCardId = localStorage.getItem("selectedCardId");
      const cardId = cards.find((card) => card.id === selectedCardId);

      if (cardId) {
        setNewCard(cardId);
      } else {
        setNewCard("");
      }
    };

    findSelectedCard();
  }, [cards, setNewCard]);

  //Requesting user cards
  useEffect(() => {
    const fetchUserCards = async () => {
      const phone = localStorage.getItem("userPhone");
      const currentCountry = localStorage.getItem("country_code");

      const userInfo = {
        country: currentCountry,
        phone,
      };

      await Promise.all([getUserCards(), addCardData(userInfo)]);

      ClearM();
    };

    if (!cards.length && operationParams !== "bonuses") {
      fetchUserCards();
    }
  }, [ClearM, addCardData, cards.length, getUserCards, operationParams]);

  // Show a modal window depending on the operation
  useEffect(() => {
    const showOperationModal = (message) => {
      setMainAlertText(message);
      showModalAlert(!showMAlert);
      ClearM();
    };

    if (bonusStatus) {
      showOperationModal(t("translation_done"));
    }

    if (getMStatus) {
      showOperationModal(t("withdrawal_request_accepted"));
    }

    if (setMStatus) {
      showOperationModal(t("request_has_been_sent"));
    }
  }, [ClearM, bonusStatus, getMStatus, setMStatus, showMAlert, showModalAlert]);

  const OperationComponent = () => {
    const commonProps = {
      cardFormData,
      cards,
      getBalanceDriver,
      showMAlert,
      handleTransferAmountChange,
      amountValue,
      mainAlertText,
      openModal,
      setOpenModal,
      userPhone,
      hasWithdrawRequests,
      isLimitedCards,
    };

    switch (operationParams) {
      case "withdraw":
        return (
          <OperationType
            getDriverInfo={getDriverInfo}
            handleOperation={tryGetM}
            newCard={newCard}
            profLoading={profLoading}
						setNewCard={setNewCard}
						showWithdraw
            {...commonProps}
          />
        );
      case "replenish":
        return (
          <OperationType
            handleOperation={trySendM}
            newCard={newCard}
            setNewCard={setNewCard}
            showReplenish
            {...commonProps}
          />
        );
      case "bonuses":
        return <OperationType handleOperation={tryConvertBonuses} showBonuses {...commonProps} />;
      default:
        return null;
    }
  };

  return OperationComponent();
};

export default memo(FinancialOperation);

import { Navigate } from "react-router-dom";

const PrivateRoute = ({ Component }) => {
  const accessToken = localStorage.getItem("accessToken");
  const registrationStep = JSON.parse(localStorage.getItem("registrationStep"));

  if (!accessToken) {
    return <Navigate replace to="/" />;
  }

  if (registrationStep === 1) {
    return <Navigate replace to="/registration/uploadphoto" />;
  }

  return <Component />;
};

export default PrivateRoute;

import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as DownArrowIcon } from "../../images/down-arrow.svg";
import { ReactComponent as BackIcon } from "../../images/back.svg";
import { ReactComponent as CheckIcon } from "../../images/check.svg";

import { addAvto, getAvtos } from "../Cars/cars.actions";
import { clearError, getColors } from "../AddCar/addcar.actions";
import { t } from "i18next";


import { getBrands, getModels, getPreferences } from "../AddCar/addcar.actions";

import "./addcar.scss";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import Select from "../../shared/ui/Select";
import { classNames } from "../../shared/lib/classNames/classNames";
import { Input } from "../../shared/ui/Input/inputs";
import { CircularProgressBar } from "../../shared/ui/CircularProgressBar";
import { getRedirectPage } from "../../services/getRedirectPage";
import { getDriverInfo } from "../RegistrationPage/registration.actions";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";
import generateYearsArray from "src/services/generateYearsArray";
import { allowCarYears } from "src/shared/constants";

const chkYear = new RegExp(/^[0-9]{0,4}$/);
const chkGovNumber = new RegExp(/^[a-z0-9а-яйЙыЫёЁіІїЇєЄ]{0,12}$/i);
const years = generateYearsArray(allowCarYears);

class AddCar extends Component {
  state = {
    brand: "",
    errorB: "",
    wrongBrand: "",
    brandId: "",

    model: "",
    errorM: "",
    wrongModel: "",
    modelId: "",

    year: "",
    errorY: "",
    wrongYear: "",

    number: "",
    errorN: "",
    wrongNumber: "",

    color: "",
    errorC: "",
    wrongColor: "",
    colorId: "",

    selectedPrefs: [],

    addBtnStatus: true,

    isOpen: false,
    isOpenBrandAuto: false,
    isOpenModelAuto: false,
    isOpenYearAuto: false,
    isOpenColorAuto: false,
  };

  componentDidMount() {
    this.props.getDriverInfo();
    this.props.getAvtos();
    this.props.getBrands();
    this.props.getModels();
    this.props.getColors();
    this.props.getPreferences();
  }

  componentDidUpdate() {
    const { cars, driver } = this.props;
    const { registrationStep } = driver || {};
    const { brandId, orderPreferences } = cars?.[0] || {};
    if (
      cars?.length &&
      brandId !== this.state.brandId &&
      registrationStep === 1
    ) {
      this.setState({
        brandId,
        modelId: cars[0].modelId,
        year: cars[0].manufactureYear,
        number: cars[0].govNumber,
        colorId: cars[0].colorId,
        selectedPrefs: orderPreferences?.map((carInfo) => carInfo.id) || [],
      });
    }
  }

  validateAddCarInfo = () => {
    const { brandId, modelId, year, number, colorId } = this.state;

    if (!brandId || !modelId || !year || !number || !colorId) {
      return true;
    }

    return false;
  };

  ChgYear = (event) => {
    if (chkYear.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  ChgNumber = (event) => {
    if (chkGovNumber.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value?.toUpperCase(),
      });
    }
  };

  goBack = () => {
    this.props.navigate("/registration/uploadphoto");
  };

  findMatchingCarNumber = () => {
    if (!this.props.location || !this.props.location.usersCarsGovNumbers) {
      return null;
    }

    return this.props.location.usersCarsGovNumbers?.find(
      (number) => number === this.state.number.toUpperCase(),
    );
  };

  tryAddCar = () => {
    if (this.state.brandId === "") {
      this.setState({
        errorB: true,
        wrongBrand: t("choose_brand_car"),
        model: "",
      });
      return false;
    } else {
      this.setState({
        errorB: false,
        wrongBrand: "",
      });
    }

    if (this.state.modelId === "") {
      this.setState({
        errorM: true,
        wrongModel: t("choose_model_car"),
      });
      return false;
    } else {
      this.setState({
        errorM: false,
        wrongModel: "",
      });
    }

    if (this.state.year === "") {
      this.setState({
        errorY: true,
        wrongYear: t("enter_production_year"),
      });
      return false;
    } else {
      this.setState({
        errorY: false,
        wrongYear: "",
      });
    }

    if (this.state.number.length < 3 || !chkGovNumber.test(this.state.number)) {
      this.setState({
        errorN: true,
        wrongNumber: t("wrong_car_number"),
      });
      return false;
    } else if (this.findMatchingCarNumber()) {
      this.setState({
        errorN: true,
        wrongNumber: t("already_have_car_number"),
      });
      return false;
    } else {
      this.setState({
        errorN: false,
        wrongNumber: "",
      });
    }

    if (this.state.colorId === "") {
      this.setState({
        errorC: true,
        wrongColor: t("choose_car_color"),
      });
      return false;
    } else {
      this.setState({
        errorC: false,
        wrongColor: "",
      });
    }

    const carInfo = {
      brandId: this.state.brandId,
      modelId: this.state.modelId,
      colorId: this.state.colorId,
      manufactureYear: String(this.state.year),
      govNumber: this.state.number.toUpperCase(),
      orderPreferences: this.state.selectedPrefs,
      driverId: this.props.driver.id,
    };

    this.setState({
      addBtnStatus: false,
    });

    this.props.addAvto(carInfo);
  };

  tryClearError = () => {
    this.props.clearError();
  };

  // TODO MY
  getCurrentBrandId = (brandId) => {
    this.setState({
      brandId: brandId,
      modelId: "",
    });
  };

  getCurrentModelId = (modalId) => {
    this.setState({
      modelId: modalId,
    });
  };

  getCurrentYear = (year) => {
    this.setState({
      year: year,
    });
  };

  getCurrentColorId = (colorId) => {
    this.setState({
      colorId: colorId,
    });
  };

  toggleSelectBrand = () => {
    this.setState({
      isOpenBrandAuto: !this.state.isOpenBrandAuto,
      isOpen: !this.state.isOpen,
    });
  };

  toggleSelectModel = () => {
    this.setState({
      isOpenModelAuto: !this.state.isOpenModelAuto,
      isOpen: !this.state.isOpen,
    });
  };

  toggleSelectYear = () => {
    this.setState({
      isOpenYearAuto: !this.state.isOpenYearAuto,
      isOpen: !this.state.isOpen,
    });
  };

  toggleSelectColor = () => {
    this.setState({
      isOpenColorAuto: !this.state.isOpenColorAuto,
      isOpen: !this.state.isOpen,
    });
  };

  changePref = (pref) => {
    const selectedPrefs = [...this.state.selectedPrefs];
    const index = selectedPrefs.indexOf(pref.id);
    if (index === -1) {
      selectedPrefs.push(pref.id);
    } else {
      selectedPrefs.splice(index, 1);
    }
    this.setState({ selectedPrefs });
  };

  handleRedirectBack = () => {
    if (
      this.props.driver.registrationStep >= 2 &&
      !this.props.isFirstRegistration
    ) {
      return getRedirectPage("/cars");
    } else {
      return getRedirectPage("/registration/uploadphoto");
    }
  };

  clearInputValue = () => {
    this.setState({
      number: "",
    });
  };

  render() {
		const {
			brands,
			models,
			colors,
			prefs,
			addStatus,
			errorStatus,
		} = this.props;

    const registrationStep = JSON.parse(
      localStorage.getItem("registrationStep"),
    );

    let brandList = [];
    for (let j = 0; j < brands.length; j++) {
      brandList.push(
        <option key={brands[j].id} value={brands[j].id}>
          {brands[j].name}
        </option>,
      );
    }
    brandList.unshift(<option disabled hidden key={0} selected />);

    let modelList = [];

    if (this.state.brandId !== "") {
      for (let j = 0; j < models.length; j++) {
        if (models[j].name == "540") {
          // console.log('models[j]', models[j])
        }

        if (this.state.brandId == models[j].brandId) {
          modelList.push(models[j]);
        }
      }
      // modelList.unshift(<option selected disabled hidden key={0}></option>)
      // console.log('modelList end', modelList)
    }

    let colorList = [];
    for (let j = 0; j < colors.length; j++) {
      colorList.push(<option value={colors[j].id}>{t(colors[j].name)}</option>);
    }
    colorList.unshift(<option disabled hidden selected />);

    // let prefsList = ''
    const prefsList = prefs.map((pref, i) => {
      const isSelected = this.state.selectedPrefs.includes(pref.id);
      return (
        <li key={pref.id} onClick={() => this.changePref(pref)}>
          {t(pref.name)}
          {isSelected && <CheckIcon className="check_icon" />}
        </li>
      );
    });

    const currentBrand = brands.find(
      (brand) => brand.id === this.state.brandId,
    );
    const currentModel = modelList.find(
      (model) => model.id === this.state.modelId,
    );
    const currentYear = years.find((year) => year === this.state.year);
    const currentColorId = colors.find(
      (color) => color.id === this.state.colorId,
    );

    const isBrandSelector = this.state.isOpenBrandAuto ? (
      <div className="addcar-selection">
        <Select
          currentId={this.state.brandId}
          listItem={brands}
          onSelectOptionId={this.getCurrentBrandId}
          placeholder={t("car_brand_search")}
          searchBlock={true}
          title={t("car_brand_choice")}
          toggleSelect={this.toggleSelectBrand}
          topCustomComponent={<MobileTopBarHeight />}
        />
      </div>
    ) : null;

    const isModelSelector = this.state.isOpenModelAuto ? (
      <div className="addcar-selection">
        <Select
          currentId={this.state.modelId}
          listItem={modelList}
          onSelectOptionId={this.getCurrentModelId}
          placeholder={t("car_model_search")}
          searchBlock={true}
          title={t("car_model_choice")}
          toggleSelect={this.toggleSelectModel}
          topCustomComponent={<MobileTopBarHeight />}
        />
      </div>
    ) : null;

    const isYearSelector = this.state.isOpenYearAuto ? (
      <div className="addcar-selection">
        <Select
          currentId={this.state.year}
          listItem={years}
          onSelectOptionId={this.getCurrentYear}
          title={t("year_of_production")}
          toggleSelect={this.toggleSelectYear}
          topCustomComponent={<MobileTopBarHeight />}
        />
      </div>
    ) : null;

    const isColorSelector = this.state.isOpenColorAuto ? (
      <div className="addcar-selection">
        <Select
          currentId={this.state.colorId}
          listItem={colors}
          onSelectOptionId={this.getCurrentColorId}
          placeholder={t("car_color_search")}
          searchBlock={true}
          title={t("car_color_choice")}
          toggleSelect={this.toggleSelectColor}
          topCustomComponent={<MobileTopBarHeight />}
        />
      </div>
    ) : null;

    if (addStatus && registrationStep === 1) {
      return <Navigate to="/registration/uploadphoto" />;
    }

    if (addStatus && registrationStep >= 2) {
      return <Navigate to="/cars" />;
    }
    return (
      <>
        <div className="addcar-section">
          {" "}
          <div className="container">
            <MobileTopBarHeight />
            {isBrandSelector}
            {isModelSelector}
            {isYearSelector}
            {isColorSelector}
            <div
              className={classNames(
                "addcar-section__main",
                { hidden_block: this.state.isOpen },
                [],
              )}
            >
              <div className="block_title">
                <Link className="back_icon" to={this.handleRedirectBack()}>
                  <BackIcon className="arrowleft_icon" />
                </Link>
              </div>

              <div className="block-photo_phase">
                <div className="info-title_pahse">
                  <p>{t("auto_information")}</p>
                </div>
                <div className="photo_pahse">
                  {this.props.driver?.registrationStep === 1 && (
                    <CircularProgressBar
                      stepValue={this.props.currentCircleStep}
                      valuePercent={this.props.circleCircleFill}
                    />
                  )}
                </div>
              </div>

              <div className="set_auto_block">
                <div style={{ position: "relative" }}>
                  <p>{t("car_brand")}</p>
                  <Button
                    className="btn-select"
                    onClick={this.toggleSelectBrand}
                  >
                    <span
                      className={`select_item ${
                        currentBrand && "select_item--active"
                      }`}
                    >
                      {!currentBrand ? t("car_brand") : currentBrand?.name}
                    </span>
                    <span className="icon-block_down">
                      <DownArrowIcon className="down-icon" />
                    </span>
                  </Button>
                  {this.state.errorB ? (
                    <h5 className="error">{this.state.wrongBrand}</h5>
                  ) : null}
                </div>

                <div style={{ position: "relative" }}>
                  <p>{t("car_model")}</p>
                  <Button
                    className="btn-select"
                    onClick={this.toggleSelectModel}
                  >
                    <span
                      className={`select_item ${
                        currentModel && "select_item--active"
                      }`}
                    >
                      {!currentModel ? t("car_model") : currentModel?.name}
                    </span>
                    <span className="icon-block_down">
                      <DownArrowIcon className="down-icon" />
                    </span>
                  </Button>
                  {this.state.errorM ? (
                    <h5 className="error">{this.state.wrongModel}</h5>
                  ) : null}
                </div>

                <div className="car_type">
                  <div
                    style={{
                      width: "50%",
                      position: "relative",
                      paddingRight: "8px",
                    }}
                  >
                    <p>{t("year_of_production")}</p>
                    <Button
                      className="btn-select"
                      onClick={this.toggleSelectYear}
                    >
                      <span
                        className={`select_item ${
                          currentYear && "select_item--active"
                        }`}
                      >
                        {!currentYear ? t("year_of_production") : currentYear}
                      </span>
                      <span className="icon-block_down move_right">
                        <DownArrowIcon className="down-icon" />
                      </span>
                    </Button>
                    {this.state.errorY ? (
                      <h5 className="error error_height">
                        {this.state.wrongYear}
                      </h5>
                    ) : null}
                  </div>
                  <div style={{ width: "50%", position: "relative" }}>
                    <p>{t("car_color_v2")}</p>
                    <Button
                      className="btn-select"
                      onClick={this.toggleSelectColor}
                    >
                      <span
                        className={`select_item ${
                          currentColorId && "select_item--active"
                        }`}
                      >
                        {!currentColorId
                          ? t("car_color_v2")
                          : t(currentColorId?.name)}
                      </span>
                      <span className="icon-block_down move_right">
                        <DownArrowIcon className="down-icon" />
                      </span>
                    </Button>
                    {this.state.errorC ? (
                      <h5 className="error error_height">
                        {this.state.wrongColor}
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div className="block-number_auto">
                  <p>{t("license_plate")}</p>
                  <div style={{ position: "relative" }}>
                    <Input
                      className={classNames("number_auto", {}, [])}
                      handleClearValue={this.clearInputValue}
                      name="number"
                      onChange={this.ChgNumber}
                      placeholder="AA0000AA"
                      value={this.state.number}
                    />
                    {this.state.errorN ? (
                      <h5 className="error">{this.state.wrongNumber}</h5>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="prefs-block">
                <p>{t("additional_services")}</p>
                <ul className="prefs-list">{prefsList}</ul>
              </div>

              <div className="confirm_block">
                <Button
                  className={classNames(
                    "confirm_btn",
                    { btn_disabled: this.validateAddCarInfo() },
                    [],
                  )}
                  disabled={this.validateAddCarInfo()}
                  onClick={this.tryAddCar}
                >
                  {t("confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    brands: state.addcar.brands,
    models: state.addcar.models,
    colors: state.addcar.colors,
    driver: state.registration.profinfo,
    prefs: state.addcar.preferences,
    addStatus: state.cars.addStatus,
    errorStatus: state.addcar.errorStatus,
    errorMsg: state.addcar.errorMsg,
    cars: state.cars.avtos,
    currentCircleStep: state.addcar.currentCircleStep,
    circleCircleFill: state.addcar.circleCircleFill,
    isFirstRegistration: state.registration.isFirstRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAvto: (carInfo) => dispatch(addAvto(carInfo)),
    clearError: () => dispatch(clearError()),
    getModels: () => dispatch(getModels()),
    getBrands: () => dispatch(getBrands()),
    getColors: () => dispatch(getColors()),
    getAvtos: () => dispatch(getAvtos()),
    getPreferences: () => dispatch(getPreferences()),
    getDriverInfo: () => dispatch(getDriverInfo()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(AddCar));

import { t } from "i18next";
import React from "react";

import { convertCoinsToMoney } from "../../helpers/convertCoinsToMoney";

import styles from "./styles.module.scss";
import { metersToKm } from "src/services/metersToKm";
import { secondsToHoursMin } from "src/services/timeConvertor";

const Statistic = ({
  paymentsTitleDate,
  statistics,
  currency,
  totalAmountInCoins,
}) => {
  const statisticItems = [
    { name: t("trips_count"), value: statistics.orderCount },
    { name: t("online"), value: secondsToHoursMin(statistics.onlineInSeconds) },
    { name: `${t("mileage")} (${t("km")})`, value: metersToKm(statistics.distanceInMeters) },
  ];

  return (
    <div className={styles.statisticWrap}>
      <h3>
        {t("income")} {paymentsTitleDate}
      </h3>
      <p className={styles.statisticMoney}>
        {convertCoinsToMoney({ coins: totalAmountInCoins })}
        {currency}
      </p>
      <ul className={styles.statisticItemsWrap}>
        {statisticItems.map((item, index) => (
          <li key={`${item.name}_${index}`}>
            <p className={styles.statisticItemTitle}>{item.value}</p>
            <p className={styles.statisticItemDesc}>{item.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Statistic;

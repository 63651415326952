import React, { useState } from "react";
import ScrollableLayout from "src/shared/layouts/ScrollableLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { t } from "i18next";
import { connect } from "react-redux";
import styles from "./styles.module.scss";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";

import { rtkApi } from "src/shared/api/rtkApi";
import { useGetIsEnabledDriverDebugInfoQuery } from "./api/userSupportApi";
import { useEffect } from "react";

const UserSupportInfo = (props) => {
  const { profinfo } = props;
  const tableHeaderItems = [t("name"), t("value_translate")];
  const { data, isLoading } = useGetIsEnabledDriverDebugInfoQuery({}, { refetchOnMountOrArgChange: true });
  const [triggerPostDriverDebugInfo] = rtkApi.useLazyPostDriverDebugInfoQuery();
  const [isDisabledSend, setIsDisabledSend] = useState(isLoading || false);

  const rows = [
    {
      name: "driverId",
      translated: t("driver_id_translate"),
      value: localStorage.getItem("driverId"),
      hidden: true,
    },
    {
      name: "gpsSignalBlocked",
      translated: t("gpsSignalBlocked_translate"),
      value: localStorage.getItem("gpsSignalBlocked"),
    },
    { name: "isOnline", translated: t("isOnline_translate"), value: localStorage.getItem("isOnline") },
    {
      name: "readyForOrder",
      translated: t("readyForOrder_translate"),
      value: localStorage.getItem("readyForOrder"),
    },
    {
      name: "acceptDriverCar",
      translated: t("acceptDriverCar_translate"),
      value: localStorage.getItem("acceptDriverCar"),
      hidden: true,
    },
    { name: "userPhone", translated: t("phone_number"), value: localStorage.getItem("userPhone") },
    {
      name: "acceptOrderTime",
      translated: `${t("acceptOrderTime_translate")}, ms`,
      value: localStorage.getItem("acceptOrderTime"),
      hidden: true,
    },
    {
      name: "newOrderRequestManage",
      translated: t("newOrderRequestManage"),
      value: localStorage.getItem("newOrderRequestManage"),
      hidden: true,
    },
    { name: "coords", translated: t("coords_translate"), value: localStorage.getItem("coords") },
    {
      name: "registrationStep",
      translated: t("registrationStep_translate"),
      value: localStorage.getItem("registrationStep"),
      hidden: true,
    },
    { name: "language_name", translated: t("language"), value: localStorage.getItem("language_name") },
    { name: "regionCode", translated: t("regionCode_translate"), value: profinfo.regionCode },
    { name: "regionId", translated: t("regionId_translate"), value: profinfo.regionId, hidden: true },
    {
      name: "farRegionCode",
      translated: t("farRegionCode_translate"),
      value: profinfo.farRegionCode,
      hidden: true,
    },
    { name: "balance", translated: t("balance_translate"), value: profinfo.balance },
    { name: "bonuses", translated: t("bonuses"), value: profinfo.bonuses },
    { name: "branchId", translated: t("branchId_translate"), value: profinfo.branchId, hidden: true },
    { name: "callSign", translated: t("call_sign"), value: profinfo.callSign },
    { name: "cityId", translated: t("cityId_translate"), value: profinfo.cityId, hidden: true },
    { name: "commission", translated: t("commission_translate"), value: profinfo.commission, hidden: true },
    { name: "country", translated: t("country_translate"), value: profinfo.country },
    { name: "currency", translated: t("currency_translate"), value: profinfo.currency },
    {
      name: "orderSearchDistance",
      translated: t("orderSearchDistance_translate"),
      value: profinfo.orderSearchDistance,
    },
    { name: "partnerId", translated: t("partnerId_translate"), value: profinfo.partnerId, hidden: true },
    { name: "email", translated: t("email"), value: profinfo.email },
    { name: "rating", translated: t("rating_translate"), value: profinfo.rating },
  ];

  useEffect(() => {
    if (!isLoading) {
      setIsDisabledSend(!data?.enabled);
    }
  }, [data?.enabled, isLoading]);

  return (
    <div>
      <ScrollableLayout headerOptions={{ headerTitle: t("inform_for_stp") }}>
        <div className={styles.userSupportInfoWrap}>
          <TableContainer className={styles.userSupportInfoContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaderItems.map((item, index) => (
                    <TableCell key={`${item}_${index}`} align="center">
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  if (row.hidden) {
                    return;
                  }
                  return (
                    <TableRow key={`${row.name}_${index}`}>
                      <TableCell className={styles.userSupportInfoRowCell}>
                        {row.translated} <p>({row.name})</p>
                      </TableCell>
                      <TableCell className={styles.userSupportInfoRowCell} align="right">
                        {row.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!isDisabledSend && (
            <div className={styles.userSupportInfoSendButton}>
              <BlackButton
                onClick={() => {
                  triggerPostDriverDebugInfo({
                    info: JSON.stringify(rows),
                    driverId: localStorage.getItem("driverId"),
                  });

                  setIsDisabledSend(true);
                }}
              >
                {t("send")}
              </BlackButton>
            </div>
          )}
        </div>
      </ScrollableLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profinfo: state.registration.profinfo,
  };
};

export default connect(mapStateToProps)(UserSupportInfo);

import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

function StyleForBigFontsSettings() {
    const element = useRef();
    const [fontSize, setFontSize] = useState('0')

    useEffect(() => {
        const elementFontSize = window.getComputedStyle(element.current).fontSize;
        const size = parseInt(elementFontSize);
        setFontSize(size)

        if(size >= 24) {
           return import('./bigFontsSettings.scss')
        } else if(size >= 20) {
           return import('./middleFontsSettings.scss')
        }
    }, [])

    return (
        <div>
            <p ref={element}></p>
        </div>
    )
}

export default StyleForBigFontsSettings;

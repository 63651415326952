import { t } from 'i18next'
import moment from 'moment'

const sortItemsByDate = listItem => {
  const groupedItems = {}

  // Сортировка элементов по убыванию даты
  const sortedList = listItem.sort((a, b) => {
    return (
      moment(b.createdAt || b.date).valueOf() -
      moment(a.createdAt || a.date).valueOf()
    )
  })

  sortedList.forEach(item => {
    let dateLabel = ''
    // const date = moment(item.createdAt || item.date).format('DD.MM.YYYY')
    const today = moment().startOf('day')
    const yesterday = moment()
      .subtract(1, 'days')
      .startOf('day')

    const itemDate = moment(item.createdAt || item.date).startOf('day')

    if (itemDate.isSame(today, 'd')) {
      dateLabel = t('today-day')
    } else if (itemDate.isSame(yesterday, 'd')) {
      dateLabel = t('yesterday')
    } else {
      dateLabel = itemDate.format('DD.MM.YYYY')
    }

    if (!groupedItems[dateLabel]) {
      groupedItems[dateLabel] = []
    }

    groupedItems[dateLabel].push(item)
  })

  return groupedItems
}

export { sortItemsByDate }

import React, { useCallback } from "react";
import { t } from "i18next";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ReactComponent as TrashIcon } from "src/images/trash.svg";
import { calculateLastDigits } from "../../helpers/calculateLastDigits";
import styles from "./styles.module.scss";

const CardItem = (props) => {
	const { card, setNewCard, newCard, setOpenModal } = props;

	const checkSelectedCard = useCallback(() => {
		setNewCard(card);
		localStorage.setItem("selectedCardId", card.id);
	}, [card, setNewCard]);

	const getSendCard = useCallback(() => {
		const selectedCardId = localStorage.getItem("selectedCardId");
		return selectedCardId === card.id;
	}, [card.id]);

	return (
		<ul className={styles.cardsListBlock}>
			<li
				className={styles.navCard}
				key={card.id}
				onClick={() => checkSelectedCard()}
			>
				<div className={styles.cardsSection}>
					<div className={styles.radioBtnBlock}>
						<div className={styles.radioCheckBlock}>
							<RadioGroup value={newCard || ""}>
								<FormControlLabel
									checked={getSendCard()}
									className={styles.radioLabel}
									control={<Radio className={styles.radioBtn} />}
									value={card.id}
								/>
							</RadioGroup>
						</div>
					</div>
					<div className={styles.cardsItems}>
						<p className={styles.cardTitle}>{calculateLastDigits(card)}</p>
						{getSendCard() && (
							<p className={styles.selectedCard}>{t("main")}</p>
						)}
						<TrashIcon
							className={styles.trashIcon}
							onClick={() => setOpenModal(true)}
						/>
					</div>
				</div>
			</li>
		</ul>
	);
};

export default CardItem;

import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FaChevronLeft, FaMedal } from "react-icons/fa";

import { ReactComponent as BackIcon } from "../../images/back.svg";
import { ReactComponent as MedalIcon } from "../../images/medal.svg";

import noImg from "../../media/noimg.jpg";

import "./myreting.scss";
import { t } from "i18next";
import { getRedirectPage } from "../../services/getRedirectPage";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

class MyReting extends Component {
	state = {};

	render() {
		const { driver } = this.props;

		return (
			<Fragment>
				<div className="container-fluid p-0 m-0">
					<div className="row m-0">
						<div className="myreting-section col-sm-12 p-0">
							<MobileTopBarHeight/>
								<div className="top-nav">
									<NavLink to={getRedirectPage("/dashboard")}>
										<BackIcon className="ArrowLeft" />
									</NavLink>
									<p className="top-nav-text">{t("driver_rating")}</p>
								</div>
								<div className="driver-reting">
									<div className="profile-section">
										<div className="profile-img-block">
											<img
												src={driver.photo ? driver.photo : noImg}
												className="profile-img"
												alt=""
											/>
										</div>
										<div className="profile-info">
											<div className="label-info">
												{driver.firstName} {driver.lastName}
											</div>
										</div>
										<div className="rating-info">
											<MedalIcon
												className="medal-icon"
												style={{ marginRight: "10px" }}
											/>
											{t("best_driver")}
										</div>
									</div>
									<div className="rating-and-bonus">
										<div className="rating-and-bonus-item">
											<div
												className="label-rating-and-bonus"
												style={{ marginTop: "4px" }}
											>
												{t("driver_rating")}
											</div>
											<div className="value-rating-and-bonus">
												{driver.rating}
											</div>
											<div className="ratings">
												<div className="empty-stars"></div>
												<div
													className="full-stars"
													style={{ width: (driver.rating / 5) * 100 + "%" }}
												></div>
											</div>
										</div>
										<div className="rating-and-bonus-item">
											<div className="label-rating-and-bonus">
												{t("number_of_bonuses")}
											</div>
											<div className="value-rating-and-bonus">
												{driver.bonuses}
											</div>
											<div style={{ minHeight: "1.25em", lineHeight: "1.25" }}>
												{" "}
											</div>
										</div>
									</div>
									{/* <div className="reting-line">
                  <p className="reting-text">{t('driver_rating')}</p>
                  <p className="reting-number">{driver.rating}</p>
                </div>
                <div className="status-line">
                  <p className="reting-text">{t('status')}</p>
                  <FaMedal className="medal-icon" />
                  <p className="reting-name">{t('best_driver')}</p>
                </div>
                <div className="bonuses">
                  <p className="reting-text">{t('number_of_bonuses')}</p>
                  <p className="bonus-col">{driver.bonuses}</p>
                </div>
                <div className="reting-line">
                  <p className="reting-text">{t('commission')}</p>
                  <p className="reting-number">
                    {driver.commission
                      ? driver.commission + ' %'
                      : t('not_specified')}
                  </p>
                </div> */}
								</div>
								{/*    <div className="my-comments">       
                    <button className="note-button">Комментарии обо мне</button>
</div>  */}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		driver: state.registration.profinfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReting);

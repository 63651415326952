import { axiosInstance } from "../../axios.config";
import { apinew } from "../../config";
import { CLEAR_TOKEN } from "../../app/app.actions";

export const GET_FINES = "GET_FINES";
export const FINES_ERROR = "FINES_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const FINES_PAID_ERROR = "FINES_PAID_ERROR";

export const getFines = () => {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
    axiosInstance
      .get(apinew + "/profile/fines", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          dispatch({
            type: GET_FINES,
            payload: response?.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: FINES_ERROR,
            payload: "Нет подключения к интернету!",
          });
        } else if (
          error.response &&
          error.response?.statusText == "Unauthorized" // eslint-disable-line
        ) {
          dispatch({
            type: CLEAR_TOKEN,
            payload: false,
          });
        } else {
          dispatch({
            type: FINES_ERROR,
            payload: error,
          });
        }
      });
  };
};

export const paidFine = (fineId) => {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
    axiosInstance
      .post(
        `${apinew}/profile/fines/${fineId}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          dispatch(getFines());
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: FINES_PAID_ERROR,
            payload: "Нет подключения к интернету!",
          });
        } else if (
          error.response &&
          error.response?.statusText == "Unauthorized" // eslint-disable-line
        ) {
          dispatch({
            type: CLEAR_TOKEN,
            payload: false,
          });
        } else {
          dispatch({
            type: FINES_PAID_ERROR,
            payload: error,
          });
        }
      });
  };
};

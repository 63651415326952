import React, { Component, useEffect, useRef, useState } from "react";
import { Input } from "../../shared/ui/Input/inputs";
import { Link, Navigate } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../images/back.svg";

import { classNames } from "../../shared/lib/classNames/classNames";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { tryLogin } from "../LoginPage/login.actions";
import { t } from "i18next";
import "./smsverefication.scss";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";

const SmsVerificationPage = (props) => {
	const [password, setPassword] = useState("");
	const continueButtonRef = useRef(null);
	// Flag to track whether setOtpCodeFromSms was called
	const [otpCodeFromSmsCalled, setOtpCodeFromSmsCalled] = useState(false);

	// Define the event listener function which call on android
	const setOtpCodeFromSms = (event) => {
		const smsPassword = event.detail.otp;
		setPassword(smsPassword);
		// Set the flag to true when setOtpCodeFromSms is called
		setOtpCodeFromSmsCalled(true);
	};

	const tryEnter = () => {
		const phoneNumber = localStorage.getItem("userPhone");
		let loginInfo = {
			phone: phoneNumber,
			password,
		};
		props.tryLogin(loginInfo);
	};

	useEffect(() => {
		// sms listener for android
		window.addEventListener("newOtp", setOtpCodeFromSms);

		return () => {
			window.removeEventListener("newOtp", setOtpCodeFromSms);
		};
	}, []);

	useEffect(() => {
		// Check if setOtpCodeFromSms was called
		if (otpCodeFromSmsCalled) {
			continueButtonRef.current.click();
			// Reset the flag after the side effect is executed
			setOtpCodeFromSmsCalled(false);
		}
	}, [otpCodeFromSmsCalled]);

	if (props.loginStatus && localStorage.getItem("accessToken")) {
		return <Navigate to="/orders" />;
	}

	return (
		<div className="main-section">
			<div className="row container">
				<div className="verefictaion-section__main">
					<MobileTopBarHeight />
					<div className="verefictaion-icon_back">
						<BackIcon
							className="back-icon"
							onClick={() => props.navigate("/login")}
						/>
					</div>
					<div className="verefication_title">
						<p className="verefication-title__auth">{t("password")}</p>
						<p className="verefication-title__info">
							{t("enter_your_password")}
						</p>
					</div>
					<div className="verefictaion-section__input">
						<Input
							isPassword
							type="password"
							name="password"
							autoComplete="one-time-code"
							placeholder={t("password")}
							value={password}
							handleClearValue={() => setPassword("")}
							className={classNames(
								"pass_input",
								{ error_field: props.wrongPass === true },
								[],
							)}
							onChange={(event) => setPassword(event.target.value)}
						/>
						{props.wrongPass === true ? (
							<p id="error">{t("incorrect_password")}</p>
						) : null}
					</div>
					<div className="forgot-pass_block">
						<Link className="forgot-passwor-text" to="/forgot">
							{t("forgot_password")}
						</Link>
					</div>
					<div className="verefictaion-info__timer">
						<Button ref={continueButtonRef} onClick={tryEnter}>
							{t("enter")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loginStatus: state.login.loginStatus,
		phone: state.login.phone,
		wrongPass: state.login.wrongPass,
		driver: state.registration.profinfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		tryLogin: (loginInfo) => dispatch(tryLogin(loginInfo)),
	};
};

export default withTranslation()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(withNavigation(SmsVerificationPage)),
);

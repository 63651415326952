import React, { useEffect } from "react";
import maplibregl from "!maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const useCreateMap = ({
  map,
  mapContainer,
  mapTheme,
  coordsDriver,
  customMapZoom,
  setIsMapMoving,
  orderStage,
  disableCircle,
  geoJSONCircle,
  atwork,
}) => {
  useEffect(() => {
    // stops map from intializing more than once
    if (map.current) {
      return;
    }

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: mapTheme,
      center: coordsDriver,
      zoom: customMapZoom,
      attributionControl: false,
    });

    map.current.on("movestart", () => {
      setIsMapMoving(true);
    });

    map.current.on("zoom", () => {
      setIsMapMoving(true);
    });

    map.current.addControl(
      new maplibregl.AttributionControl({
        compact: true,
      }),
      "top-left",
    );

    // disable map rotation using right click + drag
    map.current.touchZoomRotate.disableRotation();

    // close default credent
    map.current.on("load", () => {
      // Find the attribution control's button
      const attributionControlButton = document.querySelector(".maplibregl-ctrl-attrib-button");

      if (attributionControlButton) {
        // Simulate a click event to close the attribution control
        attributionControlButton.click();
      }
    });

    // NOT DELETE, FOR TESTING - set car coords on click
    // map.current.on("click", function (e) {
    //   // Get the coordinates (longitude and latitude) of the clicked point
    //   const coords = e.lngLat;
    //   localStorage.setItem(
    //     "coords",
    //     `{"latitude": ${coords.lat},"longitude": ${coords.lng},"speed":${Math.floor(Math.random() * 100)}}`,
    //   );
    //   // Log the coordinates to the console or use them as needed
    // });

    // Circle radius
    map.current.on("style.load", () => {
      if (!orderStage && !disableCircle) {
        map.current.addSource("circle-source", {
          type: "geojson",
          data: geoJSONCircle,
        });

        map.current.addLayer({
          id: "circle-layer",
          type: "fill",
          source: "circle-source",
          paint: {
            "fill-color": atwork ? "#1DB960" : "#757575",
            "fill-opacity": 0.2,
          },
        });

        map.current.addLayer({
          id: "circle-layer-outline",
          type: "line",
          source: "circle-source",
          paint: {
            "line-color": atwork ? "#1DB960" : "#9E9E9E",
            "line-width": 3,
          },
        });
      }
    });

    // Clean up the map instance when the component unmounts
    return () => {
      map.current.remove();
      map.current.off("movestart");
    };
  }, []);
  return null;
};

export default useCreateMap;

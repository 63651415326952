import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api as mainBackendApi } from "src/config";

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    localStorage.removeItem("accessToken");

    const refreshToken = localStorage.getItem("refreshToken");
    const refreshResult = await baseQuery(
      {
        url: `${mainBackendApi}/tokens/${refreshToken}/refresh`,
        method: "POST",
        body: { refreshToken },
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      localStorage.setItem("accessToken", refreshResult.data.accessToken);
      localStorage.setItem("refreshToken", refreshResult.data.refreshToken);
      result = await baseQuery(args, api, extraOptions);
    } else {
      localStorage.clear();
      window.location.replace = "/";
      throw new Error("Unauthorized");
    }
  }

  return result;
};

export const rtkApi = createApi({
  reducerPath: "rtkApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

import { rtkApi } from "src/shared/api/rtkApi";
import { apinew } from "src/config";
import { featuresApi } from "src/config-url";

export const userSupportApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    postDriverDebugInfo: builder.query({
      query: ({ info, driverId }) => ({
        url: `${apinew}/debug-info`,
        method: "POST",
        body: { driverId, info },
      }),
    }),
    getIsEnabledDriverDebugInfo: builder.query({
      query: () => ({
        url: `${featuresApi}/DriverDebugInfo`,
        method: "GET",
      }),
    }),
  }),
});

export const { usePostDriverDebugInfoQuery, useGetIsEnabledDriverDebugInfoQuery } = userSupportApi;

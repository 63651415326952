import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../../lib/classNames/classNames";
import "./smsinput.scss";

let currentSmsIndex = 0;

export const SmsInput = ({
	className,
	children,
	changeSmscode,
	ckeckCode,
	iosCallBack,
	smsCode,
	...otherProps
}) => {
	const [activeSmsIndex, setActiveSmsIndex] = useState(0);
	const inputsRef = useRef(null);

	useEffect(() => {
		setActiveSmsIndex(0);
	}, []);

	const handleOnChange = ({ target }) => {
		const { value } = target;

		//for ios code from message
		if (value.length === 4) {
			return changeSmscode(value.split(""), iosCallBack);
		}

		const newSmsCode = [...smsCode];
		newSmsCode[currentSmsIndex] = value.substring(value.length - 1);

		setActiveSmsIndex(currentSmsIndex + 1);

		if (!value) {
			setActiveSmsIndex(currentSmsIndex - 1);
		} else {
			setActiveSmsIndex(currentSmsIndex + 1);
		}

		changeSmscode(newSmsCode);
	};

	const handleOnKeyDown = ({ key }, index) => {
		currentSmsIndex = index;
		if (key === "Backspace") {
			setActiveSmsIndex(currentSmsIndex - 1);
		}

		const fullSmsCode = smsCode.join("");
		if (key === "Enter" && fullSmsCode.length === 4) {
			ckeckCode();
		}
	};

	useEffect(() => {
		if (inputsRef.current) {
			inputsRef.current.focus();
		}
	}, [activeSmsIndex]);

	return (
		<div className={classNames("SmsInput", {}, [className])}>
			{smsCode.map((_, index) => {
				return (
					<input
						{...otherProps}
						type="number"
						ref={index === activeSmsIndex ? inputsRef : null}
						key={index}
						value={smsCode[index]}
						onChange={handleOnChange}
						onKeyDown={(e) => handleOnKeyDown(e, index)}
					/>
				);
			})}
		</div>
	);
};

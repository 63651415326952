import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { signin, refreshSMS, clearRefSms, clearError } from "./checkcode.actions";

import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";

import "./checkcode.scss";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

import { ReactComponent as BackIcon } from "../../images/back.svg";
import { ReactComponent as MessageSquareIcon } from "../../images/message-square.svg";

import { SmsInput } from "../../shared/ui/SmsInput";
import { showModalAction } from "../ModalAction/modalAction.actions";
import ModalAction from "../ModalAction";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { classNames } from "../../shared/lib/classNames/classNames";
import { getDriverInfo, setFirstRegistration } from "../RegistrationPage/registration.actions";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";

const checkSmsCode = new RegExp(/^[0-9]{0,4}$/);

class CheckCode extends Component {
  state = {
    code: new Array(4).fill(""),
    errorC: "",
    wrongCode: "",

    // modalAlert
    mainAlertText: "",
    secondAlertText: "",
    actionText1: "",
    actionText2: "",

    goOrders: false,
    seconds: 60,
    timer: null,
  };

  continueButtonRef = React.createRef(null);

  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");
    // sms listener for android
    window.addEventListener("newOtp", this.setOtpCodeFromSms);

    if (accessToken) {
      this.props.getDriverInfo();
    }

    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
    window.removeEventListener("newOtp", this.setOtpCodeFromSms);
  }

  startTimer = () => {
    this.timerId = setInterval(() => {
      if (this.state.seconds === 0) {
        this.setState({
          mainAlertText: t("did_not_get_code"),
          secondAlertText: this.props.phone,
          actionText1: t("send_code_again"),
          actionText2: t("get_phone_code"),
        });
        let status = !this.props.showMAction;
        this.props.showModalAction(status);
        clearInterval(this.timerId);
      } else {
        this.setState({ seconds: this.state.seconds - 1 });
      }
    }, 1000);
    // this.setState({ timer })
  };

  ChgCode = (event) => {
    if (checkSmsCode.test(event.target.value) !== false) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  changeSmscode = (smsCode, callBack = () => null) => {
    this.setState(
      {
        code: smsCode,
      },
      callBack,
    );
  };

  goBack = () => {
    this.props.navigate("/registration");
  };

  ckeckCode = () => {
    const fullSmsCode = this.state.code.join("");

    if (this.state.code.length !== 4) {
      this.setState({
        errorC: true,
        wrongCode: t("code_must_consist_of_4_digits"),
      });
      return false;
    } else {
      this.setState({
        errorC: false,
        wrongCode: "",
      });
    }
    let signInfo = {
      phone: this.props.phone,
      code: fullSmsCode,
      branchId: 1,
    };
    this.props.signin(signInfo);
    this.props.setFirstRegistration(true);
  };

  tryClearError = () => {
    this.props.clearError();
  };

  goToOrders = () => {
    this.setState({
      goOrders: true,
    });
    this.props.clearRefSms();
  };
  verificationMethodModal = () => {
    this.setState({
      mainAlertText: t("did_not_get_code"),
      secondAlertText: this.props.phone,
      actionText1: t("send_code_again"),
    });
    let status = !this.props.showMAction;
    this.props.showModalAction(status);
  };

  refSMS = () => {
    let tryRegInfo = this.props.tryRegInfo;
    this.props.refreshSMS(tryRegInfo);
    let status = !this.props.showMAction;
    this.props.showModalAction(status);

    this.setState({
      seconds: 60,
    });

    this.startTimer();
  };

  handleTwo = () => {
    let status = !this.props.showMAction;
    this.props.showModalAction(status);
  };

  validateSmsCode = () => {
    const { code } = this.state;
    const allFilled = code.every((sms) => sms !== "");

    if (!allFilled || code.length <= 0) {
      return true;
    }

    return false;
  };

  // Define the event listener function which call on android
  setOtpCodeFromSms = (event) => {
    const code = event.detail.otp.split("");
    this.setState({ code }, () => {
      // This callback function is executed after the state is updated
      this.continueButtonRef.current.click();
    });
  };

  render() {
    const { loginStatus, refSmsStatus, showMAlert, errorStatus, showMAction, driver } = this.props;

    if (localStorage.getItem("accessToken") && !this.props.isFirstRegistration) {
      return <Navigate to="/orders" />;
    }

    if (loginStatus) {
      return <Navigate to="/registration/uploadphoto" />;
    }

    if (refSmsStatus) {
      this.setState({
        mainAlertText: t("SMS_with_code_was_sent_repeatedly"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.clearRefSms();
    }

    if (loginStatus === false) {
      this.setState({
        mainAlertText: t("wrong_code"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.clearRefSms();
    }

    return (
      <>
        {showMAlert ? <ModalAlert completeIcon={true} mainAlertText={this.state.mainAlertText} /> : null}
        {showMAction ? (
          <ModalAction
            actionText1={this.state.actionText1}
            iconMainActive={<MessageSquareIcon />}
            mainAction={this.refSMS}
            mainAlertText={this.state.mainAlertText}
            secondAlertText={this.state.secondAlertText}
          />
        ) : null}

        <div className="main-section">
          <div className="row container">
            <div className="verefictaion-section__main">
              <MobileTopBarHeight />
              <div className="verefictaion-icon_back">
                <BackIcon className="back-icon" onClick={() => this.props.navigate("/registration")} />
              </div>
              <div className="verefication_title">
                <p className="verefication-title__auth">{t("digit_code")}</p>
                <p className="verefication-title__info">{t("we_sent_you_code")}</p>
                <p className="phone-nubmer">{this.props.phone}</p>
              </div>
              <div className="verefictaion-section__input">
                <SmsInput
                  autoComplete="one-time-code"
                  changeSmscode={this.changeSmscode}
                  ckeckCode={this.ckeckCode}
                  iosCallBack={() => this.continueButtonRef.current.click()}
                  name="code"
                  onChange={this.ChgCode}
                  smsCode={this.state.code}
                />
              </div>
              <div className="verefictaion-info__timer">
                {this.state.seconds > 0 ? (
                  <p>
                    {t("able_to_retrieve_code")} <br /> {t("through")} {this.state.seconds} {t("seconds")}
                  </p>
                ) : (
                  <p onClick={this.verificationMethodModal}>{t("send_again")}</p>
                )}
              </div>

              <div className="confirm_block">
                <Button
                  className={classNames("confirm_btn", { btn_disabled: this.validateSmsCode() }, [])}
                  disabled={this.validateSmsCode()}
                  onClick={this.ckeckCode}
                  ref={this.continueButtonRef}
                >
                  {t("continue")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    phone: state.registration.phone,
    showMAction: state.modalAction.showMAction,
    loginStatus: state.chkCode.loginStatus,
    tryRegInfo: state.registration.tryRegInfo,
    refSmsStatus: state.chkCode.refSmsStatus,
    showMAlert: state.modalAlert.showMAlert,
    errorStatus: state.chkCode.errorStatus,
    errorMsg: state.chkCode.errorMsg,
    driver: state.registration.profinfo,
    isFirstRegistration: state.registration.isFirstRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signin: (signInfo) => dispatch(signin(signInfo)),
    refreshSMS: (tryRegInfo) => dispatch(refreshSMS(tryRegInfo)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    showModalAction: (status) => dispatch(showModalAction(status)),
    clearRefSms: () => dispatch(clearRefSms()),
    clearError: () => dispatch(clearError()),
    getDriverInfo: () => dispatch(getDriverInfo()),
    setFirstRegistration: (isFirstRegistration) => dispatch(setFirstRegistration(isFirstRegistration)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withNavigation(CheckCode)));

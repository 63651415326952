import React, { Component, Fragment } from 'react'
import './modalhistory.scss'
import { ReactComponent as Check } from '../../images/check.svg'
import { FaCheck } from 'react-icons/fa'
import { t } from 'i18next'

class ModalHistory extends Component {
    state = {
        sortByDate: false,
        sortByOrder: false,
    }
    render() {

        const { sortBy,typeSort } = this.props;

        return (
            <Fragment>
                <div className="container-fluid p-0 m-0 modal-g">
                    <div className="row m-0 modal-c">
                        <div className="modal">
                            <div className="modalalert-section col-sm-12 p-0">
                                <div className='modal-text-top'>
                                    <p className='top-nav-text-sort'>{t('sort_text')}</p>
                                </div>
                                <div className='sorts-container'>
                                    <div className='sort-category'>
                                        <button className='sort-text-filter' onClick={() => {
                                            sortBy('date');
                                        }}>{t('sort_by_date')}</button>
                                        {typeSort === 'date' && (<Check className='check-modal-history' />)}
                                    </div>
                                    <div className='border-modal-sort'></div>
                                    <div className='sort-category'>
                                        <button className='sort-text-filter' onClick={() => {
                                            sortBy('id');
                                        }}>{t('sort_by_order_id')}</button>
                                        {typeSort === 'id' && (<Check className='check-modal-history' />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default ModalHistory;

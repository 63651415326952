import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../axios.config";
import { apinew } from "../../config";

export const GET_G_TRANSACTIONS = "GET_G_TRANSACTIONS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const getWithdrawT = createAsyncThunk(
  "payment/getWithdrawT",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${apinew}/payment/withdraw/list`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: GET_G_TRANSACTIONS,
          payload: response?.data.data,
        });
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

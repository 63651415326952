import React from "react";

import styles from "./styles.module.scss";

const ModalOrderInfoMenu = (props) => {
  const { orderInfoItems = [], bottomComponent = null } = props;

  return (
    <div className={styles.orderInfoWrap}>
      {orderInfoItems.map((item, index) => {

        if (item.customElement) {
          return (
            <div key={`${item.customElement}_${index}`}>
              {item.customElement}
            </div>
          );
        }

        if (!item.iconText && !item.value) {
          return null;
        }

        return (
          <div
            key={`${item.iconText}_${index}`}
            className={`${styles.orderInfoItem} ${item.customClassName}`}
            onClick={item.onClick}
          >
            {item.icon}
            <div className={styles.orderInfoItemInner}>
              <span>{item.iconText}</span> <span>{item.value}</span>
            </div>
          </div>
        );
      })}
      {bottomComponent}
    </div>
  );
};

export default ModalOrderInfoMenu;

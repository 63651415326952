import { axiosInstanceWithToken } from "src/axios.config";
import { apinew } from "src/config";

export const getSalaryData = async ({ salaryDateFrom, salaryDateTo }) => {
  try {
    const response = await axiosInstanceWithToken.post(`${apinew}/v1/profile/salary`, {
      dateFrom: salaryDateFrom,
      dateTo: salaryDateTo,
    });

    return response.data;
  } catch (error) {
    console.log("error in getSalaryData: ", error);
  }
};

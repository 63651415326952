import React, {Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { showUpdPassForm, updateDriverPass } from '../EditProfile/editprofile.actions'
import {t} from 'i18next'

import './changepassword.scss' 

const chkPassword = new RegExp(/^[\S]{0,16}$/)

class ChangePassword extends Component {
    state = {
        newPass: "",
        wrongNewPass: "",
        errorNP: "",
        confirmPass: "",
        errorCP: "",
        wrongConfirmPass: ""
    }

    chgPass = event => {
        if (
          chkPassword.test(event.target.value) ||
          event.target.value.length === 0
        ) {
          this.setState({
            [event.target.name]: event.target.value,
          })
        }
      }

    confirmUpdPass = () => {
        if (this.state.newPass.length < 4) {
            this.setState({
                errorNP: true,
                wrongNewPass: t('password_must_be_least_4_characters')
            })
            return false 
        } else {
            this.setState({
                errorNP: false,
                wrongNewPass: ""
            })
        }

        if (this.state.confirmPass !== this.state.newPass) {
            this.setState({
                errorCP: true,
                wrongConfirmPass: t('passwords_do_not_match')
            })
            return false
        } else {
            this.setState({
                errorCP: false,
                wrongConfirmPass: ""
            })
            let passData = {
                id: this.props.driver.id,
                password: this.state.newPass,
                confirmPassword: this.state.confirmPass
            }
            this.props.updateDriverPass(passData)
            let showUPF = !this.props.showUPF
            this.props.showUpdPassForm(showUPF)
        }
    }

    Cancel = () => {
        let showUPF = !this.props.showUPF
        this.props.showUpdPassForm(showUPF)
    }

render() {
return (
<Fragment>
<div className="container-fluid p-0 m-0">
        <div className="row m-0">
            <div className="modal">
                <div className="updpass-section col-sm-12 p-0">
                    <p className="updpass-text">{t('changing_password')}</p>
                    <div className="input-block">
                        <input type="password" className="label-info" placeholder={t('enter_new_password')} name="newPass" value={this.state.newPass} onChange={this.chgPass}/>
                        {this.state.errorNP ? (<p className="error">{this.state.wrongNewPass}</p>) : null}
                        <input type="password" className="label-info" placeholder={t('confirm_new_password')} name="confirmPass" value={this.state.confirmPass} onChange={this.chgPass}/>
                        {this.state.errorCP ? (<p className="error">{this.state.wrongConfirmPass}</p>) : null}
                    </div>
                    <div className="updpass-buttons">
                        <p onClick={this.Cancel}>{t('cancel')}</p>
                        <p onClick={this.confirmUpdPass}>{t('confirm')}</p>
                    </div>
                </div>
            </div>
        </div>
	</div>
</Fragment>
)
    
}
}
const mapStateToProps = state => {
    return {
        showUPF: state.editprofile.showUPF,
        driver: state.registration.driver,
    }
}
    
const mapDispatchToProps = (dispatch) => {
    return {
        showUpdPassForm:(showUPF) => dispatch(showUpdPassForm(showUPF)),
        updateDriverPass: (newPass) => dispatch(updateDriverPass(newPass)),
    }
}
    
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
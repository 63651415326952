import React, { memo, useCallback } from "react";
import { t } from "i18next";
import { classNames } from "src/shared/lib/classNames/classNames";
import { ReactComponent as PlusIcon } from "../../../../images/plus.svg";
import styles from "./styles.module.scss";

const PaymentForm = (props) => {
  const { formInputs = [], onClick = () => null, action, cardId, method, className } = props;

  const handleClick = useCallback(() => {
    localStorage.setItem("cardId", cardId);
    onClick();
  }, [cardId, onClick]);

  return (
    <form action={action} id="makepayment-form" method={method}>
      {formInputs &&
        formInputs.map((input, index) => (
          <input key={`${input.name}_${index}`} name={input.name} type="hidden" value={input.value} />
        ))}

      <button
        className={classNames(styles.addCard, {}, [className])}
        name="btnsubmit"
        onClick={handleClick}
        type="submit"
      >
        <PlusIcon className={styles.icon} />
        <p>{t("add_card")}</p>
      </button>
    </form>
  );
};

export default memo(PaymentForm);

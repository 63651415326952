import React from "react";
import { connect } from "react-redux";
import { FaCheck } from "react-icons/fa";

import "./modalalertdelete.scss";
import { t } from "i18next";

const ModalAlertDelete = ({ text, onClose }) => {
  const Confirm = () => {
    onClose();
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row m-0">
        <div className="modal">
          <div className="modalalert-section col-sm-12">
              <div className="check-icon_block">
                <FaCheck className="check_icon" />
              </div>
            <p className="alert-text">{text}</p>
            <div className="alert-buttons">
              <button onClick={Confirm}>{t("acquainted-button")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlertDelete);

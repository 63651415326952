import React from "react";
import { t } from "i18next";
import moment from "moment";

import ModalOrderInfoMenu from "src/pages/ModalOrder/components/ModalOrderInfoMenu";
import { paymentType } from "src/shared/constants";
import OrderPriceBlock from "./components/OrderPriceBlock";

import { ReactComponent as ArrowLeft } from "src/images/arrow-right.svg";
import noImg from "src/media/noimg.jpg";
import { ReactComponent as Bolt } from "src/images/Lightning.svg";
import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as CommentDots } from "src/images/message.svg";
import { ReactComponent as Road } from "src/images/Path.svg";
import { ReactComponent as MobilePhone } from "src/images/MobilePhone.svg";
import { ReactComponent as Clock } from "src/images/clock-v2.svg";

import styles from "./styles.module.scss";
import { sumCurrentTimeMinutes } from "src/services/timeConvertor";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

export const FullOrderInfoWindow = (props) => {
  const {
    order,
    isOpen,
    onReturn,
    showGiveCarTime = true,
    timeToPoint,
    buttonForOrderMenu,
  } = props;
  let prefsArr = order?.orderPreferences?.map((pref) => t(pref.name)).join(", ");

  const distance = order.distance / 1000;

  const times = sumCurrentTimeMinutes(timeToPoint);

  const orderInfoItems = [
    {
      icon: (
        <img
          src={order?.clientPhoto ? order.clientPhoto : noImg}
          className={styles.clientPhoto}
          alt=""
        />
      ),
      iconText: order?.clientFullName,
      value: (
        <div className="icon">
          <a href={"tel:" + order.clientPhone}>
            <MobilePhone className={styles.faPhone} />
          </a>
        </div>
      ),
    },
    {
      icon: <CommentDots className="CommentDotsIcon" />,
      iconText: order.comment,
    },
    {
      icon: <Bolt className="icon-yellow" />,
      iconText: prefsArr?.length > 0 ? t("optional_extras") : null,
      value: prefsArr?.length > 0 ? prefsArr : null,
    },
    showGiveCarTime && {
      icon: <Clock />,
      iconText: t("auto_feed"),
      value: `${times}`,
    },
    {
      icon: <Road />,
      iconText: t("distance"),
      value: `${distance?.toFixed(1)}${t("km")}`,
    },
    {
      customElement: <OrderPriceBlock order={order} />,
    },
    {
      icon: <CreditCard className="CreditCardIcon" />,
      iconText: t("payment"),
      value: t(paymentType[order?.paymentType]),
    },
  ];

  return (
    <div
      className={`${styles.fullOrderWrap} ${
        isOpen ? styles.fullOrderWrapOpen : ""
      }`}
    >
      <MobileTopBarHeight/>
      <div className={styles.fullOrder}>
        <ArrowLeft onClick={onReturn} className={styles.arrowLeft} />
        <h1>{t("trip_info")}</h1>
        <div className={styles.modalOrderInfoMenuWrap}>
          <ModalOrderInfoMenu
            orderInfoItems={orderInfoItems}
            bottomComponent={buttonForOrderMenu}
          />
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";

const useCountdown = ({ countStart, interval = 1000, enabled = true }) => {
  const [count, setCount] = useState(countStart);
  const [isEnabled, setIsEnabled] = useState(enabled);

  const intervalRef = useRef();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    intervalRef.current = setInterval(() => {
      setCount((count) => count - 1);
    }, interval);

    return () => clearInterval(intervalRef.current);
  }, [isEnabled, interval]);

  useEffect(() => {
    if (count === 0) {
      setIsEnabled(false);
      clearInterval(intervalRef.current);
    }
  }, [count]);

  return { count };
};

export default useCountdown;

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./styles.module.scss";

const CounterSelector = (props) => {
  const { error, errorText, ...otherProps } = props;

  return (
    <div className={styles.counterSelectorWrapper}>
      <div className={styles.fullInputWrap}>
        <div className={styles.phoneInputWrap}>
          <PhoneInput className={styles.phone} {...otherProps} />
        </div>
      </div>

      {error && <p className={styles.error}>{errorText}</p>}
    </div>
  );
};

export default CounterSelector;

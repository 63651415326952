const colors = {
  lightTheme: {
    routeColor: '#0782f3',
  },
  darkTheme: {
    routeColor: '#0782f3',
  },
}

export default colors

import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import "moment/locale/de";
import "moment/locale/ru";
import "moment/locale/sk";
import "moment/locale/uk";
import { t } from "i18next";
import { locale } from "moment";
import { apiWS, cancelDelayForOrderRequest } from "src/config";
import { DRIVER_ONLINE_CHECK_INTERVAL_MS } from "src/config-in-git";

import useZendeskLauncherPosition from "src/components/ZendeskWidget/hooks/useZendeskLauncherPosition";

import useDisableHistoryNavigation from "src/hooks/useDisableHistoryNavigation";
import useLanguageManager from "src/hooks/useLanguageManager";

import SignalR from "src/pages/connectSocket";
import ModalAlert from "src/pages/ModalAlert";
import ModalError from "src/pages/Modals/ModalError";
import { incomingOrderInfo } from "src/pages/Order/order.actions";
import { checkIsOnline } from "src/pages/Orders/orders.actions";
import { getDriverInfo } from "src/pages/RegistrationPage/registration.actions";
import StyleForBigFontsSettings from "src/pages/StyleForBigFontsSettings";

import { DEFAULT_LANGUAGE_NAME, SUPPORTED_LANGUAGES } from "src/shared/const/languages";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";
import { modalCloseDelay, pagesStopCustomStopPreloader } from "src/shared/constants";
import { disableHistoryRoutes } from "src/shared/constants/router";
import {
  appChangeLanguage,
  onContentLoaded,
  onThemeChanged,
  onTopPaddingRequested,
} from "src/shared/funcForMobile";

import { clearTokenStatus } from "./app.actions";
import { sendPointsToMap } from "./app.actions";
import { appActions } from "./appSlice";
import AppRoutes from "./routers";

import "src/styles/main.scss";
import useCheckBackEndFeatures from "src/hooks/useCheckBackEndFeatures";

const App = (props) => {
  const {
    tokenStatus,
    atwork,
    profinfo,
    sendPointsToMap,
    clearTokenStatus,
    checkIsOnline,
    getDriverInfo,
    incomingOrderInfo,
  } = props;

  const [exit, setExit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { errorType, titledError, supportButtonVisible, maintenanceError } = useSelector(
    (state) => state.appReducer,
  );

  const { changeLanguage } = useLanguageManager();

  const getLanguage = useCallback(() => {
    const languageName = localStorage.getItem(LANGUAGE_NAME)?.toUpperCase();
    const validLanguages = SUPPORTED_LANGUAGES.map((language) => language.locale);
    const appType = localStorage.getItem("appType");

    if (profinfo?.language) {
      changeLanguage(profinfo.language);
      appChangeLanguage(appType);
      return;
    }

    if (languageName && validLanguages.includes(languageName)) {
      changeLanguage(languageName);
      locale(languageName.toLocaleLowerCase());
    } else {
      localStorage.setItem(LANGUAGE_NAME, DEFAULT_LANGUAGE_NAME);
      changeLanguage(DEFAULT_LANGUAGE_NAME);
      locale(DEFAULT_LANGUAGE_NAME.toLowerCase());
    }
  }, [changeLanguage, profinfo.language]);

  const tryClearTokenStatus = () => {
    localStorage.clear();
    clearTokenStatus();
    setExit(true);
  };

  const handleCloseErrorModal = useCallback(() => {
    setIsOpenModal(false);

    const timer = setTimeout(() => {
      dispatch(appActions.clearError());
    }, modalCloseDelay);

    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    const initializeAppSettings = async () => {
      // not remove acceptOrderTime, used for mobile app
      localStorage.setItem("acceptOrderTime", cancelDelayForOrderRequest);

      const coords = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;
      const acceptOrderTime = localStorage.getItem("acceptOrderTime");
      const accessToken = localStorage.getItem("accessToken");
      const appPermitionShowOnTop = localStorage.getItem("appPermitionShowOnTop");
      const appShowOnTop = localStorage.getItem("appShowOnTop");
      const newOrderRequestManage = localStorage.getItem("newOrderRequestManage");
      const appVersion = localStorage.getItem("appVersion");
      const appType = localStorage.getItem("appType");
      const theme = localStorage.getItem("theme");

      // stop native app preloader
      if (!pagesStopCustomStopPreloader.includes(location.pathname)) {
        onContentLoaded();
      }

      // not remove acceptOrderTime, used for mobile app
      if (!acceptOrderTime) {
        localStorage.setItem("acceptOrderTime", cancelDelayForOrderRequest);
      }

      if (!theme) {
        document.querySelector("body").setAttribute("data-theme", "light");
        localStorage.setItem("theme", "true");
        // for mobile app
        onThemeChanged(true);
      } else {
        document.querySelector("body").setAttribute("data-theme", theme === "true" ? "light" : "dark");
        // for mobile app
        onThemeChanged(theme);
      }

      if (!appVersion) {
        localStorage.setItem("appVersion", false);
      }

      if (accessToken) {
        await getDriverInfo();
      }

      if (!appType) {
        localStorage.setItem("appType", false);
      }

      if (!newOrderRequestManage) {
        localStorage.setItem("newOrderRequestManage", true);
      }

      if (!appPermitionShowOnTop && !appShowOnTop) {
        localStorage.setItem("appPermitionShowOnTop", false);
        localStorage.setItem("appShowOnTop", false);
      }

      if (!coords || !coords?.latitude || !coords?.longitude || !coords?.speed) {
        localStorage.setItem("coords", null);
      }
      const getLanguageTimeout = setTimeout(() => {
        getLanguage();
      }, 1000);

      localStorage.setItem("apiWS", apiWS);
      localStorage.removeItem("socket_initialized");

      // disabled native app add padding
      onTopPaddingRequested(false);

      return () => {
        clearTimeout(getLanguageTimeout);
      };
    };

    const handleMessage = (event) => {
      postMessage("connect", "*");
      let msg = event.data;
      msg = JSON.parse(msg);
      if (msg.location) {
        localStorage.setItem("coords", JSON.stringify(msg.location));
        const coords = JSON.parse(localStorage.getItem("coords"));
        if (coords && coords.latitude && coords.longitude) {
          sendPointsToMap();
        }
      }

      if (msg.token) {
        localStorage.setItem("pushToken", msg.token);
      }

      if (msg.back) {
        if (location.pathname !== "/orders") {
          navigate(-1);
        } else {
          window.ReactNativeWebView.postMessage("close");
        }
      }
    };

    // Set location for mobile application to local storage
    document.addEventListener("message", handleMessage, false);

    initializeAppSettings();

    return () => {
      document.removeEventListener("message", handleMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check atwork and order in local store if we at work
  useEffect(() => {
    let checkOrderInterval;
    let timerCheckOnlineInterval;

    const checkOrder = () => {
      let orderShowRequestBody = null;

      // if problem parse order, clear this order
      try {
        orderShowRequestBody = JSON.parse(localStorage.getItem("orderShowRequestBody")) || null;
      } catch (error) {
        console.log("error in parse order in checkOrder: ", error);
        localStorage.removeItem("orderShowRequestBody");
        orderShowRequestBody = null;
      }
      incomingOrderInfo(orderShowRequestBody);
    };

    const startIntervals = () => {
      timerCheckOnlineInterval = setInterval(async () => {
        await checkIsOnline();
      }, DRIVER_ONLINE_CHECK_INTERVAL_MS);

      checkOrderInterval = setInterval(checkOrder, 1000);
    };

    if (atwork) {
      startIntervals();
    } else {
      clearInterval(checkOrderInterval);
      clearInterval(timerCheckOnlineInterval);
    }

    return () => {
      clearInterval(checkOrderInterval);
      clearInterval(timerCheckOnlineInterval);
    };
  }, [atwork, checkIsOnline, incomingOrderInfo]);

  // check backEnd features
  useCheckBackEndFeatures()

  // If change language
  useEffect(() => {
    if (profinfo?.language) {
      getLanguage();
    }
  }, [getLanguage, profinfo?.language]);

  useEffect(() => {
    if (exit) {
      setExit(false);
      navigate("/");
    }
  }, [exit, navigate]);

  // Open a modal if there is an error
  useEffect(() => {
    if (errorType) {
      setIsOpenModal(true);
    }
  }, [errorType]);

  // Add indentation for zendesk launcher
  useZendeskLauncherPosition({ atwork });

  // Prevents the user from going back to certain routes
  useDisableHistoryNavigation(disableHistoryRoutes);

  return (
    <div id="App">
      <ModalError
        errorType={errorType}
        isOpen={isOpenModal}
        maintenanceError={maintenanceError}
        onClose={handleCloseErrorModal}
        showSupportBtn={supportButtonVisible}
        title={titledError}
      />

      {!tokenStatus && (
        <ModalAlert
          confirmHandler={tryClearTokenStatus}
          mainAlertText={t("error_login_from_different_device")}
        />
      )}

      <SignalR atwork={atwork} />
      <AppRoutes />
      <StyleForBigFontsSettings />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tokenStatus: state.app.tokenStatus,
    atwork: state.orders.workStatus,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendPointsToMap: () => dispatch(sendPointsToMap()),
    clearTokenStatus: () => dispatch(clearTokenStatus()),
    checkIsOnline: () => dispatch(checkIsOnline()),
    getDriverInfo: () => dispatch(getDriverInfo()),
    incomingOrderInfo: (data) => dispatch(incomingOrderInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  updNewsList,
  sendNotItem,
  updNotList,
  setItemId,
  clearError,
} from "./mainnews.actions";
import moment from "moment/moment";

import { classNames } from "../../shared/lib/classNames/classNames";

import { ReactComponent as BackIcon } from "../../images/back.svg";

import "./mainnews.scss";
import { t } from "i18next";
import BottomMenu from "../BottomMenu";
import { getRedirectPage } from "../../services/getRedirectPage";
import Loader from "../Loader";
import { sortItemsByDate } from "../../services/sortItemsByDate";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from 'src/hoc/NavigateForClass';

const formatDateFunc = (date) => {
  const format = {
    date: "DD.MM.YYYY | HH:mm",
  };
  return `${moment(moment.utc(date).toDate()).format(format.date)}`;
};

class MainNews extends Component {
  state = {
    showNews: false,
    showNotif: true,
  };

  showNewsItem = (id) => {
    this.props.setItemId(id);
    this.props.navigate("/newsarticle");
  };

  showNot = (id, i) => {
    let notList = this.props.storeNotifications;
    notList[i].isRead = true;
    this.props.updNotList(notList);
    let notItem = this.props.storeNotifications[i];
    this.props.sendNotItem(notItem);
  };

  showNotif = () => {
    this.setState({
      showNotif: true,
      showNews: false,
    });
  };

  showNews = () => {
    this.setState({
      showNotif: false,
      showNews: true,
    });
  };

  tryClearError = () => {
    this.props.clearError();
  };

  render() {
    const { storeNews, storeNotifications, errorStatus } = this.props;

    const groupedItems = sortItemsByDate(storeNews);

    const news = Object.keys(groupedItems).map((newsItem) => (
      <div className="news-list-block" key={newsItem}>
        <h3>{newsItem}</h3>
        {groupedItems[newsItem].map((item) => (
          <div className="news-item" key={item.id}>
            <div
              className={classNames(
                "news-block",
                { no_read: !item.isRead },
                []
              )}
              onClick={() => this.showNewsItem(item.id)}
            >
              <p className="main-news">{item.title}</p>
              <p className="title-news">{item.content}</p>
              {!item.isRead && <div className="no_read__point" />}
            </div>
          </div>
        ))}
      </div>
    ));

    // const news = storeNews.map((item, i) => {
    //   return (
    //     <li key={item.id} className="news-item">
    //       <p className="theme">{item.title}</p>
    //       <p className="news-text">{item.content}</p>
    //       <div className="bot-line">
    //         <p className="news-date">{formatDateFunc(item.createdAt)}</p>
    //         {item.isRead ? (
    //           <p className="read">{t('read')}</p>
    //         ) : (
    //           <p className="no-read">{t('new')}</p>
    //         )}
    //         <p
    //           className="read-more"
    //           onClick={() => this.showNewsItem(item.id, i)}
    //         >
    //           <NavLink to="/newsarticle">{t('read_more')}</NavLink>
    //         </p>
    //       </div>
    //     </li>
    //   )
    // })

    // const notif = storeNotifications.map((item, i) => {
    //     return(
    //     <li key={item.id} className="news-item">
    //         <p className="theme">{item.title}</p>
    //         <p className="notif-text">{item.content}</p>
    //         <div className="bot-line">
    //             <p className="news-date">{formatDateFunc(item.createdAt)}</p>
    //             {item.isRead ? <p className="read">Прочитано</p> : <p className="no-read">Новое</p>}
    //             <p className="read-more" onClick={() => this.showNot(item.id, i) }><NavLink to="/notifications">Читать далее</NavLink></p>
    //         </div>
    //     </li>)
    // })

    return (
      <>

        <div className="container-fluid">
          <div className="row">
            <div className="mainnews-section">
              <MobileTopBarHeight />
              <div className="top-nav">
                <p className="top-nav-text" style={{ maxWidth: "250px" }}>
                  {t("notifications_and_news")}
                </p>
              </div>
              {/* <div className="nav-line">
                 <div className={!this.state.showNotif ? "nav-btn-left active" : "nav-btn-left"} onClick={this.showNews}>
                    {t("news")}
                 </div>
                 <div className={this.state.showNotif ? "nav-btn active" : "nav-btn"} onClick={this.showNotif}>
                    {t("notifications")}
                 </div>
              </div>  */}
              <ul className="news-list">
                {/* {this.state.showNotif ? notif : news} */}
                {news}
              </ul>
            </div>
          </div>
        </div>
        <BottomMenu />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    storeNews: state.mainnews.news,
    storeNotifications: state.mainnews.notifications,
    errorStatus: state.order.errorStatus,
    errorMsg: state.order.errorMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updNewsList: (newsList) => dispatch(updNewsList(newsList)),
    updNotList: (notList) => dispatch(updNotList(notList)),
    sendNotItem: (notItem) => dispatch(sendNotItem(notItem)),
    setItemId: (newsItem) => dispatch(setItemId(newsItem)),
    clearError: () => dispatch(clearError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(MainNews))

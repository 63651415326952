import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearError } from '../Cars/cars.actions'

import ModalAlert from '../ModalAlert'
import { showModalAlert } from '../ModalAlert/modalAlert.actions'

import { ReactComponent as BackIcon } from '../../images/back.svg'

import './editpassword.scss'
import { t } from 'i18next'
import { classNames } from '../../shared/lib/classNames/classNames'
import { Button } from '../../shared/ui/Buttons/DefautlButton/button'
import { Input } from '../../shared/ui/Input/inputs'
import { getRedirectPage } from '../../services/getRedirectPage'
import {
  sendImg,
  showUpdPassForm,
  updateDriverPass,
} from '../EditProfile/editprofile.actions'
import { updDriverInfo } from '../RegistrationPage/registration.actions'
import { showModalConfirm } from '../Modals/ModalConfirm/modalConfirm.actions'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'

const chkPassword = new RegExp(/^[\S]{0,16}$/)

class EditPassword extends Component {
  state = {
    password: '',
    wrongPassword: '',
    errorP: '',
    cpassword: '',
    wrongCPassword: '',
    errorCP: '',

    newPass: '',
    errorNP: '',
    wrongNewPass: '',
    confirmPass: '',
    wrongConfirmPass: '',
  }

  chgPass = (event) => {
    if (
      chkPassword.test(event.target.value) ||
      event.target.value.length === 0
    ) {
      this.setState({
        [event.target.name]: event.target.value,
      })
    }
  }

  validateRegInfo = () => {
    const { newPass, confirmPass } = this.state
    if (!newPass || !confirmPass) {
      return true
    }

    return false
  }

  confirmUpdPass = () => {
    if (this.state.newPass.length < 4) {
      this.setState({
        errorNP: true,
        wrongNewPass: t('password_must_be_least_4_characters'),
      })
      return false
    } else {
      this.setState({
        errorNP: false,
        wrongNewPass: '',
      })
    }

    if (this.state.confirmPass !== this.state.newPass) {
      this.setState({
        errorCP: true,
        wrongConfirmPass: t('passwords_do_not_match'),
      })
      return false
    } else {
      this.setState({
        errorCP: false,
        wrongConfirmPass: '',
      })
      let passData = {
        id: this.props.driver.id,
        password: this.state.newPass,
        confirmPassword: this.state.confirmPass,
      }
      this.props.updateDriverPass(passData)
      this.setState({
        mainAlertText: t('password_success_update'),
      })
      let status = !this.props.showMAlert
      this.props.showModalAlert(status)
      // this.props.clearUsePhone()
    }
  }

  clearInputValue = (name) => {
    this.setState({
      [name]: '',
    })
  }

  render() {
    const { showMAlert } = this.props

    return (
      <>
        {' '}
        {showMAlert ? (
          <ModalAlert mainAlertText={this.state.mainAlertText} />
        ) : null}
        <div className="editpassword-section">
          <MobileTopBarHeight />
          <div className="container">
            <div className="navigation-block">
              <Link to={getRedirectPage('/dashboard')}>
                <BackIcon className="icon-nav" />
                <h1>{t('change_password')}</h1>
              </Link>
            </div>

            <div className="editpassword-input_block">
              <p>{t('new_password')}</p>
              <Input
                className={classNames(
                  'input_field',
                  { error_field: this.state.errorNP },
                  [],
                )}
                placeholder="*********"
                name="newPass"
                type="password"
                isPassword
                value={this.state.newPass}
                onChange={this.chgPass}
                // hanleShowPassword={() =>
                //   this.togglePasswordVisibility('newPass1')
                // }
              />
              {this.state.errorNP ? (
                <p id="error">{this.state.wrongNewPass}</p>
              ) : null}
            </div>
            <div className="editpassword-input_block">
              <p>{t('confirm_password_v2')}</p>
              <Input
                className={classNames(
                  'input_field',
                  { error_field: this.state.errorCP },
                  [],
                )}
                placeholder="*********"
                name="confirmPass"
                type="password"
                isPassword
                value={this.state.confirmPass}
                onChange={this.chgPass}
                // hanleShowPassword={() =>
                //   this.togglePasswordVisibility('newPass2')
                // }
              />
              {this.state.errorCP ? (
                <p id="error">{this.state.wrongConfirmPass}</p>
              ) : null}
            </div>

            <div className="update-pass_btn">
              <Button
                className={classNames(
                  'udatepass-btn',
                  { btn_disabled: this.validateRegInfo() },
                  [],
                )}
                onClick={this.confirmUpdPass}
                disabled={this.validateRegInfo()}
              >
                {t('confirm')}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    // driver: state.registration.profinfo,
    driver: state.registration.driver,
    showUPF: state.editprofile.showUPF,
    driverPhoto: state.editprofile.driverPhoto,
    driverLicense: state.editprofile.driverLicense,
    updProfInfoStatus: state.registration.updProfInfoStatus,
    showMConfirm: state.modalConfirm.showMConfirm,
    addImgStatus: state.editprofile.addImgStatus,
    showMAlert: state.modalAlert.showMAlert,
    updProfileStatus: state.registration.updProfileStatus,
    errorStatus: state.editprofile.errorStatus,
    errorMsg: state.editprofile.errorMsg,
    atwork: state.orders.workStatus,
    cityList: state.ProfileReducer.cityList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUpdPassForm: (showUPF) => dispatch(showUpdPassForm(showUPF)),
    updDriverInfo: (driverInfo) => dispatch(updDriverInfo({driverInfo})),
    sendImg: (imgInfo) => dispatch(sendImg(imgInfo)),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    clearError: () => dispatch(clearError()),
    updateDriverPass: (newPass) => dispatch(updateDriverPass(newPass)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword)

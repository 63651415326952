import { LANGUAGE_NAME } from "./const/localstorage";

export const paymentType = {
  0: "cash",
  1: "by_card",
  2: "cash_and_bonuses",
  4: "card_and_bonuses",
};

export const orderIconStages = {
  16: 3,
};

/** App link */
export const appStoreLink = "https://apps.apple.com/no/app/carma-driver/id6450720893";
export const playMarketLink = "market://details?id=com.carma.driver";

export const notRemovedItemsLocalStore = [LANGUAGE_NAME, "coords"];

/**Map */
export const mapViewNum = {
  "2D": "2D",
  "3D": "3D",
};

//external navigation providers
export const navProviders = {
  wego: "wego",
  waze: "waze",
  google: "google",
};

// Allow added car current time minus this time
export const allowCarYears = 24;

// Time in ms after the end of which the button for canceling the order will appear
export const ORDER_CANCELLATION_TIMEOUT_MS = 420000;

// pages which stop android preloader with inner logic
export const pagesStopCustomStopPreloader = ["/", "/orders", "/order"];

export const paymentsTypes = {
  STRIPE: "Stripe",
  WAYFORPAY: "WayForPay",
};

export const defaultCommission = 15;
export const allowArriveRadius = 300;

export const imageDefaultQuality = {
  width: 500,
  height: 500,
  quality: 0.5,
};
export const allowArriveRadiusInMeters = 1000;
export const modalCloseDelay = 300;

export const checkRemoteLoggerDelay = 60000;

import React, { useState } from 'react'
import { classNames } from '../../lib/classNames/classNames'
import './input.scss'
import { ReactComponent as CloseIcon } from '../../../images/close.svg'
import { ReactComponent as OpenEye } from '../../../images/eye.svg'
import { ReactComponent as CloseEye } from '../../../images/eye-off.svg'

export const Input = ({
  className,
  children,
  value,
  name,
  handleClearValue,
  // hanleShowPassword,
  isPassword,
  type,
  readOnly,
  onChange,
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = event => {
    if (onChange) {
      onChange(event)
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="input_block">
        <input
          className={classNames('Input', {}, [className])}
          name={name}
          onChange={handleChange}
          type={!showPassword && isPassword ? 'password' : 'text'}
          value={value}
          {...otherProps}
        />
        {value && !readOnly && type !== 'password' && (
          <div className="Input-icon" onClick={handleClearValue}>
            <CloseIcon className="Input-icon-close" />
          </div>
        )}
        {value && isPassword && (
          <div className="Input-icon" onClick={handleShowPassword}>
            {!showPassword ? (
              <CloseEye className="Input-icon-close" />
            ) : (
              <OpenEye className="Input-icon-close" />
            )}
          </div>
        )}
      </div>
  )
}

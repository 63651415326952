import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { showModalAction } from './modalAction.actions'

import './modalaction.scss'
import { t } from 'i18next'

class ModalAction extends Component {
  Cancel = () => {
    let status = !this.props.showMAction
    this.props.showModalAction(status)
  }

  render() {
    const { iconMainActive, iconSecondAction } = this.props

    return (
      <Fragment>
        <div className="container-fluid ">
          <div className="m-0">
            <div className="modal-action">
              <div className="modalaction-section">
                <p className="alert-text">{this.props.mainAlertText}</p>
                {this.props.isGiude && (
                  <div className="block-guide">
                    <div className="guide-title_block">
                      <p className="guide_info"> </p>
                    </div>
                    <div className="guide_img">
                      <img alt="" src={this.props.guidePhoto} />
                    </div>
                  </div>
                )}

                <p className="alert-info_text">
                  {this.props.secondAlertText
                    ? this.props.secondAlertText
                    : null}
                </p>
                <div className="action-block">
                  <div className="icon-modal_active">{iconMainActive}</div>
                  <div className="btn-modal_handler">
                    <button
                      className="action_handler-one border_bottom"
                      onClick={this.props.mainAction}
                    >
                      {this.props.actionText1}
                    </button>
                  </div>
                </div>
                {this.props.actionText2 ? (
                  <div className="action-block">
                    <div className="icon-modal_active">{iconSecondAction}</div>
                    <div className="btn-modal_handler">
                      <button
                        className="action_handler-two border_bottom"
                        onClick={this.props.secondAction}
                      >
                        {this.props.actionText2}
                      </button>
                    </div>
                  </div>
                ) : null}
                <div className="alert-buttons">
                  <button onClick={this.Cancel}>{t('close')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    showMAction: state.modalAction.showMAction,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showModalAction: status => dispatch(showModalAction(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAction)

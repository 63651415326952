import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../axios.config";
import { api } from "../../config";

export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const NOT_REGISTERED = "NOT_REGISTERED";

export const sendPhone = createAsyncThunk(
  "auth/sendPhone",
  async (userPhone, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${api}/driver/reset-password`, {
        phone: userPhone.phone,
        branchId: 1,
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: RESET_PASS,
          payload: true,
        });
      }
    } catch (error) {
      if (error.response && error.response?.data.Data === "user_not_found") {
        //!TODO: CTD-204
        dispatch({
          type: NOT_REGISTERED,
        });

        return;
      }

      return rejectWithValue(error);
    }
  },
);

export const RESET_PASS = "RESET_PASS";
export const refStatusPass = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASS,
      payload: false,
    });
  };
};

export const CLEAR_FORGOT = "CLEAR_FORGOT";
export const clearForgot = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FORGOT,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

import React, { memo, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import { t } from "i18next";
import { WITHDRAWAL_COMMISION_PERCENTAGE } from "src/config-in-git";

import CreditCard from "src/components/CreditCard";

import Loader from "src/pages/Loader";
import ModalAlert from "src/pages/ModalAlert";

import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Button/button";
import { Modal } from "src/shared/ui/Modal";

import styles from "./styles.module.scss";
import { ReactComponent as CreditCardIcon } from "../../../../images/credit-card.svg";
import { ReactComponent as DownArrow } from "../../../../images/down-arrow.svg";
import { ReactComponent as WalletIcon } from "../../../../images/wallet.svg";
import CardItem from "../CardItem";

const OperationType = (props) => {
  const {
    getBalanceDriver,
    newCard,
    showMAlert,
    cards,
    setNewCard,
    cardFormData,
    showWithdraw,
    showBonuses,
    handleTransferAmountChange,
    amountValue,
    handleOperation,
    mainAlertText,
    openModal,
    setOpenModal,
    hasWithdrawRequests,
    isLimitedCards,
    userPhone,
    getDriverInfo,
    profLoading,
  } = props;

  const getValidData = useCallback(() => {
    const selectedCardId = localStorage.getItem("selectedCardId");

    if (showBonuses && amountValue) {
      return false;
    }

    return !selectedCardId || !amountValue;
  }, [showBonuses, amountValue]);

  useEffect(() => {
    const fetchCards = async () => {
      if (showWithdraw) {
        await getDriverInfo(true);
      }
    };

    fetchCards();
  }, [getDriverInfo, showWithdraw]);

  return (
    <>
      {profLoading && <Loader />}
      <ModalAlert mainAlertText={mainAlertText} />

      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className={styles.modalCardInfo}>
          <h3> {t("select_card_v1")}</h3>
          {cards && cards.map((card) => <CardItem card={card} key={card.id} setNewCard={setNewCard} />)}
          {!isLimitedCards && (
            <CreditCard
              cardFormData={cardFormData}
              className={styles.addCardForm}
              isLimitedCards={isLimitedCards}
              userPhone={userPhone}
            />
          )}
        </div>
      </Modal>

      <div className={styles.balanceSection}>
        <h3>{t("available_balance")}</h3>
        <div className={styles.balanceDriver}>
          <p>{getBalanceDriver()}</p>
        </div>

        <input
          className={styles.fieldSum}
          onChange={handleTransferAmountChange}
          placeholder={0}
          type="text"
          value={amountValue}
        />

        {showWithdraw && (
          <p className={styles.сommission}>
            {t("driver_commission")} {WITHDRAWAL_COMMISION_PERCENTAGE}%
          </p>
        )}

        {hasWithdrawRequests && (
          <Link className={styles.listWithdrawap} to="/gtransactions">
            {t("view_withdrawal_requests")}
          </Link>
        )}

        {showBonuses ? (
          <div className={styles.bonusesBlock}>
            <WalletIcon className={styles.WalletIcon} />
            <div className={styles.paymentType}>
              <p>{t("to_main_account")}</p>
            </div>
          </div>
        ) : (
          <div className={styles.chooseCardSection} onClick={() => setOpenModal(true)}>
            <div className={styles.iconCard}>
              <CreditCardIcon className={styles.CreditCard} />
              <DownArrow className={styles.DownArrow} />
            </div>
            <div className={styles.chooseCard}>
              <p>{newCard ?? t("card_not_selected")}</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.nextBlockBtn}>
        <Button
          className={classNames(styles.nextBtn, { [styles.btnDisabled]: getValidData() }, [])}
          disabled={getValidData()}
          onClick={handleOperation}
        >
          {t("confirm")}
        </Button>
      </div>
    </>
  );
};

export default memo(OperationType);

import React, { Component, Fragment } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateCar,
  getCar,
  resetEditCarStatus,
  clearError,
} from "../Cars/cars.actions";

import { ReactComponent as BackIcon } from "../../images/back.svg";
import { ReactComponent as CheckIcon } from "../../images/check.svg";

import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";


import "./editcar.scss";
import { t } from "i18next";
import { classNames } from "../../shared/lib/classNames/classNames";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { getRedirectPage } from "../../services/getRedirectPage";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

class EditCar extends Component {
  state = {
    brand: "",
    model: "",
    year: "",
    number: "",
    color: "",
    klass: "",
    chgCss: "",
    updStatus: false,
    selectedPrefs: [],
  };

  componentDidMount() {
    let editCarId = this.props.editCarId;
    this.props.getCar(editCarId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { editCarInfo, editCarStatus } = this.props;
    if (prevProps.editCarStatus !== editCarStatus && editCarInfo?.id) {
      const orderPrefIds = editCarInfo.orderPreferences.map((pref) => pref.id);
      this.setState({ selectedPrefs: orderPrefIds });
    }
  }

  tryUpdCar = () => {
    if (
      this.props.editCarId == localStorage.getItem("selectedCarId") && // eslint-disable-line
      this.props.atwork
    ) {
      this.setState({
        mainAlertText: t("before_you_change_additional_options_finish_work"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    } else {
      const carInfo = {
        id: this.props.editCarId,
        brandId: this.props.editCarInfo.brandId,
        modelId: this.props.editCarInfo.modelId,
        colorId: this.props.editCarInfo.colorId,
        manufactureYear: this.props.editCarInfo.manufactureYear,
        govNumber: this.props.editCarInfo.govNumber,
        orderPreferences: this.state.selectedPrefs,
        driverId: this.props.driver.id,
      };
      //    console.log("CarInfo in updateCar:", carInfo)
      this.props.updateCar(carInfo);
      this.setState({
        updStatus: true,
      });
    }
  };

  changePref = (pref) => {
    const selectedPrefs = [...this.state.selectedPrefs];
    const index = selectedPrefs.indexOf(pref.id);
    if (index === -1) {
      selectedPrefs.push(pref.id);
    } else {
      selectedPrefs.splice(index, 1);
    }
    this.setState({ selectedPrefs });
  };

  tryClearError = () => {
    this.props.clearError();
  };

  render() {
    const {
      brands,
      models,
      colors,
      classes,
      prefs,
      editCarInfo,
      editCarStatus,
      errorStatus,
      showMAlert,
    } = this.props;

    if (editCarStatus) {
      for (let i = 0; i < brands.length; i++) {
        if (editCarInfo.brandId === brands[i].id) {
          this.setState({
            brand: brands[i].name,
          });
        }
      }
      for (let i = 0; i < models.length; i++) {
        if (editCarInfo.modelId === models[i].id) {
          this.setState({
            model: models[i].name,
          });
        }
      }
      for (let i = 0; i < colors.length; i++) {
        if (editCarInfo.colorId === colors[i].id) {
          this.setState({
            color: colors[i].name,
          });
        }
      }
      for (let i = 0; i < classes.length; i++) {
        if (editCarInfo.classId === classes[i].id) {
          this.setState({
            klass: classes[i].name,
          });
        }
      }

      this.props.resetEditCarStatus();
    }

    const prefsList = prefs.map((pref, i) => {
      const { editCarInfo } = this.props;

      if (editCarInfo.id) {
        const isSelected = this.state.selectedPrefs.includes(pref.id);

        return (
          <li key={pref.id} onClick={() => this.changePref(pref)}>
            {t(pref.name)}
            {isSelected && <CheckIcon className="check_icon" />}
          </li>
        );
      }
    });

    if (this.state.updStatus) {
      this.setState({
        updStatus: false,
      });
      return <Navigate to="/cars" />;
    }

    return (
      <>
        {showMAlert ? (
          <ModalAlert mainAlertText={this.state.mainAlertText} />
        ) : null}

        <div className="edit-car_section">
          <MobileTopBarHeight />
          <div className="container">
            <Link className="navigation-block" to={getRedirectPage("/cars")}>
              <BackIcon className="icon-nav" />
            </Link>
            <div className="title-info_block">
              <h1>{t("edit")}</h1>
            </div>

            <div className="car-info">
              <div className="car-info_block border_btm-active">
                <h2 className="brand">
                  {this.state.brand} {this.state.model}
                </h2>
                <h2 className="govnumber_car">
                  {this.props.editCarInfo.govNumber}
                </h2>
              </div>

              <div className="car-info_block">
                <p className="brand">{t(this.state.color)}</p>
                <p>{this.props.editCarInfo.manufactureYear}</p>
              </div>
            </div>
            <div className="prefs-block">
              <p>{t("additional_services")}</p>
              <ul className="prefs-list">{prefsList}</ul>
            </div>

            <div className="update-btn_block">
              <Button
                className={classNames("update_btn", {}, [])}
                onClick={this.tryUpdCar}
              >
                {t("update_v1")}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editCarId: state.cars.editCarId,
    editCarInfo: state.cars.editCarInfo,
    editCarStatus: state.cars.editCarStatus,
    driver: state.registration.driver,
    brands: state.addcar.brands,
    models: state.addcar.models,
    colors: state.addcar.colors,
    classes: state.addcar.classes,
    prefs: state.addcar.preferences,
    errorStatus: state.cars.errorStatus,
    errorMsg: state.cars.errorMsg,
    atwork: state.orders.workStatus,
    showMAlert: state.modalAlert.showMAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCar: (editCarId) => dispatch(getCar(editCarId)),
    updateCar: (carInfo) => dispatch(updateCar(carInfo)),
    resetEditCarStatus: () => dispatch(resetEditCarStatus()),
    clearError: () => dispatch(clearError()),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCar);

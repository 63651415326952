import React from "react";
import { t } from "i18next";
import { CircularProgress } from "@mui/material";
import { ReactComponent as LogoCarma } from "../../images/word-carma.svg";

import styles from "./loader.module.scss";

export const Loader = (props) => {
	const { showMenuSpace, loaderText } = props;
	return (
		<div
			className={`${styles.loaderWrap} ${
				showMenuSpace ? styles.showMenuSpace : ""
			}`}
		>
			<div className={styles.circularWrap}>
				<CircularProgress
					className={styles.circularBackground}
					size={75}
					style={{
						color:
							localStorage.getItem("theme") === "true" ? "#EEEEEE" : "#444348",
					}}
					thickness={3}
					value={100}
					variant="determinate"
				/>
				<CircularProgress
					className={styles.circular}
					disableShrink
					size={75}
					thickness={3}
					variant="indeterminate"
				/>
				<LogoCarma className={styles.logoCarma} />
			</div>
			{loaderText && (
				<div className={styles.loaderText}>
					{loaderText.map((item, index) => (
						<h3 key={`${item}_${index}`}>{item}</h3>
					))}
				</div>
			)}
		</div>
	);
};

export default Loader;

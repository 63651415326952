import React, { Fragment, memo, useEffect } from "react";

import { t } from "i18next";
import { useState } from "react";
let isStopRender = false;
let isFirstReder = true;
function secondsToHhMm(times) {
  if (typeof times !== "number") {
    return "";
  }

  times = Number(times.toFixed(0));

  let isMinus = false;
  if (times < 0) {
    times *= -1;
    isMinus = true;
  }

  const seconds = times % 60;
  const minutes = (times - seconds) / 60;

  const ss = seconds < 10 ? "0" + seconds : seconds;
  const mm = minutes < 10 ? "0" + minutes : minutes;

  const changeMM = isMinus ? "-" + mm : mm;

  return {
    time: changeMM + ":" + ss,
    classTime: isMinus ? "minus-result" : "order-time-text-count",
  };
}

function MainTimer({ times, finishWork }) {
  const [timeSeconds, setTimeSeconds] = useState(times);
  const [render, setRender] = useState(false);
  const [num, setNum] = useState(1);

  useEffect(() => {
    if (times !== 0 && render === false) {
      setTimeSeconds(times);
      setRender(true);
    }
    if (finishWork !== 0 && render === true && isFirstReder) {
      isFirstReder = false;
      setNum(num + 1);
    }
    if (finishWork !== 0 && render === false && isFirstReder === false) {
      isFirstReder = false;
      setNum(num + 1);
    }

    return () => {
      clearTimeout(timeSeconds);
    };
  }, [finishWork, times]);

  useEffect(() => {
    if (
      finishWork !== 0 &&
      render === true &&
      times !== 0 &&
      timeSeconds < times
    ) {
      isStopRender = true;
      setTimeout(() => {
        setTimeSeconds(times);
        isStopRender = false;
      }, 1301);
    }
  }, [times]);

  useEffect(() => {
    if (finishWork !== 0 && render === true) {
      setTimeout(() => {
        if (isStopRender && isFirstReder === false) return;
        isFirstReder = false;
        setTimeSeconds(timeSeconds - 1);
      }, 1000);
    }
  }, [timeSeconds, num]);

  const time = secondsToHhMm(timeSeconds);
  return (
    <Fragment>
      <div className="order-time-count-single">
        <p className="order-time-text">{t("order_time")}</p>
        <p className={time.classTime}>{time.time} </p>
      </div>
    </Fragment>
  );
}

const memoRender = (prev, next) => {
  if (prev.finishWork !== next.finishWork) return false;
  if (prev.times !== next.times) return false;
  if (prev.times < next.times) {
    isStopRender = true;
    return false;
  }

  return true;
};

export default memo(MainTimer, memoRender);

import { axiosInstanceWithToken } from "src/axios.config";
import { apiRpc } from "src/config";

const getCommission = async ({ coords, carClassId }) => {
  try {
    const response = await axiosInstanceWithToken.post(`${apiRpc}/get-point-driver-commission`, {
      point: {
        lat: coords.latitude,
        lon: coords.longitude,
      },
    });

    return response.data.data[carClassId];
  } catch (error) {
    console.log("error in getCommission", error);
  }
};

export default getCommission;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../axios.config";
import { api } from "../../config";
import { CLEAR_TOKEN } from "../../app/app.actions";
import { UPD_LOGIN } from "../LoginPage/login.actions";

export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const LOGIN_STATUS = "LOGIN_STATUS";
export const signin = createAsyncThunk("auth/signin", async (signInfo, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${api}/driver/confirm`, signInfo);

    if (response?.status === 200 || response?.status === 204) {
      localStorage.setItem("accessToken", response?.data.accessToken);
      localStorage.setItem("refreshToken", response?.data.refreshToken);
      dispatch({
        type: LOGIN_STATUS,
        payload: true,
      });
      dispatch({
        type: UPD_LOGIN,
        payload: true,
      });
    } else {
      dispatch({
        type: LOGIN_STATUS,
        payload: false,
      });
    }
  } catch (error) {
    //TODO: CTD-204
    if (error.response && error.response?.data.Data == "invalid_confirmation_code") {
      dispatch({
        type: LOGIN_STATUS,
        payload: false,
      });

      return;
    }

    return rejectWithValue(error);
  }
});

export const REF_SMS_STATUS = "REF_SMS_STATUS";
export const refreshSMS = createAsyncThunk(
  "auth/refreshSMS",
  async (tryRegInfo, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${api}/driver/sign-up`, tryRegInfo);

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: REF_SMS_STATUS,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CLEAR_REF_SMS = "CLEAR_REF_SMS";
export const clearRefSms = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REF_SMS,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

// special logic for clear webview local store
export const clearLocalStorageExceptKeys = (keysToKeep) => {
	let saveLocalStoreValues = {};

	// get values from local store
	for (const key in localStorage) {
		if (keysToKeep.includes(key)) {
			saveLocalStoreValues = {
				...saveLocalStoreValues,
				[key]: localStorage.getItem(key),
			};
		}
	}

	localStorage.clear();

	// set saved values to local store
	for (const key in saveLocalStoreValues) {
		localStorage.setItem(key, `${saveLocalStoreValues[key]}`);
	}

};

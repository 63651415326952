import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import { ReactComponent as BackIcon } from "../../images/back.svg";
import { classNames } from "../../shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

function PageHeader({
	title,
	className,
	iconClassName,
	onClick,
	backPage,
	Icon,
	onAdditionClick,
}) {
	const navigate = useNavigate();

	const handleGoBack = useCallback(() => {
		if (onClick) {
			onClick();
		} else {
			backPage ? navigate(backPage) : navigate(-1);
		}
	}, [backPage, navigate, onClick]);

	const additionClick = () => {
		if (onAdditionClick) {
			onAdditionClick();
		}
	};

	return (
		<div className={classNames(styles.wrapper, {}, [className])}>
			<MobileTopBarHeight />
			<div className={styles.content}>
				<div className={styles.icons}>
					<BackIcon className={styles.icon} onClick={handleGoBack} />
					{Icon && (
						<Icon
							className={classNames(styles.icon, {}, [iconClassName])}
							onClick={additionClick}
						/>
					)}
				</div>
				<p className={styles.title}>{title}</p>
			</div>
		</div>
	);
}

export default memo(PageHeader);

import React, { Component, Fragment } from 'react'
import { FaChevronLeft } from 'react-icons/fa'

import guideFront from '../../media/guide-front.png'
import guideBack from '../../media/guide-back.png'
import guideRight from '../../media/guide-right.png'
import guideLeft from '../../media/guide-left.png'
import guideSeatsFront from '../../media/guide-seats-front.png'
import guideSeatsBack from '../../media/guide-seats-back.png'

import './guide.scss'
import { t } from 'i18next'
import withNavigation from 'src/hoc/NavigateForClass'

class GuideCarPhoto extends Component {
  render() {
    return (
      <div className="guide-section">
        <div className="container">
          <div className="guide-section__main">
            <div className="block_title">
              <div
                className="back_icon"
                onClick={() => this.props.navigate('/cardocuments')}
              >
                <FaChevronLeft className="arrowleft_icon" />
              </div>
            </div>
            <div className="block-named">
              <h1>{t('guide_auto')}</h1>
            </div>
            <div className="list-guide">
              <div className="block-guide">
                <h2>1. {t('front_with_license_plate')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideFront} />
              </div>
              <div className="block-guide">
                <h2>2. {t('back_with_license_plate')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideBack} />
              </div>
              <div className="block-guide">
                <h2>3. {t('photo_right_side_car')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideRight} />
              </div>
              <div className="block-guide">
                <h2>4. {t('photo_left_side_car')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideLeft} />
              </div>
              <div className="block-guide">
                <h2>5. {t('front_row_seats_v2')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideSeatsFront} />
              </div>
              <div className="block-guide">
                <h2>6. {t('back_row_seats_v2')}</h2>
                <p>{t('guide_info_text')}</p>
                <img alt="" src={guideSeatsBack} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withNavigation(GuideCarPhoto)

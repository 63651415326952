import { calculatePointOnLine } from "src/components/CustomMap/helpers/calculatePointOnLine";
import { insertPointBetweenPoints } from "src/components/CustomMap/helpers/insertPointBetweenPoints";
export const getNotPassedRoute = (props) => {
  const {
    disabledFunctions,
    lastPointIndx,
    map,
    polylinePositions,
    coordsDriver,
    setPolylineWithDividePoint,
  } = props;

  if (disabledFunctions.disableSeparateRoute && polylinePositions) {
    lastPointIndx.current = 0;
    setPolylineWithDividePoint(polylinePositions);
    return polylinePositions;
  }

  if (coordsDriver && polylinePositions) {
    // get point on line between two near points
    const calculationResult = calculatePointOnLine(
      map,
      coordsDriver,
      polylinePositions
    );

    if (calculationResult !== null) {
      const { nearsPoints, coords } = calculationResult;

      const pointOnLine = [coords[1], coords[0]];

      const { polylineWithInsertPoint, insertedPointIndx } = insertPointBetweenPoints({
        nearsPoints,
        insertPointCoords: pointOnLine,
        arrayOfPoints: polylinePositions,
      });

	 
      lastPointIndx.current = insertedPointIndx;

      setPolylineWithDividePoint(polylineWithInsertPoint);

      const newPolyPoints = polylineWithInsertPoint.slice(insertedPointIndx);

      return newPolyPoints;
    } else {
      // Here can add a loader or some modal
      console.log("Не удалось построить маршрут");
    }
  }

  return null;
};

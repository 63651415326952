import React, { Component } from 'react'

import { FaChevronLeft } from 'react-icons/fa'
import { FiPhone } from 'react-icons/fi'

import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import './finishreg.scss'
import { Button } from '../../shared/ui/Buttons/DefautlButton/button'
import { classNames } from '../../shared/lib/classNames/classNames'
import { CircularProgressBar } from '../../shared/ui/CircularProgressBar'
import { connect } from 'react-redux'
import {
  getDriverInfo,
  setFirstRegistration,
} from '../RegistrationPage/registration.actions'
import { t } from 'i18next'
import withNavigation from 'src/hoc/NavigateForClass'

class FinishRegistrationPage extends Component {
  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      this.props.getDriverInfo()
    }
  }

  goFinish = () => {
    this.props.setFirstRegistration(false)
    this.props.navigate('/orders')
  }

  redirectToContactUsPage = () => {
    const data = {
      payload: true,
    }

    this.props.navigate({
      pathname: '/contactus',
      state: data,
    })
  }

  render() {
    const registrationStep = JSON.parse(
      localStorage.getItem('registrationStep'),
    )

    if (
      localStorage.getItem('accessToken') &&
      registrationStep >= 2 &&
      !this.props.isFirstRegistration
    ) {
      return <Navigate to="/orders" />
    }

    if (!localStorage.getItem('accessToken')) {
      return <Navigate to="/" />
    }

    return (
      <div className="finishreg-section">
        <div className="container">
          <div className="block_title">
            <div className="back_icon" onClick={this.goFinish}>
              <FaChevronLeft className="arrowleft_icon" />
            </div>
          </div>
          <div className="main-info">
            <div className="photo_pahse">
              <CircularProgressBar
                valuePercent={100}
                stepValue={'2'}
                finishStep={true}
              />
            </div>
            <div className="info_finish">
              <h1>
                {t('your_application')} {t('send_v1')}
              </h1>
              <h3>
                {t('manager_will_review_your_application_and_contact_you')}
              </h3>
            </div>
          </div>
          <div className="block-btn_app">
            <Button
              className={classNames('btn-app', {}, [])}
              onClick={() => this.goFinish()}
            >
              {t('in_app')}
            </Button>
          </div>
          <div className="block_help">
            <div>
              <FiPhone className="phone_icon" />
            </div>
            <p onClick={this.redirectToContactUsPage}>
              {t('customer_service')}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    driver: state.registration.profinfo,
    isFirstRegistration: state.registration.isFirstRegistration,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDriverInfo: () => dispatch(getDriverInfo()),
    setFirstRegistration: (isFirstRegistration) =>
      dispatch(setFirstRegistration(isFirstRegistration)),
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withNavigation(FinishRegistrationPage)),
)

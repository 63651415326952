import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getReplenishT, clearError } from "./stransactions.actions";
import { ReactComponent as ArrowUp } from "src/images/arrow-fill.svg";
import { ReactComponent as ArrowDownFill } from "src/images/arrow-down-fill.svg";
import { ReactComponent as BackIcon } from "src/images/back.svg";



import "./stransactions.scss";
import { t } from "i18next";
import { sortItemsByDate } from "src/services/sortItemsByDate";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import { getValuteSymbol } from "src/services/getValuteSymbol";

const STransactions = ({ sTransactionsList, errorStatus, profinfo, errorMsg, getReplenishT, clearError }) => {
  const getTransactionDescription = (operationTypeId) => {
    switch (operationTypeId) {
      case 1:
        return t("withdrawing_money");
      case 2:
        return t("recharging_by_card");
      default:
        return t("other");
    }
  };

  const getTransactionIcon = (transactionInfo) => {
    if (transactionInfo.operationTypeId === 1) {
      return <ArrowDownFill className="ArrowInfo" />;
    }

    if (transactionInfo.operationTypeId === 2) {
      return <ArrowUp className="ArrowInfo" />;
    }
  };

  const tryClearError = () => {
    clearError();
  };

  const groupedItems = sortItemsByDate(sTransactionsList);

  let transactionList;
  if (sTransactionsList) {
    transactionList = Object.keys(groupedItems).map((transaction) => (
      <div className="transaction-list-block" key={transaction}>
        <h3>{transaction}</h3>
        {groupedItems[transaction].map((item) => (
          <div className="transaction-item" key={item.id}>
            <div className="info-block">
              {getTransactionIcon(item)}
              <div>
                <p className="info-title">{getTransactionDescription(item.operationTypeId)}</p>
              </div>
            </div>
            {item.operationTypeId === 2 ? (
              <p className="current-amount send_money">
                {item.quote}
                {getValuteSymbol(profinfo.currency)}
              </p>
            ) : item.operationTypeId === 1 ? (
              <p className="current-amount get_money">
                {" "}
                -{item.quote}
                {getValuteSymbol(profinfo.currency)}
              </p>
            ) : (
              <p className="current-amount">
                {" "}
                -{item.quote}
                {getValuteSymbol(profinfo.currency)}
              </p>
            )}
          </div>
        ))}
      </div>
    ));
  }

  useEffect(() => {
    getReplenishT();
  }, [getReplenishT]);

  //!TODO: For test
  // const navigate = useNavigate();
  // const test = true;
  // if (test) {
  //   return navigate("/money");
  // }

  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div>
          <div className="transactions-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <div className="top-nav">
              <NavLink to="/money">
                <BackIcon className="ArrowLeft" />
              </NavLink>
              <p className="top-nav-text">{t("top_up_transaction")}</p>
            </div>
            {sTransactionsList.length === 0 ? (
              <p className="no-transactions">{t("had_no_transactions")}</p>
            ) : (
              <ul className="transactions-list">{transactionList}</ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sTransactionsList: state.stransactions.sTransactionsList,
    errorStatus: state.stransactions.errorStatus,
    errorMsg: state.stransactions.errorMsg,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReplenishT: () => dispatch(getReplenishT()),
    clearError: () => dispatch(clearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(STransactions);

import { useState, useEffect, useCallback } from "react";

const useDataCards = (userCardsList) => {
	const [cards, setCards] = useState([]);

	const getDataCards = useCallback(() => {
		const { stripe = [], wayForPay = [] } = userCardsList || {};

		if (stripe.length || wayForPay.length) {
			const cards = [...stripe, ...wayForPay];
			setCards(cards);
		} else {
			setCards([]);
		}
	}, [userCardsList]);

	useEffect(() => {
		getDataCards();
	}, [getDataCards]);

	return { cards };
};

export default useDataCards;

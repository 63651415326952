import React from "react";

import "./modalalertsc.scss";
import { Link } from "react-router-dom";
import { Portal } from "src/shared/ui/Portal/Portal";
import { classNames } from "src/shared/lib/classNames/classNames";

const ModalAlertSC = (props) => {
	const { mainAlertText, actions, onCancel } = props;

	const onContentClick = (e) => {
		e.stopPropagation();
	};

	return (
		<Portal>
			<div className="container-fluid">
				<div className="m-0">
					<div className="modal-operation" onClick={onCancel}>
						<div className="modaloperation-section" onClick={onContentClick}>
							<p className="alert-text">{mainAlertText}</p>

							<div>
								{actions.map((item, index) => {
									return (
										<Link
											className="action-block"
											key={`${item.text}_${index}`}
											onClick={item.onClick}
											state={item.linkState}
											to={item.linkPathname}
										>
											<div
												className={classNames("modal_active", {}, [
													item.iconClassName,
												])}
											>
												{item.icon}
											</div>
											<div className="btn-modal_handler">
												<button
													className="action_handler-one border_bottom"
													onClick={item.onClick}
												>
													{item.text}
												</button>
											</div>
										</Link>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};

export default ModalAlertSC;

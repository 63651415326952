import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../axios.config";
import { paymentGate } from "../../config";
import { CLEAR_TOKEN } from "../../app/app.actions";

export const GET_S_TRANSACTIONS = "GET_S_TRANSACTIONS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const getReplenishT = createAsyncThunk(
  "driver/getReplenishT",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${paymentGate}/driver/transactions/?skip=0&take=50`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: GET_S_TRANSACTIONS,
          payload: response?.data.data,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

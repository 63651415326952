import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaMoneyBillWave } from "react-icons/fa";
import { connect } from "react-redux";
import moment from "moment/moment";
import { getFines, paidFine } from "./fine.actions";

import "./fine.scss";
import { t } from "i18next";
import { getUserCards } from "../../components/CreditCard/store/ccard.action";
import { getDriverInfo } from "../RegistrationPage/registration.actions";

const Fine = ({
	getFines,
	finesList,
	paidFine,
	paidFineError,
	getUserCards,
	userCardsList,
	getDriverInfo,
	driver,
}) => {
	const [isPaid, setIsPaid] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		getUserCards();
		getFines();
		getDriverInfo();
	}, [getFines, getUserCards, getDriverInfo]);

	const handlePayment = (fineId) => {
		const fine = finesList.find((fine) => fine.id === fineId);

		if (userCardsList.length === 0) {
			navigate("/cardlist");
			return;
		}

		getDriverInfo();
		paidFine(fineId);

		if (driver.balance < fine.amount) {
			setIsPaid(true);
		} else {
			setIsPaid(false);
		}
	};
	// console.log('finesList', finesList)

	const formatDateFunc = (date) => {
		const format = {
			date: "DD.MM.YYYY | HH:mm",
		};
		return `${moment(moment.utc(date).toDate()).format(format.date)}`;
	};

	const sortedFineList = finesList.sort((a, b) =>
		a.isPaid === b.isPaid ? 0 : a.isPaid ? 1 : -1,
	);
	const finesListRender = sortedFineList.map((fine) => {
		return (
			<li key={fine.id} className="list-item">
				<div className="row">
					<div className="fine-amount">
						<FaMoneyBillWave className="payment-icon money" /> {fine.amount}
						грн.
					</div>
					<div className="fine-date">{formatDateFunc(fine.createdAt)}</div>
				</div>
				<div className="row">
					<div className="fine-title">
						<b>{t("status")}</b>
					</div>
					<div className="fine-info">
						{fine.isPaid ? (
							<span className="fine-paid">{t("paid")}</span>
						) : (
							<span>{t("not_paid")}</span>
						)}
					</div>
				</div>
				<div className="row">
					<div className="fine-title">
						<b>{t("reason")}</b>
					</div>
					<div className="fine-info">{fine.reason}</div>
				</div>
				{!fine.isPaid ? (
					<div className="row">
						<div className="paid-fine">
							<button
								className="fine-paid-btn"
								onClick={() => {
									handlePayment(fine.id);
								}}
							>
								{t("pay")}
							</button>
						</div>
					</div>
				) : null}
			</li>
		);
	});

	return (
		<>
			<div className="container-fluid p-0 m-0">
				<div className="row m-0">
					<div className="history-section col-sm-12 p-0">
						<div className="top-nav">
							<NavLink to="/orders">
								<FaArrowLeft className="ArrowLeft" />
							</NavLink>
							<p className="top-nav-text">{t("penalties")}</p>
						</div>
						{finesList.length < 1 ? (
							<p className="no-fines">{t("have_no_penalties")}</p>
						) : (
							<>
								{isPaid ? (
									<p className="no-fines">
										{t("not_enough_funds_on_your_balance")}
									</p>
								) : paidFineError ? (
									<p className="no-fines">{t("error_paid_fine_try_later")}</p>
								) : null}

								<ul className="fine-list">{finesListRender}</ul>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		finesList: state.fine.finesList,
		errorMsg: state.fine.errorMsg,
		paidFineError: state.fine.paidFineError,
		userCardsList: state.ccard.userCardsList,
		driver: state.registration.profinfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getFines: () => dispatch(getFines()),
		paidFine: (id) => dispatch(paidFine(id)),
		getUserCards: () => dispatch(getUserCards()),
		getDriverInfo: () => dispatch(getDriverInfo()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Fine);

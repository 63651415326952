import {
  LOGGED_IN,
  SHOW_PRELOADER,
  HIDE_PRELOADER,
  LOGOUT,
  LOGGED_FAILD,
  UPD_USER_HASH,
  NO_REG,
  AUTH,
  CHK_PHONE,
  // EXIT_APP,
  CLEAR_LOGIN,
  BLACK_LIST,
  ERROR_MSG,
  CLEAR_ERROR,
  UPD_LOGIN,
  SET_COUNTRY_PHONE_CODE,
} from "./login.actions.js";

const initialState = {
  loginStatus: localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ? true : false, // статус авторизации
  preloader: false, // показывать спинер или нет
  message: false, // если с сервера прийдет какое-то сообщение (подробности ошибки или что-то типо того)
  phone: "",
  registration: "",
  checkphone: false,
  wrongPass: false,
  blackList: false,
  countryCode: "+421",
  errorStatus: false,
  errorMsg: "",
};

function login(state = initialState, action) {
  switch (action.type) {
    case LOGGED_IN: // если авторизация прошла успешно
      return { ...state, loginStatus: action.payload };
    //     return { ...state, ...action.payload, preloader: false }
    case SHOW_PRELOADER: // показываем прелоадер пока осуществляется проверка введенных логина/пароля
      return { ...state, preloader: true };
    case HIDE_PRELOADER:
      return { ...state, preloader: false };
    case LOGGED_FAILD:
      return {
        ...state,
        message: action.payload.message,
        loginStatus: action.payload.loginStatus,
        phone: action.payload.phone,
        wrongPass: action.payload.wrongPass,
      };
    case SET_COUNTRY_PHONE_CODE: {
      return {
        ...state,
        countryCode: action.payload,
      };
    }
    case LOGOUT:
      return {
        ...state,
        loginStatus: false,
        userHash: false,
        preloader: false,
        wrongPass: false /*checkphone: false*/,
      };
    case UPD_USER_HASH:
      return { ...state, userHash: action.payload, loginStatus: true };
    case NO_REG:
      return { ...state, phone: action.payload, registration: false };
    case AUTH:
      return { ...state, registration: action.payload, loginStatus: true };
    case CHK_PHONE:
      return { ...state, phone: action.payload, checkphone: true };
    case CLEAR_LOGIN:
      return {
        ...state,
        registration: "",
        checkphone: false,
        blackList: false,
        wrongPass: false,
        errorStatus: false,
        errorMsg: "",
      };
    case BLACK_LIST:
      return { ...state, blackList: true };
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true };
    case CLEAR_ERROR:
      return { ...state, errorMsg: "", errorStatus: false };
    case UPD_LOGIN:
      return { ...state, loginStatus: action.payload };
    /*  case EXIT_APP:
        return { ...state, loginStatus: false }*/

    default:
      return { ...state };
  }
}
export default login;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { ReactComponent as CloseIcon } from "../../../../images/close.svg";
import "./finishworkbtn.scss";

// const calculateColor = (relativeMouse, slideMovementTotal) => {
//   const colorChangeRatio = relativeMouse / slideMovementTotal;

//   const initialRGB = [255, 222, 23];
//   const finalRGB = [158, 158, 158];

//   const interpolatedRGB = initialRGB.map((channel, index) => {
//     const targetChannel = finalRGB[index];
//     return Math.round(channel - colorChangeRatio * (channel - targetChannel));
//   });

//   return `rgb(${interpolatedRGB.join(", ")})`;
// };

const colorInWork = "#ffde17"; // yellow
const colorFinishWork = "#9E9E9E"; // gray
const swipeCompletionThreshold = 0.82;

const FinishWorkBtn = ({ finishWork }) => {
  const [isMousePressed, setIsMousePressed] = useState(false);
  const [initialMouse, setInitialMouse] = useState(0);
  const [slideMovementTotal, setSlideMovementTotal] = useState(0);
  const [workText, setWorkText] = useState(t("finish_job"));
  const [sliderLeft, setSliderLeft] = useState("4px");
  const [bgColor, setBgColor] = useState(colorInWork);

  const buttonBackgroundRef = useRef(null);
  const sliderRef = useRef(null);

  const handleMouseDown = useCallback((event) => {
    setIsMousePressed(true);
    setInitialMouse(event.clientX || event.touches[0].pageX);

    if (buttonBackgroundRef.current && sliderRef.current) {
      setSlideMovementTotal(
        buttonBackgroundRef.current.offsetWidth -
          sliderRef.current.offsetWidth -
          4
      );
    }
  }, []);

  const handleMouseUp = async (event) => {
    if (!isMousePressed) {
      return;
    }

    setIsMousePressed(false);

    const currentMouse = event.clientX || event.changedTouches[0].pageX;
    const relativeMouse = currentMouse - initialMouse;

    if (relativeMouse < slideMovementTotal * swipeCompletionThreshold) {
      resetSlider();
      resetColor();
    } else {
      if (relativeMouse / slideMovementTotal > swipeCompletionThreshold) {
        completeSlide();
        await finishWork();
      } else {
        resetSlider();
      }
    }
  };

  const resetColor = useCallback(() => {
    buttonBackgroundRef.current.style.transition =
      "background-color 0.3s ease-in-out";

    setBgColor(colorInWork);

    setTimeout(() => {
      buttonBackgroundRef.current.style.transition = "";
    }, 300);
  }, []);

  const resetSlider = useCallback(() => {
    setSliderLeft("4px");

    // Добавляем анимацию движения слайдера
    sliderRef.current.style.transition = "left 0.2s ease-in-out";

    // Добавляем анимацию изменения цвета
    setBgColor(colorInWork);

    // Удаляем анимацию после завершения
    setTimeout(() => {
      sliderRef.current.style.transition = "";
      setBgColor(colorInWork); // Устанавливаем окончательный цвет
    }, 200);
  }, []);

  const completeSlide = useCallback(() => {
    setSliderLeft(slideMovementTotal + "px");
    setBgColor(colorFinishWork);
  }, [slideMovementTotal]);

  const updateUI = useCallback(
    (relativeMouse) => {
      if (relativeMouse <= 0) {
        setSliderLeft("4px");
      } else if (relativeMouse >= slideMovementTotal + 4) {
        setSliderLeft(slideMovementTotal + "px");
      } else if (
        relativeMouse / slideMovementTotal >
        swipeCompletionThreshold
      ) {
        setBgColor(colorFinishWork);
        setWorkText(t("job_is_done"));
        setSliderLeft(relativeMouse + "px");
      } else {
        // const newColor = calculateColor(relativeMouse, slideMovementTotal); // NOT DELETE
        setBgColor(colorInWork);
        setSliderLeft(relativeMouse + "px");
        setWorkText(t("finish_job"));
      }
    },
    [slideMovementTotal]
  );

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isMousePressed) {
        return;
      }
      const currentMouse = event.clientX || event.touches[0].pageX;
      const relativeMouse = currentMouse - initialMouse;

      updateUI(relativeMouse);
    };

    document.body.addEventListener("mousemove", handleMouseMove);
    document.body.addEventListener("touchmove", handleMouseMove);
    document.body.addEventListener("mouseup", handleMouseUp);
    document.body.addEventListener("touchend", handleMouseUp);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
      document.body.removeEventListener("touchmove", handleMouseMove);
      document.body.removeEventListener("mouseup", handleMouseUp);
      document.body.removeEventListener("touchend", handleMouseUp);
    };
  }, [
    isMousePressed,
    initialMouse,
    slideMovementTotal,
    finishWork,
    updateUI,
    resetSlider,
    resetColor,
    completeSlide,
  ]);

  return (
    <div
      ref={buttonBackgroundRef}
      className="work-btn"
      style={{ backgroundColor: bgColor }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
    >
      <span className="slide-text">{workText}</span>
      <div ref={sliderRef} className="slider" style={{ left: sliderLeft }}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default FinishWorkBtn;

import { memo } from "react";

import classNames from "classnames";

import styles from "./styles.module.scss";

export const TextTheme = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  INVERTED: "inverted",
  ERROR: "error",
  ACCENT: "accent",
  DISABLE: "disable",
  SUCCESS: "success",
  FAILURE: "failure",
};

export const TextAlign = {
  RIGHT: "right",
  LEFT: "left",
  CENTER: "center",
};

export const TextSize = {
  FONT_12: "text12",
  FONT_15: "text15",
  FONT_16: "text16",
  FONT_18: "text18",
  FONT_24: "text24",
};

export const TextWeight = {
  LIGHT: "weight-light",
  MEDIUM: "weight-medium",
  BOLD: "weight-large",
};

export const TextTags = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  DIV: "div",
  P: "p",
};

const Text = (props) => {
  const {
    className,
    text,
    tag = TextTags.P,
    theme = TextTheme.PRIMARY,
    align = TextAlign.LEFT,
    size = TextSize.TEXT_16,
    weight = TextWeight.MEDIUM,
  } = props;

  const textClasses = classNames(
    styles.text,
    styles[theme],
    styles[align],
    styles[size],
    styles[weight],
    className,
  );

  const Component = tag;

  return <Component className={textClasses}>{text}</Component>;
};

export default memo(Text);

import { useEffect, useMemo, useRef, useState } from "react";
import colors from "../../../../styles/colors/index";
import createPolyline from "../../helpers/createPolyline";
import useCreatePoints from "../useCreatePoints";
import { getNotPassedRoute } from "./helpers/getNotPassedRoute";

const fullRouteId = "fullRouteId";
const passedRouteId = "passedRouteId";

const useBuildFullRoute = (props) => {
  const { polylinePositions, map, currentTheme, coordsDriver, mapPoints, mapLoaded, disabledFunctions } =
    props;

  const [polylineWithDividePoint, setPolylineWithDividePoint] = useState(null);

  const fullRouteStyles = useMemo(
    () => ({
      id: fullRouteId,
      type: "line",
      source: fullRouteId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": `${colors[currentTheme].routeColor}`,
        "line-width": 10,
      },
    }),
    [currentTheme],
  );

  const passedRouteStyles = useMemo(
    () => ({
      id: passedRouteId,
      type: "line",
      source: passedRouteId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#A1A1A1", // Color of the polyline
        "line-width": 10, // Width of the polyline
      },
    }),
    [],
  );

  const lastPointIndx = useRef(null);

  // create route
  const notPassedRoute = useMemo(() => {
    return getNotPassedRoute({
      disabledFunctions,
      lastPointIndx,
      map,
      polylinePositions,
      coordsDriver,
      setPolylineWithDividePoint,
    });
  }, [coordsDriver, disabledFunctions, map, polylinePositions]);

  useEffect(() => {
    if (
      map.current &&
      polylineWithDividePoint &&
      mapLoaded &&
      notPassedRoute &&
      (lastPointIndx.current || lastPointIndx.current === 0)
    ) {
      const passedRoute = polylineWithDividePoint.slice(0, lastPointIndx.current + 1);

      localStorage.setItem("passedRoute", JSON.stringify(passedRoute));

      // create polylines
      const routeGeoJSON = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: notPassedRoute.map((item) => [item[1], item[0]]),
        },
      };

      const passedRouteGeoJSON = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: passedRoute.map((item) => [item[1], item[0]]),
        },
      };

      // load route on first render

      if (passedRoute.length > 0) {
        createPolyline({
          map,
          sourceId: passedRouteId,
          routeGeoJSON: passedRouteGeoJSON,
          layoutStyles: passedRouteStyles,
        });
      }

      createPolyline({
        map,
        sourceId: fullRouteId,
        routeGeoJSON,
        layoutStyles: fullRouteStyles,
      });
    } else {
      // remove polyline when its not exist
      if (map.current.getSource(fullRouteId)) {
        map.current.removeLayer(fullRouteId); // Remove the layer first
        map.current.removeSource(fullRouteId); // Remove the source
      }

      if (map.current.getSource(passedRouteId)) {
        map.current.removeLayer(passedRouteId); // Remove the layer first
        map.current.removeSource(passedRouteId); // Remove the source
      }
    }
  }, [
    mapLoaded,
    notPassedRoute,
    polylineWithDividePoint,
    coordsDriver,
    map,
    fullRouteStyles,
    passedRouteStyles,
  ]);

  //create points
  useCreatePoints({
    map,
    mapPoints,
    mapLoaded,
    polylineWithDividePoint: polylineWithDividePoint,
    coordsDriver,
  });
};

export default useBuildFullRoute;

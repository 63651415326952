import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { ReactComponent as BackIcon } from '../../images/back.svg'
import {
  updNewsItem,
  getNewsItem,
  clearError,
} from '../MainNews/mainnews.actions'
import moment from 'moment/moment'


import './newsarticle.scss'
import { t } from 'i18next'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'

const formatDateFunc = date => {
  const format = {
    date: 'DD.MM.YYYY',
  }
  return `${moment(moment.utc(date).toDate()).format(format.date)}`
}

class NewsArticle extends Component {
  componentDidMount() {
    let id = this.props.itemId
    this.props.getNewsItem(id)
    if (!this.props.article.isRead && this.props.article !== undefined) {
      this.props.updNewsItem(id)
    }
  }

  tryClearError = () => {
    this.props.clearError()
  }

  render() {
    const { article, errorStatus } = this.props

    return (
      <Fragment>
        <div className="container-fluid p-0 m-0">
          <div className="row m-0">
            <div className="article-section col-sm-12 p-0">
            <MobileTopBarHeight />
              <div className="top-nav">
                <NavLink to="/mainnews">
                  <BackIcon className="ArrowLeft" />
                </NavLink>
                <h1>{article.title}</h1>
              </div>
              <div className="article-block">
                <p className="date">{formatDateFunc(article.createdAt)}</p>
                <p className="text">{article.content}</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    article: state.mainnews.newsitem,
    itemId: state.mainnews.itemId,
    errorStatus: state.mainnews.errorStatus,
    errorMsg: state.mainnews.errorMsg,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNewsItem: id => dispatch(getNewsItem(id)),
    updNewsItem: id => dispatch(updNewsItem(id)),
    clearError: () => dispatch(clearError()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsArticle)

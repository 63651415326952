import React, { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { ReactComponent as HomeIcon } from "src/images/home.svg";
import { ReactComponent as MegaphoneIcon } from "src/images/megaphone.svg";
import { ReactComponent as NewsIcon } from "src/images/ridea.svg";
import { ReactComponent as MenuIcon } from "src/images/grid.svg";

import { Badge, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getNewsList } from "../MainNews/mainnews.actions";
import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";
import { disableHistoryRoutes } from "src/shared/constants/router";
import "./menu.scss"; //!TODO: will need to be removed when DashboardPage is rewritten

const BottomMenu = (props) => {
  const { getNewsList, storeNews, isDisabled, loginStatus } = props;

  const [currentPath, setCurrentPath] = useState(useLocation().pathname.slice(1) || "orders");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      navigate(`/${newValue}`, { replace: true, state: null });
    },
    [navigate],
  );

  const countUnreadNews = useCallback(() => {
    return storeNews.filter((news) => !news.isRead).length;
  }, [storeNews]);

  useEffect(() => {
    getNewsList();
  }, [getNewsList]);

  useEffect(() => {
    setCurrentPath(location.pathname.slice(1));
  }, [location]);

  if (!loginStatus || !disableHistoryRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <div className={classNames(styles.bottomNavigation, { [styles.isDisabledMenu]: isDisabled }, [])}>
      <Paper
        elevation={2}
        sx={{ position: "fixed", width: "100%", bottom: 0, left: 0, right: 0, height: "80px" }}
      >
        <BottomNavigation onChange={handleChange} showLabels sx={{ height: "100%" }} value={currentPath}>
          <BottomNavigationAction icon={<HomeIcon />} label={t("main")} value="orders" />
          <BottomNavigationAction
            icon={
              <Badge badgeContent={countUnreadNews()} color="error" overlap="rectangular">
                <MegaphoneIcon />
              </Badge>
            }
            label={t("news")}
            value="mainnews"
          />
          <BottomNavigationAction
            icon={<NewsIcon className={styles.travelsIcon} />}
            label={t("trevels")}
            value="history"
          />
          <BottomNavigationAction icon={<MenuIcon />} label={t("options")} value="dashboard" />
        </BottomNavigation>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginStatus: state.login.loginStatus,
  storeNews: state.mainnews.news,
  atwork: state.orders.workStatus,
  currentOrderStatus: state.order.currentOrderStatus,
});

const mapDispatchToProps = {
  getNewsList,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(BottomMenu));

import React, { memo, useCallback } from "react";
import { connect } from "react-redux";
import {
	addCardData,
	getUserCards,
} from "src/components/CreditCard/store/ccard.action";
import FinancialOperation from "src/components/FinancialOperation";
import { clearError } from "../LoginPage/login.actions";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import { showModalAlertSC } from "../ModalAlertSC/modalAlertSC.actions";
import {
	ClearM,
	convertBonuses,
	getMoney,
	sendMoney,
	setNewCard,
} from "../MoneyManagementPage/store/money.actions";
import { getDriverInfo } from "../RegistrationPage/registration.actions";
import PageHeader from "src/components/PageHeader";
import PageLayout from "src/components/PageLayout";
import { ReactComponent as HistoryTransfer } from "../../images/history.svg";
import styles from "./styles.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";

const FinancialOperationPage = (props) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const operationParams = searchParams.get("operation");

	const getTitle = useCallback(() => {
		switch (operationParams) {
			case "withdraw":
				return t("withdraw_to_card");
			case "replenish":
				return t("replenish_balance");
			case "bonuses":
				return t("transfer_bonuses");
			default:
				return;
		}
	}, [operationParams]);

	const onAdditionClick = useCallback(() => {
		navigate("/stransactions");
	}, [navigate]);

	return (
		<>
			<PageHeader
				Icon={HistoryTransfer}
				iconClassName={styles.icon}
				onAdditionClick={onAdditionClick}
				title={getTitle()}
			/>
			<PageLayout>
				<FinancialOperation operationParams={operationParams} {...props} />
			</PageLayout>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		driver: state.registration.driver,
		profinfo: state.registration.profinfo,
		profLoading: state.registration.profLoading,
		userCardsList: state.ccard.userCardsList,
		setMStatus: state.money.setMStatus,
		newCard: state.money.newCard,
		getMStatus: state.money.getMStatus,
		rejectTransactionMoney: state.money.rejectTransactionMoney,
		showMAlert: state.modalAlert.showMAlert,
		isLoading: state.ccard.isLoading,
		bonusStatus: state.money.bonusStatus,
		errorStatus: state.order.errorStatus,
		errorMsg: state.order.errorMsg,
		bonusError: state.money.bonusError,
		cardFormData: state.ccard.addCardData,
		showMAlertSC: state.modalAlertSC.showMAlert,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserCards: () => dispatch(getUserCards()),
		sendMoney: (info) => dispatch(sendMoney(info)),
		setNewCard: (cardInfo) => dispatch(setNewCard(cardInfo)),
		getMoney: (info) => dispatch(getMoney(info)),
		ClearM: () => dispatch(ClearM()),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		convertBonuses: (info) => dispatch(convertBonuses(info)),
		getDriverInfo: (isFinancialPage) =>
			dispatch(getDriverInfo(isFinancialPage)),
		clearError: () => dispatch(clearError()),
		addCardData: (data) => dispatch(addCardData(data)),
		showModalAlertSC: (status) => dispatch(showModalAlertSC(status)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(memo(FinancialOperationPage));

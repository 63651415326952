import React, { memo, useCallback } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  addCardData,
  deleteCard,
  getUserCardById,
  getUserCards,
} from "src/components/CreditCard/store/ccard.action";
import MoneyManagement from "src/components/MoneyManagement";
import PageHeader from "src/components/PageHeader";
import PageLayout from "src/components/PageLayout";
import {
  ClearM,
  convertBonuses,
  getMoney,
  sendMoney,
  setNewCard,
} from "src/pages/MoneyManagementPage/store/money.actions";
import { clearError } from "../LoginPage/login.actions";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import { showModalAlertSC } from "../ModalAlertSC/modalAlertSC.actions";
import { getDriverInfo } from "../RegistrationPage/registration.actions";
import { ReactComponent as HistoryTransfer } from "src/images/history.svg";
import styles from "./styles.module.scss";

const MoneyManagementPage = (props) => {
  const navigate = useNavigate();

  const onAdditionClick = useCallback(() => {
    navigate("/stransactions");
  }, [navigate]);

  return (
    <>
      <PageHeader
        Icon={HistoryTransfer}
        backPage="/dashboard"
        iconClassName={styles.icon}
        onAdditionClick={onAdditionClick}
        title={t("financial_management")}
      />
      <PageLayout>
        <MoneyManagement {...props} />
      </PageLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    driver: state.registration.driver,
    profinfo: state.registration.profinfo,
    profLoading: state.registration.profLoading,
    userCardsList: state.ccard.userCardsList,
    isLoading: state.ccard.isLoading,
    setMStatus: state.money.setMStatus,
    getMStatus: state.money.getMStatus,
    showMAlert: state.modalAlert.showMAlert,
    newCard: state.money.newCard,
    bonusStatus: state.money.bonusStatus,
    errorStatus: state.order.errorStatus,
    errorMsg: state.order.errorMsg,
    bonusError: state.money.bonusError,
    cardFormData: state.ccard.addCardData,
    showMAlertSC: state.modalAlertSC.showMAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserCards: () => dispatch(getUserCards()),
    setNewCard: (cardInfo) => dispatch(setNewCard(cardInfo)),
    sendMoney: (info) => dispatch(sendMoney(info)),
    getMoney: (info) => dispatch(getMoney(info)),
    ClearM: () => dispatch(ClearM()),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    convertBonuses: (info) => dispatch(convertBonuses(info)),
    getDriverInfo: (isFinancialPage) => dispatch(getDriverInfo(isFinancialPage)),
    clearError: () => dispatch(clearError()),
    addCardData: (data) => dispatch(addCardData(data)),
    showModalAlertSC: (status) => dispatch(showModalAlertSC(status)),
    deleteCard: (id) => dispatch(deleteCard(id)),
    getUserCardById: (id) => dispatch(getUserCardById(id)),
  };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(MoneyManagementPage));

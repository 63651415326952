import { Dialog, DialogContent } from "@mui/material";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Portal } from "../Portal/Portal";

export const ModalPosition = {
  CENTER: "modalCenter",
  BOTTOM: "modalBottom",
};

export const Modal = (props) => {
  const { children, isOpen, onClose, position, noPadding } = props;

  const modalClass = classNames(styles.modal, { [styles.noPadding]: noPadding }, styles[position]);

  return (
    <Portal>
      <Dialog className={modalClass} onClose={onClose} open={isOpen}>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Portal>
  );
};

import React, { Component, Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as Search } from "src/images/search.svg";
import { ReactComponent as Sort } from "src/images/sort.svg";
import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as Path } from "src/images/Path.svg";
import { ReactComponent as MapPin } from "src/images/MapPin.svg";
import { ReactComponent as MapPinCircle } from "src/images/MapPinCircle.svg";

import { getHistory, setOrderId, clearError } from "./store/history.actions";
import moment from "moment-timezone";


import "./history.scss";
import { t } from "i18next";
import BottomMenu from "../BottomMenu";
import { getRedirectPage } from "../../services/getRedirectPage";
import ModalHistory from "../ModalHistory";
import { getValuteSymbol } from "../../services/getValuteSymbol";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import SalaryModule from "src/feature/SalaryModule";
import { getSalaryData } from "./helpers/getSalaryData";
import dayjs from "dayjs";
import { dateToCorrectFormat } from "src/feature/SalaryModule/helpers/dayToCorrectFormat";

const formatDateFuncTime = (date) => {
  const format = {
    date: "HH:mm",
  };

  const timezone = localStorage.getItem("timezone") || "Europe/Kiev";
  const utcDate = moment.utc(date);

  const convertedDate = utcDate.tz(timezone);

  return convertedDate.format(format.date);
};

const HistoryPage = ({ orders, profinfo, getHistory, setOrderId, errorMsg, errorStatus, clearError }) => {
  const [value, setValue] = useState("");
  const [histores, setHistores] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [typeSort, setTypeSort] = useState("date");
  const [isSearchSort, setIsSearchSort] = useState(false);
  //salaryModule
  const [salaryDateFrom, setSalaryDateFrom] = useState(dateToCorrectFormat(dayjs()));
  const [salaryDateTo, setSalaryDateTo] = useState(dateToCorrectFormat(dayjs()));
  const [salaryData, setSalaryData] = useState(null);

  const searchInput = React.createRef();
  const today = moment(new Date()).format("DD.MM.YYYY");
  let sortedOrders = {};

  const setOrdId = (id) => {
    setOrderId(id);
  };

  const tryClearError = () => {
    clearError();
  };

  const search = (e) => {
    const value = e.target.value;
    const histores = orders.filter((elem) =>
      elem.destinationPoints.find((e) => e?.name?.toLowerCase().includes(value.toLowerCase())),
    );
    setValue(value);
    setHistores(histores);
  };

  const sortBy = (type) => {
    setIsModal(false);
    setTypeSort(type);
  };
  const openSearch = () => {
    setIsSearchSort(true);
    setTimeout(() => searchInput.current.focus(), 500);
  };
  const closeSearch = () => {
    setIsSearchSort(false);
  };

  // sorting orders and add to sortedOrders
  histores.forEach((order) => {
    let createdAtDate = moment(new Date(order.createdAt)).format("DD.MM.YYYY");

    if (createdAtDate === today) {
      createdAtDate = t("today-day");
    }

    if (!sortedOrders[createdAtDate]) {
      sortedOrders = { ...sortedOrders, [createdAtDate]: [] };
    }

    sortedOrders[createdAtDate].push(order);
  });

  // filter orders
  const sortedOrdersByDate = () => {
    const ordersWithDates = Object.keys(sortedOrders)
      .map((key) => ({ orders: sortedOrders[key], date: key }))
      .sort((a, b) => {
        const dateA = a.date.split(".").reverse().join("-");
        const dateB = b.date.split(".").reverse().join("-");
        return new Date(dateB) - new Date(dateA);
      });

    switch (typeSort) {
      // from bigger date to lower date
      case "date":
        return ordersWithDates;

      // from lower date to bigger date
      case "id":
        return ordersWithDates.reverse();

      default:
        return ordersWithDates;
    }
  };

  const lastDate = sortedOrdersByDate()[0]?.date;

  const dateTitle = () => {
    if (lastDate !== today) {
      return lastDate;
    }

    return typeSort === "id" ? lastDate : t("today-day");
  };

  // draw sortedOrdersByDate elements
  const ordersList = sortedOrdersByDate().map((sortedOrder) => {
    return (
      <>
        {sortedOrder.date !== dateTitle() && <p className="date-text">{sortedOrder.date}</p>}
        {sortedOrder.orders.map((order, i) => {
          const distance = order?.distance / 1000;
          return (
            <NavLink key={order.id + i} onClick={() => setOrdId(order.id)} to="/historyorder">
              <li className="list-item" key={order.id}>
                <div className="payment-data">
                  <div className="sum">
                    {order.paymentType === 0 ? (
                      <div className="coins-sum">
                        <CreditCard
                          className="payment-icon money"
                          style={{ height: "18px", paddingRight: "2px" }}
                        />{" "}
                        {order.pricePaid} {getValuteSymbol(order.currency)}
                      </div>
                    ) : null}
                    {order.paymentType === 1 ? (
                      <div className="coins-sum">
                        <CreditCard
                          className="payment-icon money"
                          style={{ height: "18px", paddingRight: "2px" }}
                        />{" "}
                        {order.pricePaid} {getValuteSymbol(order.currency)}
                      </div>
                    ) : null}
                    {order.paymentType === 2 ? (
                      <div className="coins-sum">
                        <CreditCard
                          className="payment-icon money"
                          style={{ height: "18px", paddingRight: "2px" }}
                        />{" "}
                        {order.pricePaid} {getValuteSymbol(order.currency)}
                      </div>
                    ) : null}
                    {order.paymentType === 4 ? (
                      <div className="coins-cum">
                        <CreditCard
                          className="payment-icon money"
                          style={{ height: "18px", paddingRight: "2px" }}
                        />{" "}
                        {order.pricePaid} {getValuteSymbol(order.currency)}
                      </div>
                    ) : null}
                  </div>
                  <div className="distance-sum-main">
                    <div className="distance-sum">
                      <Path
                        className="payment-icon money"
                        style={{
                          width: "15px",
                          height: "15px",
                          paddingRight: "3px",
                        }}
                      />{" "}
                      {distance.toFixed(1)}{" "}
                      <span style={{ fontSize: "11px", marginTop: "2px" }}>{t("km")}</span>
                    </div>
                  </div>
                  <div className="class-sum-main">
                    <div className="class-sum">{t(order.carClass)}</div>
                  </div>
                  <div className="time-sum-main">
                    <div className="time-sum">{formatDateFuncTime(order?.createdAt)}</div>
                  </div>
                </div>
                <div className="line-items" style={{ paddingTop: "20px" }}>
                  <div className="col-block">
                    {order.destinationPoints.length > 1
                      ? order.destinationPoints.map((item, i, arr) => {
                          const isLast = order.destinationPoints.length - 1 !== i;
                          return (
                            <div className="col-address" key={i}>
                              <div className="icon-type-pin">
                                {i > 0 && i < arr.length - 1 ? (
                                  <MapPinCircle
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      marginLeft: "2px",
                                      marginRight: "5.4px",
                                    }}
                                  />
                                ) : (
                                  <MapPin
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className={
                                  "address-col-text" +
                                  " " +
                                  (isLast === false ? "address-col-text-without-border" : "")
                                }
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </li>
            </NavLink>
          );
        })}
      </>
    );
  });

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  // get data by dates for salary module
  useEffect(() => {
    const requestSalaryData = async () => {
      const salaryData = await getSalaryData({
        salaryDateFrom,
        salaryDateTo,
      });
      setSalaryData(salaryData);
    };

    requestSalaryData();
  }, [salaryDateFrom, salaryDateTo]);

  useEffect(() => {
    if (histores.length === 0 && orders?.length && value === "") {
      setHistores(orders);
    }
  }, [histores, orders, orders?.length, setHistores, value]);

  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row m-0">
          <div className="history-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <p className="top-nav-text">{t("travel_history")}</p>

            {salaryData && (
              <SalaryModule
                salaryData={salaryData}
                dateFrom={salaryDateFrom}
                dateTo={salaryDateTo}
                setDateFrom={(date) => setSalaryDateFrom(date)}
                setDateTo={(date) => setSalaryDateTo(date)}
                currency={getValuteSymbol(profinfo.currency)}
              />
            )}
            <div className="container-of-search">
              <div className="buttons-container">
                <button
                  className="sort-button"
                  onClick={() => {
                    setIsModal(true);
                  }}
                >
                  <Sort style={{ width: "22px", height: "22px" }} />
                </button>
                {dateTitle() && <p className="date-text-now">{dateTitle()}</p>}
                {isSearchSort === false && (
                  <button className="search-button" onClick={openSearch}>
                    <Search style={{ width: "21px", height: "21px" }} />
                  </button>
                )}
              </div>
              {isSearchSort && (
                <div className="search-main-container">
                  <input
                    onBlur={closeSearch}
                    onChange={search}
                    placeholder={t("search")}
                    ref={searchInput}
                    value={value}
                  />
                  <Search className="icon-search" style={{ marginTop: "8px" }} />
                </div>
              )}
            </div>
            {orders.length === 0 ? (
              <p className="no-history">{t("have_no_completed_trips")}</p>
            ) : (
              <ul className="orders-list">{ordersList}</ul>
            )}
          </div>
        </div>
      </div>
      {isModal && <ModalHistory sortBy={sortBy} typeSort={typeSort} />}
      <BottomMenu />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.history.doneOrders,
    errorStatus: state.history.errorStatus,
    errorMsg: state.history.errorMsg,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHistory: () => dispatch(getHistory()),
    setOrderId: (id) => dispatch(setOrderId(id)),
    clearError: () => dispatch(clearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);

/* eslint-disable no-undef */

// send message for success login
export const onLogin = () => {
  //android
  try {
    Native.onLoggedIn();
  } catch {
    console.log("its not android");
  }
  //ios

  try {
    webkit.messageHandlers.onLoggedIn.postMessage("Login completed");
  } catch {
    console.log("its not ios");
  }
};

// stop rington for android when accept/cancel/dont take order
export const callStopOrderRingtone = () => {
  //android
  try {
    Native.callStopOrderRingtone();
  } catch {
    console.log("its not android");
  }
  //ios

  try {
    webkit.messageHandlers.callStopOrderRingtone.postMessage("callStopOrderRingtone");
  } catch {
    console.log("its not ios");
  }
};

// change work status for ios
export const setWebKitWorkState = (status) => {
  try {
    webkit.messageHandlers.onChangeWorkingState.postMessage(status);
  } catch {
    console.log("its not Apple device");
  }
};

//Change language for ios/android
export const appChangeLanguage = (appType) => {
  try {
    if (appType === "android") {
      Native.onLanguageChanged();
    } else {
      webkit.messageHandlers.onLanguageChanged.postMessage("onLanguageChanged");
    }
  } catch {
    console.log("its not Apple device");
  }
};

export const fromMobileSetOtp = (otpCode) => {
  alert("OTP is " + otpCode);
};

// change theme for ios
export const onThemeChanged = (theme) => {
  //ios

  try {
    webkit.messageHandlers.onThemeChanged.postMessage(theme);
  } catch {
    console.log("onThemeChanged its not ios");
  }
};

// allow native app add padding (true/false), use for other websites
export const onTopPaddingRequested = (value) => {
  //android
  try {
    Native.onTopPaddingRequested(value);
  } catch {
    console.log("onTopPaddingRequested its not android");
  }
  //ios

  try {
    webkit.messageHandlers.onTopPaddingRequested.postMessage(value);
  } catch {
    console.log("onTopPaddingRequested its not ios");
  }
};

// stop app loader after call
export const onContentLoaded = () => {
  //android
  try {
    Native.onContentLoaded();
  } catch {
    console.log("onContentLoaded its not android");
  }
  //ios

  try {
    webkit.messageHandlers.onContentLoaded.postMessage("onContentLoaded");
  } catch {
    console.log("onContentLoaded its not ios");
  }
};

import React from "react";
import { withTranslation } from "react-i18next";
import Select from "src/shared/ui/Select";
import { t } from "i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateProfileLanguage } from "../RegistrationPage/registration.actions";

import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

import styles from "./styles.module.scss";
import { SUPPORTED_LANGUAGES } from "src/shared/const/languages";

const findCurrentLanguage = (profinfoLanguage) =>
  SUPPORTED_LANGUAGES.find((item) => item.locale === String(profinfoLanguage)) || null;

const ChooseLanguages = (props) => {
  const { profinfo, updateProfileLanguage } = props;
  const languageId = findCurrentLanguage(profinfo.language)?.id;
  const navigate = useNavigate();

  const getCurrentLanguage = async (item) => {
    await updateProfileLanguage(item.locale);
  };

  return (
    <div className={styles.languageSelector}>
      <Select
        currentId={languageId}
        listItem={SUPPORTED_LANGUAGES}
        onSetItem={getCurrentLanguage}
        title={t("choice_language")}
        toggleSelect={() => navigate(-1)}
        topCustomComponent={<MobileTopBarHeight />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileLanguage: (language) => dispatch(updateProfileLanguage(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChooseLanguages));

// NOT DELETE, its for older version webview
import "globalthis/auto";
import "core-js/stable";
//

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app/App";
import "./i18n/config";
import Providers from "./app/providers";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Providers>
    <App />
  </Providers>,
);

import React, { Component } from "react";
import { connect } from "react-redux";

import * as signalR from "@microsoft/signalr";
import withNavigation from "src/hoc/NavigateForClass";

import { getOrderRequestManage } from "../../app/app.actions";
import { apiWS } from "../../config";
import { checkWaitingTime } from "../Order/components/Timer/timer.actions";
import {
  incomingOrderInfo,
  currentOrderInfo,
  removeOrderRequest,
  orderStatus,
  updatePrice,
  declineIncomingOrder,
  closeMissedModalOrder,
} from "../Order/order.actions";
import {
  checkInterval,
  changeSocketConnect,
  driverOffline,
  chgWorkStatus,
  checkPushOrderAccepted,
} from "../Orders/orders.actions";

// const textFormator = (text) => {
//   const arrAdress = text.split(', ');
//   let minusCountElement = 1;
//   let mainText = arrAdress[0] + ' ';

//   if(parseInt(arrAdress[1])){
//     mainText += arrAdress[1];
//     minusCountElement++;
//   }

//   return {
//     mainText,
//     secondaryText : arrAdress.slice(minusCountElement).join(', ')
//   }
// }

class SignalR extends Component {
  constructor(props) {
    super(props);
    this.interval = null;

    const retryTimes = [0, 200];
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(apiWS, {
        accessTokenFactory: () => {
          const accessToken = localStorage.getItem("accessToken");
          return accessToken;
        },
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
          const index =
            context.previousRetryCount < retryTimes.length
              ? context.previousRetryCount
              : retryTimes.length - 1;
          return retryTimes[index];
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.serverTimeoutInMilliseconds = 600000;

    let reconnectDelay = 2;
    this.connection.onreconnecting((error) => {
      console.assert(this.connection.state === signalR.HubConnectionState.Reconnecting);

      console.error(`Connection lost due to error ${error}. Reconnecting in ${reconnectDelay} seconds...`);

      setTimeout(() => {
        reconnectDelay *= 2;
        this.connection.start().catch((err) => console.error(err));
      }, reconnectDelay * 1000);
    });

    this.connection.onreconnected(() => {
      this.connection
        .invoke("initializeAsync")
        .then()
        .catch((err) => console.log("Socket -> Error*: ", err));
    });

    this.connection.onclose(() => {
      localStorage.setItem("isOnline", false);
    });
  }

  componentWillUnmount() {
    if (this.connection && this.connection.state == signalR.HubConnectionState.Connected) {
      this.connection.stop().then();
      console.log("Socket -> connection closed");
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.props.getOrderRequestManage, 1000);

    this.connection.on("extended_paycheck", (data) => {
      console.log("Socket extended_paycheck -> ", data);
      this.props.updatePrice(data.data.priceEstimated);
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    this.connection.on("current_order_info", (data) => {
      console.log("Socket current_order_info ->", data);
      this.props.currentOrderInfo(data.data);

      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    this.connection.on("connected", (_) => {
      console.log("Socked connected");
      postMessage("connected", "*");
    });

    this.connection.on("disconnected", (_) => {
      console.log("Socked disconnected");
      const getWorkData = {
        status: false,
        driverId: this.props.regInfo.id,
        carId: localStorage.getItem("selectedCarId"),
      };
      this.props.chgWorkStatus(getWorkData);
      postMessage("disconnected", "*");
    });

    this.connection.on("accept_order_request", this.handleAcceptOrderRequest); // Отвечает за появления модального окна возможного заказа

    this.connection.on("remove_order_request", (data) => {
      console.log("Socket remove_order_request -> ", data);
      this.props.removeOrderRequest(data.data);
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    this.connection.on("order_status", (data) => {
      //  отмена поездки клиентом
      console.log("Socket order_status -> ", data);
      localStorage.removeItem("orderStage");
      localStorage.removeItem("orderShowRequestDelaySec");

      this.props.orderStatus(data.data);
      this.props.checkPushOrderAccepted();

      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    this.connection.on("driver_offline", (data) => {
      console.log("Socket driver_offline -> ", data);
      this.props.driverOffline(data.data);
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    this.connection.on("order_pending_start", (data) => {
      console.log("Socket order_pending_start -> ", data);
      const date = new Date(data.data.startTime);
      const now = new Date();
      const elapsed = now.getTime() - date.getTime();
      // const fromTime = new Date(0, 0, 0, 0, 0, 0, elapsed);

      this.props.checkWaitingTime(elapsed);
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then();
      }
    });

    // Переподключаемся после падения коннекта
    this.connection.on("reconnected", () => {
      console.log("Socket -> reconnected");
      clearInterval(this.interval);
      this.connection
        .invoke("initializeAsync")
        .then()
        .catch((err) => console.log("Socket -> Error*: ", err));

      // Отправляем текущие координаты
      // let pos;
      // const coordsDriver = JSON.parse(localStorage.getItem("coords"));
      // this.interval = setInterval(() => {
      //   pos = {
      //     lat: coordsDriver.latitude,
      //     lon: coordsDriver.longitude,
      //   };

      //   if (
      //     this.connection &&
      //     this.connection.state == signalR.HubConnectionState.Connected &&
      //     coordsDriver.latitude &&
      //     coordsDriver.longitude
      //   ) {
      //     this.connection
      //       .invoke("sendMessage", "send_location", JSON.stringify(pos))
      //       .then()
      //       .catch((err) => console.log("Socket -> Error*: ", err));
      //   }
      // }, 5000); //TODO
    });

    this.connection.off("reconnect", (data) => {
      console.log("Socket reconnect -> ", data);
      postMessage("reconnect", "*");
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", '{ "messageId": "' + data.messageId + '" }')
          .then()
          .catch((err) => console.log("Socket -> Error*: ", err));
      }
    });

    this.connection.on("send", (data) => {
      // console.log('Socket send -> ', data)
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { newOrderRequestManage } = this.props;

  //   if (prevProps.newOrderRequestManage !== newOrderRequestManage) {
  //     if (newOrderRequestManage) {
  //       // добавляем обработчик события accept_order_request
  //       this.connection.on("accept_order_request", this.handleAcceptOrderRequest);
  //     } else {
  //       // удаляем обработчик события accept_order_request
  //       this.connection.off("accept_order_request", this.handleAcceptOrderRequest);
  //     }
  //   }
  // }

  handleAcceptOrderRequest = async (data) => {
    const { incomingOrderInfo } = this.props;
    const lastOrderId = localStorage.getItem("lastOrderId");
    const driverId = localStorage.getItem("driverId");
    const currentPage = this.props.location.pathname;

    if (localStorage.getItem("readyForOrder") === "true") {
      console.log("Socket -> accept_order_request. Driver ready for order");

      const dataToSendMessage = {
        messageId: data.messageId,
        orderId: data.data.orderId,
        driverId,
      };

      if (lastOrderId === data.data.orderId) {
        if (data.messageId) {
          this.connection
            .invoke("sendMessage", "message_received", JSON.stringify(dataToSendMessage))
            .then(() => console.log("Good"))
            .catch((error) => console.log("Error occurred during sendMessage:", error));
        }

        return;
      }

      // localStorage.setItem('orderShowRequestBody', JSON.stringify(data.data)) //!TODO for test CAR-421TODO for test
      incomingOrderInfo(data.data);
      if (data.messageId) {
        this.connection
          .invoke("sendMessage", "message_received", JSON.stringify(dataToSendMessage))
          .then(() => console.log("Good"))
          .catch((error) => console.log("Error occurred during sendMessage:", error));
      }

      if (currentPage !== "/orders") {
        return this.props.navigate("/orders");
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const isSocketInitialized = JSON.parse(localStorage.getItem("socket_initialized"));
    let pos;
    if (
      this.props.atwork &&
      newProps.atwork === false &&
      this.connection &&
      this.connection.state == signalR.HubConnectionState.Connected
    ) {
      this.connection
        .stop()
        .then()
        .catch((err) => console.log("Socket -> Error*: ", err));
    }

    if (!isSocketInitialized && newProps.atwork === true) {
      localStorage.setItem("socket_initialized", true);
      this.connection
        .start()
        .then(() => {
          console.log("SignalR Connected");
          this.connection
            .invoke("initializeAsync")
            .then()
            .catch((err) => console.log("Socket -> Error*: ", err));

          // const coordsDriver = JSON.parse(localStorage.getItem("coords"));
          // if (!coordsDriver) {
          //   return;
          // }

          // this.interval = setInterval(() => {
          //   // console.log('Sending coordinates to socket', this.props.appCoords)
          //   //NOTE: send coordinates every 5 seconds
          //   pos = {
          //     lat: coordsDriver.latitude,
          //     lon: coordsDriver.longitude,
          //   };

          //   if (this.connection.state == signalR.HubConnectionState.Connected) {
          //     this.connection
          //       .invoke("sendMessage", "send_location", JSON.stringify(pos))
          //       .then()
          //       .catch((err) => console.log("Socket -> Error*: ", err));
          //   }
          // }, 5000); //!TODO
        })
        .catch((err) => {
          console.log("SignalR Connection Error: ", err);
          // window.location.href = '/orders'
        });
    }
  }

  render() {
    //  { lat: 50.464626, lon: 30.628115 }   kiev
    //  { lat: 47.9344812, lon: 33.4383243 } KR
    /* Ukraine center   { lat: 49.0275, lng: 31.48278 };  */

    if (this.props.atwork === false) {
      clearInterval(this.props.interval);
      let status = false;
      this.props.changeSocketConnect(status);
    }

    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    atwork: state.orders.workStatus,
    appCoords: state.app.coords,
    interval: state.orders.interval, // ??
    regInfo: state.registration.driver,
    newOrderRequestManage: state.app.newOrderRequestManage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    incomingOrderInfo: (data) => dispatch(incomingOrderInfo(data)),
    currentOrderInfo: (data) => dispatch(currentOrderInfo(data)),
    removeOrderRequest: () => dispatch(removeOrderRequest()),
    orderStatus: (data) => dispatch(orderStatus(data)),
    checkInterval: (interval) => dispatch(checkInterval(interval)),
    changeSocketConnect: (status) => dispatch(changeSocketConnect(status)),
    driverOffline: (data) => dispatch(driverOffline(data)),
    checkWaitingTime: (data) => dispatch(checkWaitingTime(data)),
    updatePrice: (data) => dispatch(updatePrice(data)),
    chgWorkStatus: (data) => dispatch(chgWorkStatus(data)),
    declineIncomingOrder: (orderId, declineType) => dispatch(declineIncomingOrder({ orderId, declineType })),
    getOrderRequestManage: () => dispatch(getOrderRequestManage()),
    closeMissedModalOrder: () => dispatch(closeMissedModalOrder()),
    checkPushOrderAccepted: () => dispatch(checkPushOrderAccepted()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(SignalR));

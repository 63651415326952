import { useCallback } from "react";

const useZendeskStyles = () => {
  const adjustIframePadding = useCallback(() => {
    const iframeZendesk = document.getElementsByTagName("iframe")[1];
    const iframeDocument = iframeZendesk?.contentDocument || iframeZendesk?.contentWindow?.document;
    const inputElement = iframeDocument?.querySelector(".sc-6fatmo-0");

    if (!iframeZendesk || !inputElement) {
      setTimeout(adjustIframePadding, 100);
    } else {
      const statusBarHeight = localStorage.getItem("statusBarHeight") || 30;
      iframeZendesk.style.marginTop = `${statusBarHeight}px`;
      inputElement.style.marginBottom = `${statusBarHeight}px`;
    }
  }, []);

  return { adjustIframePadding };
};

export default useZendeskStyles;

import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { maxAttempts, maxLimitPaymentCards, pollingInterval } from "src/config-in-git";
import useDataCards from "src/hooks/useDataCards";
import Loader from "src/pages/Loader";
import ModalAlertSC from "src/pages/ModalAlertSC";
import ModalConfirm from "src/pages/Modals/ModalConfirm";
import { Button } from "src/shared/ui/Button/button";
import { ReactComponent as ArrowCash } from "../../images/arrow-cash.svg";
import { ReactComponent as ArrowPut } from "../../images/arrow-put.svg";
import { ReactComponent as SortIcon } from "../../images/sort.svg";
import { ReactComponent as TranslateArrow } from "../../images/translate-arrow.svg";
import CreditCard from "../CreditCard";
import BalanceBlock from "./components/BalanceBlock";
import CardItem from "./components/CardItem";
import styles from "./styles.module.scss";

const MoneyManagement = (props) => {
  const {
    setNewCard,
    profinfo,
    profLoading,
    userCardsList,
    getUserCards,
    addCardData,
    deleteCard,
    isLoading,
    showMAlertSC,
    showModalAlertSC,
    cardFormData,
    getUserCardById,
    getDriverInfo,
    ClearM,
    errorStatus,
    clearError,
    errorMsg,
  } = props;

  const { cards } = useDataCards(userCardsList);

  const [openModal, setOpenModal] = useState(false);
  const [mainAlertText, setMainAlertText] = useState("");

  const pollingTimeoutRef = useRef(null);
  const attemptCountRef = useRef(0);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userPhone = localStorage.getItem("userPhone");
  const isLimitedCards = userCardsList.totalCards >= maxLimitPaymentCards;
  const isDataLoading = isLoading || profLoading || !cardFormData.gatewayType || searchParams.get("status");

  const pullCardStatus = useCallback(async () => {
    const cardId = localStorage.getItem("cardId");
    const shouldContinuePolling = attemptCountRef.current < maxAttempts;

    const status = await getUserCardById(cardId);

    if (status === "Pending" && shouldContinuePolling) {
      pollingTimeoutRef.current = setTimeout(async () => {
        attemptCountRef.current += 1;
        await pullCardStatus();
      }, pollingInterval);
    } else {
      clearTimeout(pollingTimeoutRef.current);
      attemptCountRef.current = 0;
      localStorage.removeItem("cardId");
      navigate("/money", { replace: true });
    }
  }, [getUserCardById, navigate]);

  //Requesting user cards
  useEffect(() => {
    const fetchUserCards = async () => {
      const phone = localStorage.getItem("userPhone");
      const currentCountry = localStorage.getItem("country_code");

      const userInfo = {
        country: currentCountry,
        phone,
      };

      await Promise.all([getUserCards(), addCardData(userInfo), getDriverInfo(true)]);

      ClearM();
    };

    fetchUserCards();
  }, [ClearM, addCardData, getDriverInfo, getUserCards]);

  // Make request every 500ms if the user has added a card
  useEffect(() => {
    if (searchParams.get("status")) {
      pullCardStatus();
    } else {
      localStorage.removeItem("cardId");
    }

    return () => {
      clearTimeout(pollingTimeoutRef.current);
    };
  }, [pullCardStatus, searchParams]);

  const closeCardDeleteModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const onConfirmDeleteCard = useCallback(async () => {
    const selectedCardId = localStorage.getItem("selectedCardId");

    await deleteCard(selectedCardId);
    closeCardDeleteModal();
  }, [closeCardDeleteModal, deleteCard]);

  const showModalOperation = useCallback(() => {
    setMainAlertText(t("operations"));
    showModalAlertSC(!showMAlertSC);
  }, [showMAlertSC, showModalAlertSC]);

  const closeModalOperation = useCallback(() => {
    showModalAlertSC(!showMAlertSC);
  }, [showMAlertSC, showModalAlertSC]);

  const alertActions = [
    {
      onClick: closeModalOperation,
      linkState: { operationLabel: t("withdraw_to_card") },
      linkPathname: "/financial-operations?operation=withdraw",
      text: t("withdraw_balance_to_card"),
      icon: <ArrowPut />,
      iconClassName: styles.icon,
    },
    {
      onClick: closeModalOperation,
      linkState: { operationLabel: t("replenish_balance") },
      linkPathname: "/financial-operations?operation=replenish",
      text: t("replenish_balance"),
      icon: <ArrowCash />,
      iconClassName: styles.icon,
    },
    {
      onClick: closeModalOperation,
      linkState: { operationLabel: t("transfer_bonuses") },
      linkPathname: "/financial-operations?operation=bonuses",
      text: t("transfer_bonuses"),
      icon: <TranslateArrow />,
      iconClassName: styles.iconWithoutStroke,
    },
    {
      onClick: closeModalOperation,
      linkState: "",
      linkPathname: "/gtransactions",
      text: t("withdrawal_history"),
      icon: <SortIcon />,
      iconClassName: styles.iconWithoutStroke,
    },
  ];

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <>
      {openModal && (
        <ModalConfirm
          confirmHandler={onConfirmDeleteCard}
          mainConfirmText={t("want_to_delete_this_debit_card")}
          onDefaultCancel={closeCardDeleteModal}
        />
      )}

      {showMAlertSC && (
        <ModalAlertSC
          actions={alertActions}
          mainAlertText={mainAlertText}
          onCancel={() => {
            showModalAlertSC(false);
          }}
        />
      )}

      <BalanceBlock profinfo={profinfo} />

      <div className={styles.cards}>
        <h2>{t("card_v2")}</h2>
        {cards &&
          cards.map((card) => (
            <CardItem
              card={card}
              deleteCard={onConfirmDeleteCard}
              key={card.id}
              setNewCard={setNewCard}
              setOpenModal={setOpenModal}
            />
          ))}
        <CreditCard
          cardFormData={cardFormData}
          className={styles.addCardForm}
          isLimitedCards={isLimitedCards}
          userPhone={userPhone}
        />
      </div>
      <div className={styles.operationsBtnBlock}>
        <Button className={styles.operationsBtn} onClick={showModalOperation}>
          {t("operations")}
        </Button>
      </div>
    </>
  );
};

export default memo(MoneyManagement);

import React, { Component, Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as Bolt } from "../../images/Lightning.svg";
import { ReactComponent as BackArrow } from "../../images/back.svg";
import { ReactComponent as CommentDots } from "../../images/message.svg";
import { ReactComponent as CreditCard } from "../../images/credit-card.svg";
import { ReactComponent as Car } from "../../images/car.svg";
import { ReactComponent as MapPin } from "../../images/MapPin.svg";
import { ReactComponent as MapPinCircle } from "../../images/MapPinCircle.svg";
import { ReactComponent as Road } from "../../images/Path.svg";
import { ReactComponent as Copy } from "../../images/CopyClipboard.svg";
import { ReactComponent as Coins } from "../../images/Coins.svg";
import { ReactComponent as Clock } from "../../images/clock.svg";
import { ReactComponent as Bonus } from "../../images/Bonus.svg";
import { ReactComponent as Star } from "../../images/star.svg";
import { ReactComponent as DownloadCloud } from "src/images/download-cloud.svg";

import noImg from "../../media/noimg.jpg";
import { getHistoryItem, clearError } from "../History/store/history.actions";
import { setClientRating } from "../Finishedorder/finishedorder.actions";

import { CopyToClipboard } from "react-copy-to-clipboard";

import "./historyorder.scss";
import { t } from "i18next";
import { getValuteSymbol } from "../../services/getValuteSymbol";
import { formatDateFunc } from "src/services/formatDateFunc";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import CustomMap from "src/components/CustomMap";
import { updateRoute } from "src/components/CustomMap/helpers/updateRoute";
import { getPolylineMultiPoints } from "src/shared/helpers/getPolylineMultiPoints";

const HistoryOrder = ({
  orderId,
  getHistoryItem,
  order,
  orderStatus,
  errorStatus,
  profinfo,
  errorMsg,
  clearError,
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [polylinePositions, setPolylinePositions] = useState(null);

  let pointsList = "";
  if (orderStatus && order.destinationPoints) {
    pointsList = order.destinationPoints.map((point, j, arr) => {
      const isLast = order.destinationPoints.length - 1 !== j;
      return (
        <li className="address" key={j}>
          <div className="icon-item">
            {j > 0 && j < arr.length - 1 ? (
              <MapPinCircle
                style={{
                  width: "12px",
                  height: "12px",
                  marginLeft: "2px",
                  marginRight: "5.4px",
                }}
              />
            ) : (
              <MapPin style={{ width: "20px", height: "20px" }} />
            )}
          </div>
          <div className={"address-text" + " " + (isLast === false ? "address-without-border" : "")}>
            {point.name}
          </div>
        </li>
      );
    });
  }

  let prefsArr = "";
  if (orderStatus && order.orderPreferences) {
    prefsArr = order.orderPreferences.map((pref) => {
      return (
        <p className="pref-item" key={pref.name}>
          {t(pref.name)}
        </p>
      );
    });
  }

  let time = [];
  if (orderStatus && order.orderExecutionTime) {
    time = order.orderExecutionTime.split(":");
  }

  const distance = order.distance / 1000;

  useEffect(() => {
    getHistoryItem(orderId);
  }, [getHistoryItem, orderId]);

  // get polyline for map
  useEffect(() => {
    const getPolyline = async () => {
      if (order.destinationPoints) {
        const mapPoints = order.destinationPoints.map((item) => ({ lon: item.lon, lat: item.lat }));
        const polyline = await getPolylineMultiPoints(mapPoints);
        setPolylinePositions(polyline);
      }
    };

    getPolyline();
  }, [order.destinationPoints]);


  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row m-0">
          <div className="historyorder-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <NavLink to="/history">
              <BackArrow className="ArrowLeft" />
            </NavLink>
            <div className="top-nav-text">
              <p>
                {t("order")} <br /> #{order.id}
                <CopyToClipboard style={{ marginLeft: "8px" }} text={order.id}>
                  <button className="copy-to-clipboard">
                    <Copy style={{ width: "14px", height: "14px" }} />
                  </button>
                </CopyToClipboard>
              </p>
            </div>
            <div className="order-form">
              <p className="order-date">{formatDateFunc(order.orderDate, profinfo?.language || "es")}</p>
              <div className="map-adress">
                {polylinePositions && (
                  <CustomMap
                    mapPoints={order.destinationPoints}
                    coordsDriver={polylinePositions[Math.round(polylinePositions.length / 2)]}
                    disabledFunctions={{
                      disableSeparateRoute: true,
                      disableButtons: true,
                      disableGlueCar: true,
                      disabledSpeed: true,
                      disabledNavigation: true,
                      disabledCarMarker: true,
                      disableCircle: true,
                    }}
                    mapLoaded={mapLoaded}
                    polylinePositions={polylinePositions}
                    setMapLoaded={setMapLoaded}
                    zoomFullRoute={true}
                    customMapZoom={null}
                    fitBoundsParams={{ speed: 10 }}
                  />
                )}
              </div>
              <div className="trip-info">
                <ul className="address-list">{orderStatus ? pointsList : null}</ul>
              </div>
              <div className="trip-item">
                <div className="icon-container">
                  <Road className="icon-item-svg" />
                  <p className="label-text"> {t("distance")}</p>
                </div>
                <p className="label-info">
                  {distance.toFixed(1)}
                  <span className="units-text">{t("km")}</span>
                </p>
              </div>
              <div className="trip-item">
                <div className="icon-container">
                  <Coins className="icon-item-svg" />
                  <p className="label-text">{t("cost")}</p>
                </div>
                <p className="label-info" style={{ marginTop: "1px" }}>
                  {order.quotePaid}
                  {getValuteSymbol(order.currency)}
                </p>
              </div>
              <div className="trip-item">
                <div className="icon-container">
                  <CreditCard className="icon-item-svg" />
                  <p className="label-text">{t("payment")}</p>
                </div>
                {order?.paymentType === 0 ? <p className="label-info">{t("cash")}</p> : null}
                {order?.paymentType === 1 ? <p className="label-info">{t("card")}</p> : null}
                {order?.paymentType === 2 ? <p className="label-info">{t("cash_and_bonuses")}</p> : null}
                {order?.paymentType === 4 ? <p className="label-info">{t("card_and_bonuses")}</p> : null}
              </div>
              <div className="trip-item">
                <div className="icon-container">
                  <Bonus className="icon-item-svg" />
                  <p className="label-text">{t("bonuses")}</p>
                </div>
                <p className="label-info" style={{ marginTop: "0.5px" }}>
                  {order.quoteBonus}
                </p>
              </div>
              <div className="trip-item">
                <div className="icon-container">
                  <Car className="icon-item-svg" />
                  <p className="label-text">{t("class_of_order_v2")}</p>
                </div>
                <p className="label-info">{t(order.carClass)}</p>
              </div>
              {orderStatus && order?.orderPreferences?.length !== 0 ? (
                <div className="trip-item">
                  <div className="icon-container">
                    <Bolt className="icon-item-svg-yellow" style={{ color: "#FFDE17" }} />
                    <p className="label-text">{t("optional_extras")}</p>
                  </div>
                  <p className="label-info">{prefsArr}</p>
                </div>
              ) : null}
              <div className="trip-item">
                <div className="icon-container">
                  <Clock className="icon-item-svg" />
                  <p className="label-text">{t("turnaround_time")}</p>
                </div>
                <p className="label-info">
                  {order.status === "Paid" ? time[0] + t("minutes") : t("order_incomplete")}
                </p>
              </div>
              {/* compensation block */}
              <div className="trip-item">
                <div className="icon-container">
                  <DownloadCloud className="DownloadCloudIcon" />
                  <p className="label-text">{t("compensation")}</p>
                </div>
                <p className="label-info">
                  {order?.compensation}
                  {getValuteSymbol(order.currency)}
                </p>
              </div>
              <div className="trip-item-client">
                <img alt="" className="profile-img" src={order.clientPhoto ? order.clientPhoto : noImg} />
                <p className="label-text" style={{ marginLeft: "9px", marginTop: "1px" }}>
                  {order.clientName}
                </p>
              </div>
              {order.orderComment ? (
                <div className="trip-item-client">
                  <div className="icon-container">
                    <CommentDots className="icon-item-svg" />
                    <p className="label-text">{order.orderComment}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.history.orderId,
    order: state.history.doneOrder,
    orderStatus: state.history.orderStatus,
    errorStatus: state.history.errorStatus,
    errorMsg: state.history.errorMsg,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHistoryItem: (orderId) => dispatch(getHistoryItem(orderId)),
    setClientRating: (clientRatingData) => dispatch(setClientRating(clientRatingData)),
    clearError: () => dispatch(clearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryOrder);

export const metersToKm = (meters, options) => {
	let km = meters / 1000;

	if (options) {
		const { calculateLowerNum, calculateHigherNum } = options;

		if (calculateLowerNum && km < calculateLowerNum) {
			km = km.toFixed(1);
		}

		if (calculateHigherNum && km >= calculateLowerNum) {
			km = Math.round(km);
		}
	}

	return km;
};

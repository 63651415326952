import React from "react";
import { t } from "i18next";

import { getValuteSymbol } from "src/services/getValuteSymbol";

import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as Cashback } from "src/images/cashback.svg";

import styles from "./styles.module.scss";

const FinalOrderMenu = (props) => {
  const { order } = props;
  
  const orderPayDetails = [
    {
      name: t("total_amount"),
      value: `${order?.priceEstimated} ${getValuteSymbol(order.currency)}`,
    },
    {
      name: t("pending_quote"),
      value: `${order?.pendingsQuote} ${getValuteSymbol(order.currency)}`,
    },
  ];
  return (
    <div className={styles.finalOrderMenuWrap}>
      <h2>{t("pay_for_trip")}</h2>
      <div className={styles.finalPrice}>
        {order?.totalQuote}
        {getValuteSymbol(order.currency)}
      </div>
      <div className={styles.finalOrderPriceBonuses}>
        <div className={styles.finalOrderPriceBonusesItem}>
          <CreditCard className="CreditCardIcon" />
          <div className={styles.finalOrderPriceBonusesInner}>
            <span>
              {order?.pricePaid}
              {getValuteSymbol(order.currency)}
            </span>
          </div>
        </div>
        /
        <div className={styles.finalOrderPriceBonusesItem}>
          <Cashback className="CreditCardIcon" />
          <div className={styles.finalOrderPriceBonusesInner}>
            <span>
              {order?.bonusPaid + order?.compensation}
              {getValuteSymbol(order.currency)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <ul className={styles.finalOrderBonusesDetails}>
        {orderPayDetails.map((item, index) =>
          item.name ? (
            <li key={`${item.name}_${index}`}>
              <span>{item.name}</span>
              <span>+{item.value}</span>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

export default FinalOrderMenu;

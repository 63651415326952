import { updateRoute } from "src/components/CustomMap/helpers/updateRoute";

export const getPolylineMultiPoints = async (points, errorHandler = () => null) => {
  const positions = await Promise.all(
    points.flatMap(async (point, index) => {
      const prevItem = points[index - 1];

      if (!prevItem) {
        return [[point.lat, point.lon]];
      }

      try {
        const { formattedCoordinates } = await updateRoute({
          startPos: [prevItem.lon, prevItem.lat],
          endPos: [point.lon, point.lat],
        });

        return formattedCoordinates;
      } catch {
        errorHandler();
        return [];
      }
    }),
  );

  // connect all new polyline points arrays to one
  const positionsToOneArr = positions.reduce((accumulator, currentArray) => {
    return accumulator.concat(currentArray);
  }, []);

  return positionsToOneArr;
};

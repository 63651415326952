import { useNavigate, useLocation } from "react-router-dom";
import { defaultParamKey } from "src/shared/constants/urlParams";

const useSetUrlParam = ({ paramValue, paramKey = defaultParamKey }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const setUrlParam = () => {
    const currentUrl = location.pathname; // Get the current pathname
    // Navigate to a new URL with the specified query parameter
    navigate(`${currentUrl}?${paramKey}=${paramValue}`);
  };

  return setUrlParam;
};

export default useSetUrlParam;

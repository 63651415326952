import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { classNames } from "../../shared/lib/classNames/classNames";

import { ReactComponent as LogoTaxi } from "../../images/logo-new.svg";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { connect } from "react-redux";
import { getAvtos } from "../Cars/cars.actions";
import {
	clearReg,
	clearRpcError,
	getDriverInfo,
	sendCoordsToRpc,
} from "../RegistrationPage/registration.actions";
import { getAllInspections } from "../CarDocuments/cardocs.actions";
import "./authpage.scss";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import ModalAlert from "../ModalAlert";
import Loader from "../Loader";
import withNavigation from "src/hoc/NavigateForClass";
import { appStoreLink, playMarketLink } from "src/shared/constants";
import { onContentLoaded } from "src/shared/funcForMobile";

class AuthPage extends Component {
	state = {
		isCarsEmpty: true,
		mainAlertText: "",
	};

	componentDidUpdate(prevProps, prevState) {
		const { cars, driver, rpcError, getCoordsError } = this.props;

		if (prevProps.cars.length !== cars.length && cars.length > 0) {
			this.props.getAllInspections(this.props.cars[0].id);
		}

		if (prevProps.driver !== driver) {
			this.checkStepRegister();
		}

		if (prevProps.rpcError !== rpcError && rpcError !== false) {
			this.setState({
				mainAlertText: this.props.rpcError,
			});
			let status = !this.props.showMAlert;
			this.props.showModalAlert(status);
		}

		if (
			prevProps.getCoordsError !== getCoordsError &&
			getCoordsError !== false
		) {
			this.setState({
				mainAlertText: t("provide_access_geolocation_to_continue_registration"),
			});
			let status = !this.props.showMAlert;
			this.props.showModalAlert(status);
		}
	}

	checkStepRegister = () => {
		const registrationStep = JSON.parse(
			localStorage.getItem("registrationStep"),
		);
		const accessToken = localStorage.getItem("accessToken");

		if (!accessToken) {
			return "/login";
		}

		if (registrationStep === 1) {
			return "/registration/uploadphoto";
		}

		if (registrationStep === 2) {
			return "/cardocuments";
		}

		return "/orders";
	};

	handleRegistration = async () => {
		await this.props.sendCoordsToRpc();

		if (this.props.rpcCountryInfo?.country_code) {
			return this.props.navigate("/registration");
		}
	};

	getAppLink = () => {
		const appType = localStorage.getItem("appType");
		switch (appType) {
			case "android":
				return playMarketLink;
			case "ios":
				return appStoreLink;
			default:
				return "/";
		}
	};

	render() {
		const { showMAlert, isFirstRegistration, rpcLoaded } = this.props;

		const registrationStep = JSON.parse(
			localStorage.getItem("registrationStep"),
		);

		if (rpcLoaded) {
			return <Loader />;
		}

		if (
			localStorage.getItem("accessToken") &&
			registrationStep >= 2 &&
			!isFirstRegistration
		) {
			return <Navigate to="/orders" />;
		}

		// disabled loader for native app
		onContentLoaded();

		if (registrationStep === 1 && !isFirstRegistration) {
			return <Navigate to="/registration/uploadphoto" />;
		}

		return (
			<>
				{showMAlert && (
					<ModalAlert
						mainAlertText={this.state.mainAlertText}
						confirmHandler={() => {
							this.props.clearRpcError();
						}}
					/>
				)}
				<div className="auth-section">
					<div className="block_auth-logo">
						<div className="logo_block">
							<LogoTaxi className="logo_icon" />
						</div>
					</div>
					<div className="block_auth-btn">
						<Button className={classNames("auth-btn auth-btn--login", {}, [])}>
							<Link
								to={this.checkStepRegister()}
								className="auth-link auth-btn-login--link"
							>
								{t("enter")}
							</Link>
						</Button>
						<Button
							className={classNames("auth-btn auth-btn--register", {}, [])}
						>
							<p
								onClick={this.handleRegistration}
								className="auth-link auth-btn-register--link"
							>
								{t("registration")}
							</p>
						</Button>
					</div>
					{/* hide button */}
					{/* <div className="block_auth--title">
						<div>
							<p>
								{t("or_order")}{" "}
								<a href={this.getAppLink()} className="title_company">
									CARMA TAXI
								</a>
							</p>
						</div>
					</div> */}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		showMAlert: state.modalAlert.showMAlert,
		updPhotoStatus: state.registration.updPhotoStatus,
		updLicenseStatus: state.registration.updLicenseStatus,
		driver: state.registration.profinfo,
		loginStatus: state.login.loginStatus,
		cars: state.cars.avtos,
		inspectionInfo: state.carInspections.inspectionInfo,
		isFirstRegistration: state.registration.isFirstRegistration,

		// RPC
		rpcLoaded: state.registration.rpcLoaded,
		rpcCountryInfo: state.registration.rpcCountryInfo,
		rpcError: state.registration.rpcError,
		getCoordsError: state.registration.getCoordsError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDriverInfo: () => dispatch(getDriverInfo()),
		clearReg: () => dispatch(clearReg()),
		getAllInspections: (carId) => dispatch(getAllInspections(carId)),
		getAvtos: () => dispatch(getAvtos()),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		sendCoordsToRpc: () => dispatch(sendCoordsToRpc()),
		clearRpcError: () => dispatch(clearRpcError()),
	};
};

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(withNavigation(AuthPage)),
);

import React, { Component, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendPhone, clearForgot, clearError } from "./forgot.actions";
import { ReactComponent as BackIcon } from "../../images/back.svg";

import { classNames } from "../../shared/lib/classNames/classNames";

import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";


import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { Input } from "../../shared/ui/Input/inputs";

import "./forgot.scss";
import { t } from "i18next";
import { getRedirectPage } from "../../services/getRedirectPage";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";

const chkPhoneNumber = new RegExp(/^\+380[0-9]{0,9}$/);

class Forgot extends Component {
	state = {
		phone: "",
		errorN: false,
		wrongPhone: "",
		mainAlertText: "",
		chgCss: "",
	};

	componentDidMount() {
		const phoneNumber = localStorage.getItem("userPhone");
		if (phoneNumber) {
			this.setState({
				phone: phoneNumber,
			});
		}
	}

	ChgPhone = (event) => {
		if (chkPhoneNumber.test(event.target.value)) {
			this.setState({
				[event.target.name]: event.target.value,
			});
		}
	};

	trySend = () => {
		if (this.state.phone.length !== 13) {
			this.setState({
				errorN: true,
				wrongPhone: t("number_is_not_entered_correctly"),
			});
			return false;
		} else {
			this.setState({
				errorN: false,
				wrongPhone: "",
			});
		}

		const userPhone = {
			phone: this.state.phone,
		};
		this.props.sendPhone(userPhone);
	};

	tryClearError = () => {
		this.props.clearError();
	};

	validateRegInfo = () => {
		// const { newPass, confirmPass } = this.state
		// if (!newPass || !confirmPass) {
		//   return true
		// }

		return false;
	};

	clearInputValue = (name) => {
		this.setState({
			[name]: "",
		});
	};

	confirmHandlerModal = () => {
		if (this.state.mainAlertText === t("message_with_password_was_sent")) {
			return this.props.navigate("/login/sms-verefication");
		}

		return;
	};

	render() {
		const { resetPass, notRegistered, showMAlert, errorStatus } = this.props;
		if (resetPass) {
			this.setState({
				mainAlertText: t("message_with_password_was_sent"),
			});
			let status = !this.props.showMAlert;
			this.props.showModalAlert(status);
			this.props.clearForgot();
		}

		if (notRegistered) {
			this.setState({
				mainAlertText: t("number_is_not_registered"),
			});
			let status = !this.props.showMAlert;
			this.props.showModalAlert(status);
			this.props.clearForgot();
		}

		return (
			<>
				{showMAlert ? (
					<ModalAlert
						mainAlertText={this.state.mainAlertText}
						confirmHandler={this.confirmHandlerModal}
					/>
				) : null}

				<div className="forgot-section">
					<MobileTopBarHeight />
					<div className="container">
						<div className="navigation-block">
							<Link to={getRedirectPage("/login/sms-verefication")}>
								<BackIcon className="icon-nav" />
								<h1>{t("change_password")}</h1>
							</Link>
						</div>
						<div className="forgot-form">
							<p className="forgot-text">
								{t("enter_number_that_used_sign_up")}
							</p>
							<div className="forgot-block">
								<div className="phone-line">
									<Input
										type="text"
										className={classNames(
											"input_field",
											{ error_field: this.state.wrongPhone },
											[],
										)}
										name="phone"
										value={
											this.state.phone.length === 0 ? "+380" : this.state.phone
										}
										handleClearValue={() => this.clearInputValue("phone")}
										onChange={this.ChgPhone}
									/>
								</div>
								{this.state.errorN ? (
									<span id="error">{this.state.wrongPhone}</span>
								) : (
									<p className="input-text">{t("Enter_number_without_+380")}</p>
								)}
							</div>
							<div className="reg-button">
								<Button
									className={classNames(
										"reg-btn",
										{ btn_disabled: this.validateRegInfo() },
										[],
									)}
									onClick={this.trySend}
								>
									{t("continue")}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		resetPass: state.forgot.resetPass,
		showMAlert: state.modalAlert.showMAlert,
		notRegistered: state.forgot.notRegistered,
		errorStatus: state.forgot.errorStatus,
		errorMsg: state.forgot.errorMsg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendPhone: (userPhone) => dispatch(sendPhone(userPhone)),
		showModalAlert: (status) => dispatch(showModalAlert(status)),
		clearForgot: () => dispatch(clearForgot()),
		clearError: () => dispatch(clearError()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withNavigation(Forgot));

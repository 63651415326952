import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "src/components/PrivateRoute";
import ProtectedOrderPage from "src/components/ProtectedOrderPage";

import AddCar from "src/pages/AddCar";
import ApkPolicy from "src/pages/ApkPolicy";
import ApkRules from "src/pages/ApkRules";
import AuthPage from "src/pages/AuthPage";
import CarDocuments from "src/pages/CarDocuments";
import Cars from "src/pages/Cars";
import CheckCode from "src/pages/CheckCode";
import ChooseLanguages from "src/pages/ChooseLanguages";
import ContactUs from "src/pages/ContactUs";
import Dashboard from "src/pages/Dashboard";

import DeleteAccount from "src/pages/DeleteAccount";
import EditCar from "src/pages/EditCar";
import EditPassword from "src/pages/EditPassword";
import EditProfile from "src/pages/EditProfile";
import FinancialOperationPage from "src/pages/FinancialOperationPage";
import Fine from "src/pages/Fine";
import FinishRegistrationPage from "src/pages/FinishRegistrationPage";
import Forgot from "src/pages/Forgot";
import GTransactions from "src/pages/GTransactions";
import GuideCarPhoto from "src/pages/GuideCarPhoto";
import History from "src/pages/History";
import HistoryOrder from "src/pages/HistoryOrder";
import Invoices from "src/pages/Invoices";
import Loader from "src/pages/Loader";
import LoginPage from "src/pages/LoginPage";
import MainNews from "src/pages/MainNews";
import MoneyManagementPage from "src/pages/MoneyManagementPage";
import MyReting from "src/pages/MyReting";
import NewsArticle from "src/pages/NewsArticle";
import Notifications from "src/pages/Notifications";
import Order from "src/pages/Order";
import Orders from "src/pages/Orders";
import RegistrationPage from "src/pages/RegistrationPage";
import SmsVerificationPage from "src/pages/SmsVerificationPage";
import STransactions from "src/pages/STransactions";
import UploadPhotoDriver from "src/pages/UploadPhotoDriver";
import UserSupportInfo from "src/pages/UserSupportInfo";

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<AuthPage />} path="/" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SmsVerificationPage />} path="/login/sms-verefication" />
        <Route element={<RegistrationPage />} path="/registration" />
        <Route element={<CheckCode />} path="/checkcode" />
        <Route element={<UploadPhotoDriver />} path="/registration/uploadphoto" />
        <Route element={<FinishRegistrationPage />} path="/finishreg" />
        {/* Step registration or authorization */}
        <Route element={<AddCar />} path="/addcar" />
        <Route element={<CarDocuments />} path="/cardocuments" />
        <Route element={<GuideCarPhoto />} path="/guide" />
        <Route element={<Forgot />} path="/forgot" />
        <Route element={<ApkRules />} path="/rules" />
        <Route element={<ApkPolicy />} path="/policy" />

        <Route element={<ProtectedOrderPage Component={Order} />} path="/order" />
        <Route element={<PrivateRoute Component={DeleteAccount} />} path="/delete-account" />
        <Route element={<PrivateRoute Component={EditPassword} />} path="/editpassword" />
        <Route element={<PrivateRoute Component={ChooseLanguages} />} path="/choose-language" />
        <Route element={<PrivateRoute Component={Orders} />} path="/orders" />
        <Route element={<PrivateRoute Component={EditProfile} />} path="/editprofile" />
        <Route element={<PrivateRoute Component={EditCar} />} path="/editcar" />
        <Route element={<PrivateRoute Component={Cars} />} path="/cars" />
        <Route element={<PrivateRoute Component={History} />} path="/history" />

        <Route element={<PrivateRoute Component={MyReting} />} path="/myreting" />
        <Route element={<PrivateRoute Component={NewsArticle} />} path="/newsarticle" />
        <Route element={<PrivateRoute Component={Notifications} />} path="/notifications" />
        <Route element={<PrivateRoute Component={ContactUs} />} path="/contactus" />
        <Route element={<PrivateRoute Component={HistoryOrder} />} path="/historyorder" />
        <Route element={<PrivateRoute Component={MoneyManagementPage} />} path="/money" />
        <Route element={<PrivateRoute Component={MainNews} />} path="/mainnews" />
        <Route element={<PrivateRoute Component={FinancialOperationPage} />} path="/financial-operations" />
        <Route element={<PrivateRoute Component={GTransactions} />} path="/gtransactions" />
        <Route element={<PrivateRoute Component={STransactions} />} path="/stransactions" />
        <Route element={<PrivateRoute Component={Fine} />} path="/fines" />
        <Route element={<PrivateRoute Component={Dashboard} />} path="/dashboard" />
        <Route element={<PrivateRoute Component={Invoices} />} exact path="/invoices" />
        <Route
                  element={<PrivateRoute Component={UserSupportInfo} />}
                  path="/user-support-info"
                />,
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

import React, { Component, Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { ReactComponent as CheckIcon } from '../../images/check.svg'

import {
  getAllInspections,
  clearAddNewTOStatus,
  sendTOImgs,
  clearError,
} from './cardocs.actions'

import { showModalConfirm } from '../Modals/ModalConfirm/modalConfirm.actions'
import ModalAlert from '../ModalAlert'
import { showModalAlert } from '../ModalAlert/modalAlert.actions'
import Loader from '../Loader'
import { t } from 'i18next'
import { Trans, withTranslation } from 'react-i18next'
import { MAX_IMAGE_SIZE_MB } from '../../config-in-git'

import { ReactComponent as BackIcon } from '../../images/back.svg'
import { ReactComponent as CameraIcon } from '../../images/camera.svg'
import { ReactComponent as PlusIcon } from '../../images/plus.svg'

import guideFront from '../../media/guide-front.png'
import guideBack from '../../media/guide-back.png'
import guideRight from '../../media/guide-right.png'
import guideLeft from '../../media/guide-left.png'
import guideSeatsFront from '../../media/guide-seats-front.png'
import guideSeatsBack from '../../media/guide-seats-back.png'

import { showModalAction } from '../ModalAction/modalAction.actions'
import ModalAction from '../ModalAction'
import { Button } from '../../shared/ui/Buttons/DefautlButton/button'
import { classNames } from '../../shared/lib/classNames/classNames'
import { getAvtos } from '../Cars/cars.actions'

import './cardocuments.scss'
import {
  getDriverInfo,
  updRegistrationStep,
} from '../RegistrationPage/registration.actions'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'
import withNavigation from 'src/hoc/NavigateForClass'
import { compressImage } from 'src/shared/helpers/compressImage'
import { imageDefaultQuality } from 'src/shared/constants'

const guideCarPhoto = [
  { photo: guideFront },
  { photo: guideBack },
  { photo: guideLeft },
  { photo: guideRight },
  { photo: guideSeatsFront },
  { photo: guideSeatsBack },
]
class CarDocuments extends Component {
  state = {
    files: {
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      file5: '',
      file6: '',
      file7: '',
      file8: '',
    },
    filesStep: 0,
    mainConfirmText: '',
    mainAlertText: '',
    actionText1: '',
    showImgBlock: false,
    newTO: false,
    exit: false,
    preloader: false,

    idxGuidePhoto: 0,
    isGuidePhoto: false,
  }

  file1 = React.createRef()
  file2 = React.createRef()
  file3 = React.createRef()
  file4 = React.createRef()
  file5 = React.createRef()
  file6 = React.createRef()
  file7 = React.createRef()
  file8 = React.createRef()

  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken')
    let carId = this.props.carId
    if (accessToken) {
      this.props.getDriverInfo()
      this.props.getAvtos()
      this.props.getAllInspections(carId) //! TDODO
    }
  }

  componentDidUpdate(prevProps) {
    const { driver, lastInspectionStatus } = this.props

    if (prevProps.driver?.registrationStep !== driver?.registrationStep) {
      if (
        prevProps.driver?.registrationStep === 2 &&
        this.state.exit === true
      ) {
        this.props.navigate('/finishreg')
      }
    }

    if (
      prevProps.lastInspectionStatus !== lastInspectionStatus &&
      lastInspectionStatus === true
    ) {
      this.props.updRegistrationStep(1000)
      this.setState({
        exit: true,
      })
      this.props.navigate('/orders')
    }
  }

  tryTO = () => {
    if (
      this.props.carId === localStorage.getItem('selectedCarId') &&
      this.props.atwork
    ) {
      this.setState({
        mainAlertText: t('before_changing'),
      })
      let status = !this.props.showMAlert
      this.props.showModalAlert(status)
    } else {
      let status = !this.props.showMConfirm
      this.props.showModalConfirm(status)
      this.setState({
        mainConfirmText: t('apply_new_TO'),
      })
    }
  }

  newTO = () => {
    this.setState({
      newTO: true,
    })
  }

  photoToBase64 = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.name;
  
    if (file) {
      try {
        this.setState({
          preloader: true,
        })

        const compressedFile = await compressImage(file, imageDefaultQuality.width, imageDefaultQuality.height, imageDefaultQuality.quality);
  
        const reader = new FileReader();
        reader.onloadend = () => {
          let fileExist = false;
          const currentFiles = { ...this.state.files };
          for (let item in currentFiles) {
            if (currentFiles[item].fileArray === reader.result) {
              fileExist = true;
              this.setState({
                mainAlertText: t('already_uploaded_photo'),
              });
              let status = !this.props.showMAlert;
              this.props.showModalAlert(status);
              break;
            }
          }
          if (!fileExist) {
            const type = compressedFile.type.split('/')[0];
            if (compressedFile.size > 15728640 || type !== 'image') {
              fileExist = true;
              this.setState({
                mainAlertText:
                  type !== 'image' ? (
                    t('only_photos_can_uploaded')
                  ) : (
                    <Trans
                      i18nKey="image_exceeded_max_size"
                      values={{ maxSize: MAX_IMAGE_SIZE_MB }}
                    />
                  ),
              });
              let status = !this.props.showMAlert;
              this.props.showModalAlert(status);
            } else {
              let imgInfo = {
                name: fileName,
                fileArray: reader.result,
              };
              this.setState({
                files: { ...this.state.files, [fileName]: imgInfo },
              });
            }
          }
        };
  
        reader.readAsDataURL(compressedFile);
        this.setState({
          preloader: false,
        })
      } catch (error) {
        console.error("Image compression error:", error);
      }
    }
    event.target.value = null;
    console.log(this.state.files)
  };

  showImgs = () => {
    this.setState({
      showImgBlock: !this.state.showImgBlock,
    })
  }

  saveTO = () => {
    let imgArr = []
    for (let key in this.state.files) {
      if (this.state.files[key]) {
        let imgBody = this.state.files[key].fileArray.split(',')
        let imgInfo = {
          data: imgBody[1],
          name: this.state.files[key].name,
        }
        imgArr.push(imgInfo)
      } else {
        this.setState({
          mainAlertText: t('not_all_doc_added'),
        })
        let status = !this.props.showMAlert
        this.props.showModalAlert(status)
        return false
      }
    }

    let info = {
      fileArray: imgArr,
      driverId: this.props.driver.id,
      // carId: this.props.cars[0]?.id,
      carId: !this.props.carId ? this.props.cars[0]?.id : this.props.carId,
    }

    this.setState({
      preloader: true,
    })

    this.props.sendTOImgs(info)
    this.props.updRegistrationStep(1000)
  }

  goBack = () => {
    if (this.props.goBack) {
      this.setState({
        exit: true,
      })
    }
  }

  tryClearError = () => {
    this.setState({
      preloader: false,
    })
    this.props.clearError()
  }

  showModalForUploadPhoto = (filesStep, title) => {
    if (filesStep >= 3) {
      this.setState({
        idxGuidePhoto: filesStep - 3,
        isGuidePhoto: true,
      })
    } else {
      this.setState({
        isGuidePhoto: false,
      })
    }
    this.setState({
      mainAlertText: title || t('upload_photo'),
      actionText1: t('select_from_gallery'),
      filesStep: filesStep,
    })

    let status = !this.props.showMAction
    this.props.showModalAction(status)
  }

  uploadPhotoFunc = () => {
    this[`file${this.state.filesStep}`].current.click()

    let status = !this.props.showMAction
    this.props.showModalAction(status)
  }

  handleRedirectBack = () => {
    return this.props.navigate(-1)
  }

  render() {
    const {
      showMAlert,
      addNewTOStatus,
      showMAction,
    } = this.props

    const registrationStep = JSON.parse(
      localStorage.getItem('registrationStep'),
    )


    if (addNewTOStatus) {
      this.setState({
        preloader: false,
        mainAlertText: t('information_saved'),
      })
      let status = !this.props.showMAlert
      this.props.showModalAlert(status)
      this.props.clearAddNewTOStatus()
    }
    if (this.state.exit && registrationStep >= 1) {
      return <Navigate to="/finishreg" />
    }

    if (this.state.exit && registrationStep === 1000) {
      return <Navigate to="/cars" />
    }

    return (
      <>
        {this.state.preloader ? <Loader /> : null}
        {showMAlert ? (
          <ModalAlert
            mainAlertText={this.state.mainAlertText}
            confirmHandler={this.goBack.bind(this)}
          />
        ) : null}
        {/* {showMAlert ? (
          <ModalAlert
            mainAlertText={this.state.mainAlertText}
            confirmHandler={this.goBack.bind(this)}
          />
        ) : null}
        {showMConfirm ? (
          <ModalConfirm
            mainConfirmText={this.state.mainConfirmText}
            confirmHandler={this.newTO.bind(this)}
          />
        ) : null}
         */}
        {showMAction ? (
          <ModalAction
            mainAlertText={this.state.mainAlertText}
            actionText1={this.state.actionText1}
            isGiude={this.state.isGuidePhoto}
            guidePhoto={guideCarPhoto[this.state.idxGuidePhoto].photo}
            mainAction={this.uploadPhotoFunc}
            iconMainActive={<CameraIcon />}
          />
        ) : null}

        <div className="car-documents_section">
          <div className="container">
            <div className="upload-car_photo">
              <MobileTopBarHeight />
              <div className="back_icon" onClick={this.handleRedirectBack}>
                <BackIcon className="arrowleft_icon" />
              </div>

              <div className="block-photo_phase">
                <div className="info-title_pahse">
                  <h1>{t('car_photo')}</h1>
                </div>
              </div>

              <div className="document-auto_block">
                <h2>{t('photo_tech_passport')}</h2>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() => this.showModalForUploadPhoto(1)}
                >
                  <div className="add_icon-block">
                    {!this.state.files.file1?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>

                  <div>
                    <p className="name-block_type">
                      {t('technical_passport_front_side')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file1 && this.state.files.file1.name}
                    </p>
                  </div>

                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file1)}
                    name="file1"
                    ref={this.file1}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() => this.showModalForUploadPhoto(2)}
                >
                  <div className="add_icon-block">
                    {!this.state.files.file2?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">
                      {t('technical_passport_back_side')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file2 && this.state.files.file2.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file2)}
                    name="file2"
                    ref={this.file2}
                    style={{ display: 'none' }}
                  />
                </Button>
                <div className="border-block_passport" />
              </div>

              <div className="document-auto_block">
                <h2>{t('car_photo')}</h2>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(
                      3,
                      t('front_with_license_plate'),
                    )
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file3?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">
                      {t('front_with_license_plate')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file3 && this.state.files.file3.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file3)}
                    name="file3"
                    ref={this.file3}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(
                      4,
                      t('back_with_license_plate'),
                    )
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file4?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">
                      {t('back_with_license_plate')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file4 && this.state.files.file4.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file4)}
                    name="file4"
                    ref={this.file4}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(5, t('photo_right_side_car'))
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file5?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">
                      {t('photo_right_side_car')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file5 && this.state.files.file5.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file5)}
                    name="file5"
                    ref={this.file5}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(6, t('photo_left_side_car'))
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file6?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">
                      {t('photo_left_side_car')}
                    </p>
                    <p className="named_img">
                      {this.state.files.file6 && this.state.files.file6.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file6)}
                    name="file6"
                    ref={this.file6}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(7, t('front_row_seats_v2'))
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file7?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">{t('front_row_seats_v2')}</p>
                    <p className="named_img">
                      {this.state.files.file7 && this.state.files.file7.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file7)}
                    name="file7"
                    ref={this.file7}
                    style={{ display: 'none' }}
                  />
                </Button>
                <Button
                  className={classNames('add_document-btn', {}, [])}
                  onClick={() =>
                    this.showModalForUploadPhoto(8, t('back_row_seats_v2'))
                  }
                >
                  <div className="add_icon-block">
                    {!this.state.files.file8?.name ? (
                      <PlusIcon className="add_icon" />
                    ) : (
                      <CheckIcon className="add_icon" />
                    )}
                  </div>
                  <div>
                    <p className="name-block_type">{t('back_row_seats_v2')}</p>
                    <p className="named_img">
                      {this.state.files.file8 && this.state.files.file8.name}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={(evt) => this.photoToBase64(evt, this.file8)}
                    name="file8"
                    ref={this.file8}
                    style={{ display: 'none' }}
                  />
                </Button>
              </div>
              <div className="next-block_btn">
                <Button
                  className={classNames(
                    'next-btn',
                    { btn_disabled: false },
                    [],
                  )}
                  onClick={() => this.saveTO()}
                >
                  {t('confirm')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    driver: state.registration.driver,
    carId: state.cars.carId,
    lastInspectionStatus: state.carInspections.lastInspectionStatus,
    inspectionInfo: state.carInspections.inspectionInfo,
    showMConfirm: state.modalConfirm.showMConfirm,
    addNewTOStatus: state.carInspections.addNewTOStatus,
    showMAlert: state.modalAlert.showMAlert,
    goBack: state.carInspections.goBack,
    errorStatus: state.carInspections.errorStatus,
    errorMsg: state.carInspections.errorMsg,
    btnActive: state.carInspections.btnActive,
    showMAction: state.modalAction.showMAction,
    cars: state.cars.avtos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInspections: (carId) => dispatch(getAllInspections(carId)),
    showModalAction: (status) => dispatch(showModalAction(status)),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    clearAddNewTOStatus: () => dispatch(clearAddNewTOStatus()),
    sendTOImgs: (info) => dispatch(sendTOImgs(info)),
    clearError: () => dispatch(clearError()),
    getDriverInfo: () => dispatch(getDriverInfo()),
    getAvtos: () => dispatch(getAvtos()),
    updRegistrationStep: (registrationStep) =>
      dispatch(updRegistrationStep(registrationStep)),
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withNavigation(CarDocuments)),
)


import React, { memo } from "react";
import { t } from "i18next";
import { ReactComponent as WalletIcon } from "src/images/wallet.svg";
import { ReactComponent as CrownIcon } from "src/images/crown-fill.svg";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import styles from "./styles.module.scss";

const BalanceBlock = (props) => {
	const { profinfo } = props;

	const driverBalance = profinfo.balance
		? parseFloat(profinfo.balance).toFixed(2)
		: "0";

	const driverBonuses = profinfo.bonuses ? profinfo.bonuses : "0";

	return (
		<div className={styles.balanceBonusesBlock}>
			<div className={styles.item}>
				<div className={styles.iconLabel}>
					<WalletIcon style={{ marginRight: "10px" }} />
					{t("balance_driver")}
				</div>
				<div className={styles.value}>
					{driverBalance}
					<span className={styles.currency}>{getValuteSymbol(profinfo.currency)}</span>
				</div>
			</div>
			<div className={styles.itemBonus}>
				<div className={styles.iconLabel}>
					<CrownIcon className={styles.CrownIcon} />
					{t("bonuses")}
				</div>
				<div className={styles.value}>{driverBonuses}</div>
			</div>
		</div>
	);
};

export default memo(BalanceBlock);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as BackIcon } from '../../images/back.svg';

import { t } from 'i18next';

import './deleteAccount.scss';
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight';
import withNavigation from 'src/hoc/NavigateForClass';

import { removeAccount } from '../Profile/profile.actions';
import ModalAlertDelete from '../ModalAlertDelete';

const DeleteAccount = ({ navigate, removeAccount }) => {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  const handleDeleteAccount = () => {
    removeAccount()
      .then((response) => {
        if (response.status === 204) {
          setSnackBarMessage(t('request_confirmed'));
          setSnackBarOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        console.error('Error deleting account:', error);
      });
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row m-0">
        <div className="delete-section col-sm-12 p-0">
          <MobileTopBarHeight />
          <div className="top-nav">
            <div className="top-nav_back" onClick={goBack}>
              <BackIcon className="ArrowLeft" />
            </div>
            <div className="top-nav_text">
              <p>{t('delete_account')}</p>
            </div>
          </div>
          <div className="main-text-delete">
            <p className="paragraph">{t('delete_account_description')}</p>
          </div>
          <div className="delete-block_btn">
            <div className="delete-btn" onClick={handleDeleteAccount}>
              <p>{t('confirm_delete_account')}</p>
            </div>
          </div>
        </div>
      </div>
      {snackBarOpen ? (
        <ModalAlertDelete text={snackBarMessage} onClose={handleSnackBarClose} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeAccount: () => dispatch(removeAccount()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(DeleteAccount));

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "src/axios.config";
import { svcApi } from "src/config";

import { ErrorTypes } from "src/shared/const/errors";

import { onThemeChanged } from "../shared/funcForMobile";

export const SET_COORDS = "SET_COORDS";

export const sendPointsToMap = () => {
  return (dispatch) => {
    const coords = JSON.parse(localStorage.getItem("coords"));
    // console.log('==== sendPointsToMap', { coords })

    // const coordsData = {
    //   lat: 50.4607,
    //   lng: 30.6201
    // }
    const coordsData = {
      lat: coords.latitude,
      lng: coords.longitude,
    };
    //  console.log("coordsData APP.ACTIONS:", coordsData)
    dispatch({
      type: SET_COORDS,
      payload: coordsData,
    });
  };
};

export const CLEAR_TOKEN = "CLEAR_TOKEN";

export const clearTokenStatus = () => {
  //  console.log('Enter clearTokenStatus in app.actions')
  return (dispatch) => {
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
    dispatch({
      type: CLEAR_TOKEN,
      payload: true,
    });
  };
};

export const ORDER_REQUEST_MANAGE = "ORDER_REQUEST_MANAGE";
export const getOrderRequestManage = () => {
  //  console.log('Enter clearTokenStatus in app.actions')
  const newOrderRequestManage = JSON.parse(localStorage.getItem("newOrderRequestManage"));
  return (dispatch) => {
    dispatch({
      type: ORDER_REQUEST_MANAGE,
      payload: newOrderRequestManage,
    });
  };
};

export const CHANGE_COLOR_THEME = "CHANGE_COLOR_THEME";
export const switchColorTheme = (theme) => {
  localStorage.setItem("theme", theme);
  // for mobile app
  onThemeChanged(theme);
  document.querySelector("body").setAttribute("data-theme", theme ? "light" : "dark");

  return (dispatch) => {
    dispatch({
      type: CHANGE_COLOR_THEME,
    });
  };
};

export const fetchIsServerErrorStatus = createAsyncThunk("app/fetchIsServerErrorStatus", async () => {
  try {
    const { data } = await axiosInstance.get(svcApi);
    return data.status;
  } catch (error) {
    return ErrorTypes.MAINTENANCE_ERROR;
  }
});

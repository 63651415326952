import { Component } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { MAXIMUM_CARS_FOR_DRIVER } from "src/config";
import withNavigation from "src/hoc/NavigateForClass";
import { formatDateFunc } from "src/services/formatDateFunc";
import { getRedirectPage } from "src/services/getRedirectPage";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Buttons/DefautlButton/button";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

import {
  getAvtos,
  setCarId,
  delCar,
  setEditCarId,
  clearError,
  resetSelectedCar,
  updateCar,
  setIsDefault,
} from "./cars.actions";
import { getBrands, getModels, getColors, getClasses, getPreferences } from "../AddCar/addcar.actions";
import { clearInspection, getAllInspections } from "../CarDocuments/cardocs.actions";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import ModalConfirm from "../Modals/ModalConfirm";
import { showModalConfirm } from "../Modals/ModalConfirm/modalConfirm.actions";

import { ReactComponent as AddCarIcon } from "src/images/add-car.svg";
import { ReactComponent as BackIcon } from "src/images/back.svg";
import { ReactComponent as CheckIcon } from "src/images/check.svg";
import { ReactComponent as PlusIcon } from "src/images/plus.svg";

import "./cars.scss";

class Cars extends Component {
  state = {
    carDelInfo: {},
    mainConfirmText: "",
    mainAlertText: "",
    showActiveCar: false,
    showListCar: true,
    currentNewCar: "",
    selectedCarFromList: false,
    isDefaultCarId: 0,
  };

  componentDidMount() {
    this.props.clearInspection();
    this.props.getBrands();
    this.props.getModels();
    this.props.getColors();
    this.props.getAvtos(false);
    this.props.getPreferences();

    if (this.props.cars?.length) {
      const carDefault = this.getDefaulCar();
      if (carDefault?.id) {
        this.setState({
          isDefaultCarId: carDefault.id,
        });
        this.props.getAllInspections(carDefault.id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { cars } = this.props;

    if (prevProps.cars?.length !== cars?.length && cars?.length) {
      const carDefault = this.getDefaulCar();
      if (carDefault?.id) {
        this.setState({
          isDefaultCarId: carDefault.id,
        });
        this.props.getAllInspections(carDefault.id);
      }
    }
  }

  truncateNumberPlate = (numberPlate) => {
    if (numberPlate.length > 8) {
      return `${numberPlate.slice(0, 8)}...`;
    }

    return numberPlate;
  };

  showActive = () => {
    this.setState({
      showActiveCar: true,
      showListCar: false,
      selectedCarFromList: false,
    });
    if (!this.state.showActiveCar) {
      this.props.getAvtos(false);
      if (this.state.isDefaultCarId) {
        this.props.getAllInspections(this.state.isDefaultCarId);
      }
    }
  };

  showList = () => {
    this.setState({
      showActiveCar: false,
      showListCar: true,
      selectedCarFromList: false,
    });
  };

  showConfirm = (id, brand, govNumber) => {
    if (this.props.selectedCar.id && this.props.selectedCar.id === id && this.props.workStatus) {
      this.setState({
        mainAlertText: t("cannot_delete_a_used_auto"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      return false;
    } else {
      let status = !this.props.showMConfirm;
      this.props.showModalConfirm(status);
      this.setState({
        carDelInfo: {
          carIdForDelete: id,
          carBrand: brand,
          carGovNumber: govNumber,
        },
        mainConfirmText: t("confirm_auto_delete"),
      });
    }
  };

  addNewCar = () => {
    const { cars } = this.props;

    if (cars.length >= MAXIMUM_CARS_FOR_DRIVER) {
      this.setState({
        mainAlertText: (
          <Trans i18nKey="can_not_add_more_cars" values={{ maxCars: MAXIMUM_CARS_FOR_DRIVER }} />
        ),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    } else {
      this.props.navigate("/addcar");
    }
  };

  showChangeCarWarningModal = () => {
    this.setState({
      mainAlertText: t("before_you_change_cars_finish_job"),
    });
    this.props.showModalAlert(!this.props.showMAlert);
  };

  editCar = (id) => {
    if (this.props.atwork === true) {
      return this.showChangeCarWarningModal();
    }

    if (id) {
      this.props.setCarId(id);
      this.props.setEditCarId(id);
      this.props.navigate("/editcar");
    }
  };

  tryDel = () => {
    let id = this.state.carDelInfo.carIdForDelete;
    if (id === this.props.selectedCar.id) {
      this.props.resetSelectedCar();
    }
    this.props.delCar(id);
  };

  removeAuto = () => {
    this.setState({
      selectedCarFromList: false,
      showActiveCar: false,
      showListCar: true,
    });
  };

  getDefaulCar = (carInfo) => {
    if (!carInfo && this.props.cars?.length) {
      return this.props.cars?.find((car) => car.isDefault === true);
    }
    return carInfo.isDefault === true;
  };

  getDefaultOrInspectationStyle = (carInfo) => {
    if (!carInfo.inspectionTill || new Date(carInfo.inspectionTill) < new Date()) {
      return false;
    }

    return true;
  };

  seeDocs = (id) => {
    if (id) {
      this.props.setCarId(id);
      this.props.navigate("/cardocuments");
    }
  };

  tryClearError = () => {
    this.props.clearError();
  };

  setNewDefaultCar = (car) => {
    if (this.props.atwork === true) {
      return this.showChangeCarWarningModal();
    }

    const carInfo = {
      id: car.id,
    };

    if (car.id) {
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.setCarId(car.id);
      this.props.setIsDefault(carInfo);

      this.setState({
        mainAlertText: t("success_set_car"),
        isDefaultCarId: car.id,
      });
    }
  };

  checkSelectedCar = (car) => {
    this.setState({
      currentNewCar: car.id,
      selectedCarFromList: true,
    });
    this.props.getAllInspections(car.id);
  };

  render() {
    const {
      cars,
      brands,
      models,
      colors,
      classes,
      delStatus,
      addStatus,
      showMConfirm,
      showMAlert,
      inspectionInfo,
      errorStatus,
      lastInspectionStatus,
    } = this.props;

    if (delStatus === true || addStatus === true) {
      this.props.getAvtos(false);
    }

    const foundActiveCar = cars.find((carInfo) => carInfo.isDefault);
    const foundSelectedCar = cars.find((carInfo) => this.state.currentNewCar === carInfo.id);
    const activeCarPrefsList = foundActiveCar?.orderPreferences?.map((pref) => {
      return (
        <li key={pref.id}>
          {t(pref.name)}
          <CheckIcon className="check_icon" />
        </li>
      );
    });
    const selectedCarPrefsList = foundSelectedCar?.orderPreferences?.map((pref) => {
      return (
        <li key={pref.id}>
          {t(pref.name)}
          <CheckIcon className="check_icon" />
        </li>
      );
    });
    const statusText = !lastInspectionStatus
      ? t("not_specified")
      : inspectionInfo?.status === 2
        ? t("not_passed")
        : inspectionInfo.status === 1
          ? `${t("to")} ${formatDateFunc(
              inspectionInfo.inspectionTill,
              this.props.profinfo?.language || "en",
              "toDate",
            )}`
          : inspectionInfo.status === 0
            ? t("under_review")
            : null;

    const updatedFoundActiveCar = { ...foundActiveCar };
    for (let i = 0; i < brands.length; i++) {
      if (updatedFoundActiveCar?.brandId === brands[i].id) {
        updatedFoundActiveCar.brand = brands[i].name;
        break;
      }
    }
    for (let i = 0; i < models.length; i++) {
      if (updatedFoundActiveCar?.modelId === models[i].id) {
        updatedFoundActiveCar.model = models[i].name;
        break;
      }
    }
    for (let i = 0; i < colors.length; i++) {
      if (updatedFoundActiveCar?.colorId === colors[i].id) {
        updatedFoundActiveCar.color = colors[i].name;
        break;
      }
    }

    const carActive = updatedFoundActiveCar?.id && (
      <>
        <div className="car-info">
          <div className="car-info_block border_btm-active">
            <h2 className="brand">
              {updatedFoundActiveCar?.brand} {updatedFoundActiveCar?.model}
            </h2>
            <h2 className="govnumber_car">{updatedFoundActiveCar.govNumber}</h2>
          </div>

          <div className="car-info_block border_btm-active">
            <p className="brand">{t(updatedFoundActiveCar.color)}</p>
            <p>{updatedFoundActiveCar.manufactureYear}</p>
          </div>

          <div className="car-info_block border_btm-active">
            <p className="brand">{t("car_class")}</p>
            <p>{t(updatedFoundActiveCar.className)}</p>
          </div>

          <div className="car-info_block">
            <p className="end-data">{t("maintenance")}</p>
            <p className="end-data">{statusText}</p>
          </div>
        </div>
        <div className="prefs-block">
          <p>{t("additional_services")}</p>
          <ul className="prefs-list">{activeCarPrefsList}</ul>
        </div>

        <div className="btn-action_block">
          <div className="change-auto_block active_auto">
            <Button
              className={classNames("change_btn", {}, [])}
              onClick={() => this.editCar(updatedFoundActiveCar.id)}
            >
              {t("change")}
            </Button>
          </div>
          {(inspectionInfo.status === 2 || inspectionInfo.status === undefined || !lastInspectionStatus) && (
            <div className="inspection-auto_block">
              <Button
                className={classNames("inspection_btn", {}, [])}
                onClick={() => this.seeDocs(updatedFoundActiveCar.id)}
              >
                {t("pass_to_TO")}
              </Button>
            </div>
          )}
        </div>

        <div className="remove-auto_block">
          <Button
            className={classNames("remove_btn", {}, [])}
            onClick={() =>
              this.showConfirm(
                updatedFoundActiveCar.id,
                updatedFoundActiveCar.brand,
                updatedFoundActiveCar.govNumber,
              )
            }
          >
            {t("delete_auto")}
          </Button>
        </div>
      </>
    );

    const updatedFoundSelectedCar = { ...foundSelectedCar };
    for (let i = 0; i < brands.length; i++) {
      if (updatedFoundSelectedCar?.brandId === brands[i].id) {
        updatedFoundSelectedCar.brand = brands[i].name;
        break;
      }
    }
    for (let i = 0; i < models.length; i++) {
      if (updatedFoundSelectedCar?.modelId === models[i].id) {
        updatedFoundSelectedCar.model = models[i].name;
        break;
      }
    }
    for (let i = 0; i < colors.length; i++) {
      if (updatedFoundSelectedCar?.colorId === colors[i].id) {
        updatedFoundSelectedCar.color = colors[i].name;
        break;
      }
    }

    const carSelectedFromList = updatedFoundSelectedCar && (
      <>
        <div className="car-info">
          <div className="car-info_block border_btm-active">
            <h2 className="brand">
              {updatedFoundSelectedCar.brand} {updatedFoundSelectedCar.model}
            </h2>
            <h2 className="govnumber_car">{updatedFoundSelectedCar.govNumber}</h2>
          </div>

          <div className="car-info_block border_btm-active">
            <p className="brand">{t(updatedFoundSelectedCar.color)}</p>
            <p>{updatedFoundSelectedCar.manufactureYear}</p>
          </div>

          <div className="car-info_block border_btm-active">
            <p className="brand">{t("car_class")}</p>
            <p>{t(updatedFoundSelectedCar.className)}</p>
          </div>

          <div className="car-info_block">
            <p className="end-data">{t("maintenance")}</p>
            <p className="end-data">{statusText}</p>
          </div>
        </div>

        <div className="prefs-block">
          <p>{t("additional_services")}</p>
          <ul className="prefs-list">{selectedCarPrefsList}</ul>
        </div>
        <div className="btn-action_block">
          {!updatedFoundSelectedCar.isDefault && (
            <div className="default-auto_block">
              <Button
                className={classNames("defaultcar_btn", {}, [])}
                onClick={() => this.setNewDefaultCar(updatedFoundSelectedCar)}
              >
                {t("set_default_car")}
              </Button>
            </div>
          )}
          <div className="change-auto_block">
            <Button
              className={classNames("change_btn", {}, [])}
              onClick={() => this.editCar(updatedFoundSelectedCar.id)}
            >
              {t("change")}
            </Button>
          </div>
          {(inspectionInfo.status === 2 || inspectionInfo.status === undefined || !lastInspectionStatus) && (
            <div className="inspection-auto_block">
              <Button
                className={classNames("inspection_btn", {}, [])}
                onClick={() => this.seeDocs(updatedFoundSelectedCar.id)}
              >
                {t("pass_to_TO")}
              </Button>
            </div>
          )}
        </div>
        <div className="remove-auto_block">
          <Button
            className={classNames("remove_btn", {}, [])}
            onClick={() =>
              this.showConfirm(
                updatedFoundSelectedCar.id,
                updatedFoundSelectedCar.brand,
                updatedFoundSelectedCar.govNumber,
              )
            }
          >
            {t("delete_auto")}
          </Button>
        </div>
      </>
    );

    const sortedCarList = cars.sort((a, b) => {
      if (a.brand && b.brand) {
        return a.brand.localeCompare(b.brand);
      }
      return 0;
    });

    const carsList = sortedCarList.map((car) => {
      const updatedCar = {
        ...car,
        brand: brands.find((brand) => brand.id === car.brandId)?.name || car.brand,
        model: models.find((model) => model.id === car.modelId)?.name || car.model,
        color: colors.find((color) => color.id === car.colorId)?.name || car.color,
        class: classes.find((cls) => cls.id === car.classId)?.name || car.class,
      };

      const carInspectionStatus =
        !updatedCar.inspectionTill || new Date(updatedCar.inspectionTill) < new Date()
          ? "nav-list_cars car-need-inspection"
          : "nav-list_cars";

      return (
        <div className="block_list" key={updatedCar.id} onClick={() => this.checkSelectedCar(updatedCar)}>
          <li className={carInspectionStatus}>
            <div
              className={classNames(
                "main-current_block",
                { active_car: this.getDefaultOrInspectationStyle(updatedCar) },
                [],
              )}
            >
              <div className="radio-btn_block">
                <div className="radio-check_block">
                  <RadioGroup value={this.state.currentNewCar || ""}>
                    <FormControlLabel
                      checked={this.getDefaulCar(updatedCar)}
                      control={<Radio />}
                      value={updatedCar.id}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="car-model_info">
                <div className="car_title">
                  <p className="brand">
                    {updatedCar.brand} {updatedCar.model} {updatedCar.manufactureYear}
                  </p>
                </div>

                <div>
                  <p className="car_class">{t(updatedCar.className)}</p>
                </div>

                <div className="car_status">
                  {this.getDefaulCar(updatedCar) && updatedCar.inspectionTill && <p>{t("selected")}</p>}
                  {!updatedCar.inspectionTill && <p>{t("not_passed_maintenance")}</p>}
                </div>
              </div>
              <h2 className="govnumber_car">{this.truncateNumberPlate(updatedCar.govNumber)}</h2>
            </div>
          </li>
        </div>
      );
    });

    return (
      <>
        {showMConfirm ? (
          <ModalConfirm
            additionalHandler={this.removeAuto}
            confirmHandler={this.tryDel.bind(this)}
            mainConfirmText={this.state.mainConfirmText}
          />
        ) : null}
        {showMAlert ? (
          <ModalAlert confirmHandler={this.props.getAvtos} mainAlertText={this.state.mainAlertText} />
        ) : null}

        <div className="cars-section">
          <MobileTopBarHeight />
          <div className="container">
            <div className="navigation-block">
              <Link to={getRedirectPage("/dashboard")}>
                <BackIcon className="icon-nav" />
              </Link>
              <p className="link_add_cars" onClick={this.addNewCar}>
                <AddCarIcon className="icon-nav add_car_icon" />
              </p>
            </div>
            <div className="info-item">
              <h1>{t("auto")}</h1>

              <div className="nav-line">
                <div
                  className={!this.state.showListCar ? "nav-btn-left active" : "nav-btn-left"}
                  onClick={this.showActive}
                >
                  {this.state.showActiveCar && <CheckIcon className="check_icon-active" />}
                  <span>{t("active")}</span>
                </div>
                <div
                  className={this.state.showListCar ? "nav-btn active" : "nav-btn"}
                  onClick={this.showList}
                >
                  {this.state.showListCar && <CheckIcon className="check_icon-active" />}
                  <span>{t("list")}</span>
                </div>
              </div>
            </div>
            {this.state.showListCar && !this.state.selectedCarFromList ? (
              <>
                {carsList}
                <div className="add_auto" onClick={this.addNewCar}>
                  <PlusIcon className="icon_plus" />
                  <p className="link_add_cars"> {t("add_auto")}</p>
                </div>
              </>
            ) : this.state.selectedCarFromList ? (
              carSelectedFromList
            ) : (
              carActive
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars.avtos,
    carId: state.cars.carId,
    brands: state.addcar.brands,
    models: state.addcar.models,
    colors: state.addcar.colors,
    classes: state.addcar.classes,
    delStatus: state.cars.delStatus,
    addStatus: state.cars.addStatus,
    showMConfirm: state.modalConfirm.showMConfirm,
    selectedCarId: state.cars.selectedCarId,
    lastInspectionStatus: state.carInspections.lastInspectionStatus,
    selectedCar: state.cars.selectedCar,
    showMAlert: state.modalAlert.showMAlert,
    workStatus: state.orders.workStatus,
    errorStatus: state.cars.errorStatus,
    errorMsg: state.cars.errorMsg,
    inspectionInfo: state.carInspections.inspectionInfo,
    prefs: state.addcar.preferences,
    atwork: state.orders.workStatus,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrands: () => dispatch(getBrands()),
    getModels: () => dispatch(getModels()),
    getColors: () => dispatch(getColors()),
    getClasses: () => dispatch(getClasses()),
    getAvtos: (getClasses) => dispatch(getAvtos(getClasses)),
    getPreferences: () => dispatch(getPreferences()),
    setCarId: (id) => dispatch(setCarId(id)),
    delCar: (id) => dispatch(delCar(id)),
    setEditCarId: (id) => dispatch(setEditCarId(id)),
    clearInspection: () => dispatch(clearInspection()),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    clearError: () => dispatch(clearError()),
    resetSelectedCar: () => dispatch(resetSelectedCar()),
    getAllInspections: (carId) => dispatch(getAllInspections(carId)),
    updateCar: (carInfo) => dispatch(updateCar(carInfo)),
    setIsDefault: (carInfo) => dispatch(setIsDefault(carInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(Cars));

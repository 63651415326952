import React, { useEffect } from 'react';
import { ReactComponent as BackIcon } from '../../images/back.svg';
import { t } from 'i18next';
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight';
import styles from "./styles.module.scss";
import { useNavigate } from 'react-router-dom';
import withNavigation from 'src/hoc/NavigateForClass';
import { connect } from 'react-redux';
import { getDriverInvoices } from './invoices.actions';
import dayjs from "dayjs"

const Invoices = (props) => {
  const {
    invoicesData,
    getDriverInvoices
  } = props;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getDriverInvoices();
  }, [getDriverInvoices]);

  return (
    <div className={styles.invoicesContainer}>
      <MobileTopBarHeight />
      <div className={styles.invoicesBlock}>
        <div className={styles.topNav}>
          <div className={styles.topNavBack} onClick={goBack}>
            <BackIcon className={styles.arrowLeft} />
          </div>
          <div className={styles.topNavText}>
            <p>{t('invoices')}</p>
          </div>
        </div>
        <div className={styles.invoices}>
          {invoicesData.length > 0 ? (
            invoicesData
              .sort((a, b) => dayjs(b.sentAt).valueOf() - dayjs(a.sentAt).valueOf())
              .map(invoice => (
                <a key={invoice.id} href={invoice.url} className={styles.invoice}>
                  <p>{invoice.sentAt ? dayjs(invoice.sentAt).format("DD.MM.YYYY") : t("invoice")}</p>
                </a>
              ))
          ) : (
            <p>{t("no_data_to_view")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    invoicesData: state.invoices.driverInvoices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDriverInvoices: () => dispatch(getDriverInvoices()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(Invoices));
import moment from "moment/moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/ru";
import "moment/locale/sk";
import "moment-timezone";

const dateFormat = {
  date: "DD MMM YYYY, HH:mm",
  toDate: `D MMM YYYY`,
};

export const formatDateFunc = (date, language, format = "date") => {
  const timezone = localStorage.getItem("timezone") || "Europe/Kiev";

  // Set the language
  moment.locale(language.toLowerCase());
  const utcDate = moment.utc(date);
  const convertedDate = moment.tz(utcDate, timezone);

  return convertedDate.format(dateFormat[format]);
};

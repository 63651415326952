import React, { Component } from 'react'
import { connect } from 'react-redux'

import ModalAlert from '../ModalAlert'
import { showModalAlert } from '../ModalAlert/modalAlert.actions'

import { ReactComponent as BackIcon } from '../../images/back.svg'
import { ReactComponent as RightArrow } from '../../images/right-arrow.svg'
import { ReactComponent as CameraIcon } from '../../images/camera.svg'

import { ReactComponent as UserIcon } from '../../images/user.svg'
import { ReactComponent as FileIcon } from '../../images/file.svg'
import { ReactComponent as CarIcon } from '../../images/car.svg'

import './uploadphotodriver.scss'
import phaseImg from '../../media/phaseImg.png'
import { t } from 'i18next'
import { Trans, withTranslation } from 'react-i18next'
import { showModalAction } from '../ModalAction/modalAction.actions'
import ModalAction from '../ModalAction'
import { sendImg } from '../EditProfile/editprofile.actions'
import { MAX_IMAGE_SIZE_MB } from '../../config-in-git'
import { Button } from '../../shared/ui/Buttons/DefautlButton/button'
import { classNames } from '../../shared/lib/classNames/classNames'
import {
  getDriverInfo,
  getRegInfo,
  updDriverInfo,
  updRegistrationStep,
} from '../RegistrationPage/registration.actions'
import { getAvtos } from '../Cars/cars.actions'
import { CircularProgressBar } from '../../shared/ui/CircularProgressBar'
import { getInfoCircularStep } from '../AddCar/addcar.actions'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'
import withNavigation from 'src/hoc/NavigateForClass'
import Loader from '../Loader'
import { imageDefaultQuality } from 'src/shared/constants'
import { compressImage } from 'src/shared/helpers/compressImage'

class UploadPhotoDriver extends Component {
  constructor(props) {
    super(props)
    this.fileUserInput = React.createRef()
    this.fileLicenseInputFront = React.createRef()
    this.filePassportInput = React.createRef()
    this.fileCarInput = React.createRef()
  }

  state = {
    mainAlertText: '',
    secondAlertText: '',
    actionText1: '',
    actionText2: '',

    photo: '',
    driverLicenseF: '',

    showBotSub: false,
    showDocFrontSub: false,
    showDocBackSub: false,

    isUploadPhotoModal: false,
    step: 0,
    currentCircleStep: 0,
    circleCircleFill: 0,

    filledCircleFields: [],
    loader: false
  }

  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      this.props.getDriverInfo()
      this.props.getRegInfo()
      this.props.getAvtos()

      if (this.props.cars?.length !== 0) {
        this.state.filledCircleFields.push(3)
      }

      this.updateCircleProgress()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showBotSub !== this.state.showBotSub &&
      this.props.addImgStatus === true
    ) {
      this.setState({
        showBotSub: this.state.showBotSub,
      })
    }

    if (
      prevState.showDocFrontSub !== this.state.showDocFrontSub &&
      this.props.addImgStatus === true
    ) {
      this.setState({
        showDocFrontSub: this.state.showDocFrontSub,
      })
    }

    if (prevProps.driver !== this.props.driver) {
      if (this.props.driver?.photo) {
        this.setState({
          photo: this.props.driver.photo,
        })
      }

      if (this.props.driver?.driverLicense) {
        this.setState({
          driverLicenseF: this.props.driver.driverLicense,
        })
      }

      if (this.props.driver?.photo && !this.state.photo) {
        this.state.filledCircleFields.push(1)
        this.updateCircleProgress()
      }

      if (this.props.driver?.driverLicense && !this.state.driverLicenseF) {
        this.state.filledCircleFields.push(2)
        this.updateCircleProgress()
      }
    }

    if (prevProps.cars?.length !== this.props.cars?.length) {
      this.state.filledCircleFields.push(3)
      this.updateCircleProgress()
    }

    // after upload new driver photo, do update photo in state
    if (prevProps.driverPhoto !== this.props.driverPhoto) {
      this.setState({ photo: this.props.driverPhoto })
    }

    // after upload new driver license, do update driverLicenseF in state
    if (prevProps.driverLicense !== this.props.driverLicense) {
      this.setState({ driverLicenseF: this.props.driverLicense })
    }
  }

  updateCircleProgress = () => {
    const { filledCircleFields } = this.state
    const filledFieldsCircleLength = filledCircleFields.length

    if (filledFieldsCircleLength === 1) {
      this.setState({
        currentCircleStep: 1,
        circleCircleFill: 33,
      })
    }

    if (filledFieldsCircleLength === 2) {
      this.setState({
        currentCircleStep: 2,
        circleCircleFill: 66,
      })
    }

    if (filledFieldsCircleLength > 2) {
      this.setState({
        currentCircleStep: 3,
        circleCircleFill: 100,
      })
    }
  }

  handlePhotoProfileUpload = (step) => {
    if (step) {
      this.setState({
        step,
      })
    }
    this.setState({
      mainAlertText: t('upload_photo'),
      actionText1: t('select_from_gallery'),
    })

    if (step === 1 && !this.state.photo) {
      let status = !this.props.showMAction
      this.props.showModalAction(status)
    }

    if (step === 2 && !this.state.driverLicenseF) {
      let status = !this.props.showMAction
      this.props.showModalAction(status)
    }
  }

  //goLoadPassportAndCarPage //!TODO
  goAddCar = () => {
    this.props.navigate('/addcar')
    const circlarProgressInfo = {
      currentCircleStep: this.state.currentCircleStep,
      circleCircleFill: this.state.circleCircleFill,
    }
    this.props.getInfoCircularStep(circlarProgressInfo)
  }

  uploadUserPhoto = () => {
    this.fileUserInput.current.click()
    let status = !this.props.showMAction
    this.props.showModalAction(status)
    this.props.getDriverInfo()
  }

  uploadLicensePhoto = () => {
    this.fileLicenseInputFront.current.click()
    let status = !this.props.showMAction
    this.props.showModalAction(status)
    this.props.getDriverInfo()
  }

  goLastStep = () => {
    if (this.props.driver.registrationStep !== 2) {
      this.props.updRegistrationStep(2)
    }
    this.props.navigate('/cardocuments')
  }

  photoToBase64 = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.name;
    const type = file.type.split('/')[0];
  
    if (file.size > 95728640 || type !== 'image') {
      this.setState({
        mainAlertText: type !== 'image' ? t('only_photos_can_uploaded') : <Trans i18nKey="image_exceeded_max_size" values={{ maxSize: MAX_IMAGE_SIZE_MB }} />,
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    } else {
      try {
        this.setState({
          loader: true,
        });
        const compressedFile = await compressImage(file, imageDefaultQuality.width, imageDefaultQuality.height, imageDefaultQuality.quality);
  
        const reader = new FileReader();
        reader.onloadend = () => {
          let imgArr = reader.result.split(',');
          let imgInfo = {
            name: file.name,
            fileArray: imgArr[1],
            inputName: fileName,
          };
          this.props.sendImg(imgInfo);
        };
  
        reader.readAsDataURL(compressedFile);
        this.setState({
          loader: false,
        });
      } catch (error) {
        console.error("Image compression error:", error);
        this.setState({
          mainAlertText: t("image_compression_error"),
        });
        let status = !this.props.showMAlert;
        this.props.showModalAlert(status);
      }
    }
  
    if (file) {
      if (event.target.name === 'photo') {
        this.setState({
          showBotSub: !this.state.showBotSub,
          // isUploadPhotoModal: true,
        });
      }
      if (event.target.name === 'driverLicenseF') {
        this.setState({
          showDocFrontSub: !this.state.showDocFrontSub,
        });
      }
      if (event.target.name === 'driverLicB') {
        this.setState({
          showDocBackSub: !this.state.showDocBackSub,
        });
      }
    }
};

  isUploadPhoto = () => {
    this.setState({
      showBotSub: false,
      showDocFrontSub: false,
      step: 0,
    })
  }

  updateDriverInfo = () => {
    const driverInfo = {
      phone: this.props.driver.phone,
      email: this.props.driver.email,
      firstName: this.props.driver.firstName,
      lastName: this.props.driver.lastName,
      middleName: this.props.driver.middleName,
      gender: this.props.driver.gender,
      photo:
        this.state.photo !== '' ? this.state.photo : this.props.driver.photo,
      driverLicense:
        this.state.driverLicenseF !== ''
          ? this.state.driverLicenseF
          : this.props.driver.driverLicenseF,
      cityId: this.props.driver.cityId,
      dateOfBirth: this.props.driver.dateOfBirth,
    }

    if (
      this.state.photo &&
      !this.props.driver.photo &&
      !this.state.filledCircleFields.includes(1)
    ) {
      this.state.filledCircleFields.push(1)
    }

    if (
      this.state.driverLicenseF &&
      !this.props.driver.driverLicense &&
      !this.state.filledCircleFields.includes(2)
    ) {
      this.state.filledCircleFields.push(2)
    }

    this.updateCircleProgress()

    this.setState({
      showBotSub: false,
      showDocFrontSub: false,
      step: 0,
    })

    if (driverInfo.photo.length > 100 && this.props.driverPhoto) {
      driverInfo.photo = this.props.driverPhoto
    }

    if (
      driverInfo.driverLicense &&
      driverInfo.driverLicense.length > 100 &&
      this.props.driverLicense
    ) {
      driverInfo.driverLicense = this.props.driverLicense
    }

    this.props.updDriverInfo(driverInfo)
  }

  checkAllField = () => {
    const { updPhotoStatus, updLicenseStatus, cars } = this.props
    if (updPhotoStatus && updLicenseStatus && cars.length !== 0) {
      return false
    } else {
      return true
    }
  }

  render() {
    const {
      showMAction,
      addImgStatus,
      driver,
      updPhotoStatus,
      updLicenseStatus,
      cars,
      showMAlert,
      isFirstRegistration,
    } = this.props

    const registrationStep = JSON.parse(
      localStorage.getItem('registrationStep'),
    )

    const userPhoto = (
      <div className="upload-section">
        <div className="container">
          <div className="upload-user_photo">
            <MobileTopBarHeight />
            <div className="back_icon" onClick={this.isUploadPhoto}>
              <BackIcon className="arrowleft_icon" />
            </div>
            <h1>{t('photo_profile')}</h1>
            <div className="block_image">
              <h2>{t('photo_upload')}!</h2>
              <p>{t('photo_updated_after_checking')}</p>
              <img
                src={this.state.photo}
                className="upload-profile_img"
                alt=""
              />
              <div className="block-newphoto">
                <Button
                  className={classNames('new_photo', {}, [])}
                  onClick={() => this.fileUserInput.current.click()}
                >
                  <CameraIcon className="camera_icon" />
                  {t('new_photo')}
                </Button>
              </div>
            </div>
          </div>
          <div className="confirm_block">
            <Button
              className={classNames('confirm_btn', {}, [])}
              onClick={() => this.updateDriverInfo()}
            >
              {t('confirm')}
            </Button>
          </div>
          <input
            type="file"
            name="photo"
            ref={this.fileUserInput}
            onChange={this.photoToBase64}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    )

    const licensePhoto = (
      <div className="upload-section">
        <div className="container">
          <div className="upload-license_photo">
            <MobileTopBarHeight />
            <div className="back_icon" onClick={this.isUploadPhoto}>
              <BackIcon className="arrowleft_icon" />
            </div>
            <h1>{t('photo_driver_license')}</h1>
            <div className="block_image">
              <h2>{t('front_photo_uploaded')}!</h2>
              <p>{t('photo_updated_after_checking')}</p>
              <img
                src={
                  this.state.driverLicenseF
                    ? this.state.driverLicenseF
                    : phaseImg
                }
                className="upload-profile_img"
                alt=""
              />
              <div className="block-newphoto">
                <Button
                  className={classNames('new_photo', {}, [])}
                  onClick={() => this.fileLicenseInputFront.current.click()}
                >
                  <CameraIcon className="camera_icon" />
                  {t('new_photo')}
                </Button>
              </div>
            </div>
          </div>
          <div className="confirm_block">
            <Button
              className={classNames('confirm_btn', {}, [])}
              onClick={() => this.updateDriverInfo()}
              // onClick={this.isUploadPhoto}
            >
              {t('confirm')}
            </Button>
          </div>
          <input
            type="file"
            name="driverLicenseF"
            ref={this.fileLicenseInputFront}
            onChange={this.photoToBase64}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    )

    // Показываем модальное окно, если нет изображения
    if (addImgStatus) {
      if (this.state.showBotSub) {
        return userPhoto
      }

      if (this.state.showDocFrontSub) {
        return licensePhoto
      }
    }
    // Проверяем, если ли уже загруженное изображение
    if (this.state.step === 1 && this.state.photo) {
      return userPhoto
    }
    if (this.state.step === 2 && this.state.driverLicenseF) {
      return licensePhoto
    }

    let uploadPhotoFunc
    if (this.state.step === 1) {
      uploadPhotoFunc = this.uploadUserPhoto
    } else if (this.state.step === 2) {
      uploadPhotoFunc = this.uploadLicensePhoto
    }
    return (
      <>
        {showMAction ? (
          <ModalAction
            mainAlertText={this.state.mainAlertText}
            actionText1={this.state.actionText1}
            mainAction={uploadPhotoFunc}
            iconMainActive={<CameraIcon />}
          />
        ) : null}
        {showMAlert ? (
          <ModalAlert mainAlertText={this.state.mainAlertText} />
        ) : null}
        {this.state.loader && <Loader />}
        <div className="upload-section">
          <div className="container">
            <div className="uploadinfo-section__main">
              <MobileTopBarHeight />
              <div className="block_title">
                {!isFirstRegistration && driver.registrationStep >= 2 && (
                  <>
                    <div
                      className="back_icon"
                      onClick={() => this.props.navigate('/')}
                    >
                      <BackIcon className="arrowleft_icon" />
                    </div>
                    <div className="title_text">{t('back')}</div>
                  </>
                )}
              </div>

              <div className="block-photo_phase">
                <div className="info-title_pahse">
                  <p>{t('profile_and_document_photos')}</p>
                </div>
                <div className="photo_pahse">
                  <CircularProgressBar
                    stepValue={this.state.currentCircleStep}
                    valuePercent={this.state.circleCircleFill}
                  />
                </div>
              </div>

              <div className="block-profile_photo">
                <p className="step">{t('step')} 1</p>
                <div
                  className="block-user_photo"
                  onClick={() => this.handlePhotoProfileUpload(1)}
                >
                  <div className="icon-item">
                    <UserIcon className="profile-icon" />
                  </div>
                  <div className="user-info_block">
                    <p className="user_title">{t('photo_profile')}</p>
                    <div>
                      <p className="user_info">
                        {driver.firstName} {driver.lastName}{' '}
                        {updPhotoStatus && `, ${t('photo_upload_v2')}`}
                      </p>
                    </div>
                  </div>
                  <div className="user-upload_icon">
                    <RightArrow className="arrowright_icon" />
                  </div>
                </div>
                <input
                  type="file"
                  name="photo"
                  ref={this.fileUserInput}
                  onChange={this.photoToBase64}
                  style={{ display: 'none' }}
                />
              </div>

              <div className="block-profile_photo">
                <p className="step">{t('step')} 2</p>
                <div
                  className="block-user_photo step_two"
                  onClick={() => this.handlePhotoProfileUpload(2)}
                >
                  <div className="icon-item">
                    <FileIcon className="profile-icon" />
                  </div>
                  <div className="user-info_block">
                    <p className="user_title">{t('photo_driver_license')}</p>
                    <div>
                      {updLicenseStatus && (
                        <p className="user_info">
                          {updLicenseStatus && t('done')}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="user-upload_icon">
                    <RightArrow className="arrowright_icon" />
                  </div>
                </div>
                <input
                  type="file"
                  name="driverLicenseF"
                  ref={this.fileLicenseInputFront}
                  onChange={this.photoToBase64}
                  style={{ display: 'none' }}
                />
              </div>

              <div className="block-profile_photo">
                <p className="step">{t('step')} 3</p>
                <div
                  className="block-user_photo step_two"
                  onClick={() => this.goAddCar()}
                >
                  <div className="icon-item">
                    <CarIcon className="profile-icon" />
                  </div>
                  <div className="user-info_block">
                    <p className="user_title">{t('auto_information')}</p>
                    <div>
                      {cars.length !== 0 && (
                        <p className="user_info">
                          {cars.length !== 0 && t('done')}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="user-upload_icon">
                    <RightArrow className="arrowright_icon" />
                  </div>
                </div>
              </div>

              <div className="btn-next_block">
                <Button
                  className={classNames(
                    'btn-continue',
                    { btn_disabled: this.checkAllField() },
                    [],
                  )}
                  onClick={() => this.goLastStep()}
                  disabled={this.checkAllField()}
                >
                  {t('confirm')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    driverPhoto: state.editprofile.driverPhoto,
    driverLicense: state.editprofile.driverLicense,
    updPhotoStatus: state.registration.updPhotoStatus,
    updLicenseStatus: state.registration.updLicenseStatus,
    showMAction: state.modalAction.showMAction,
    showMAlert: state.modalAlert.showMAlert,
    addImgStatus: state.editprofile.addImgStatus,
    driver: state.registration.profinfo,
    cars: state.cars.avtos,
    isFirstRegistration: state.registration.isFirstRegistration,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showModalAction: (status) => dispatch(showModalAction(status)),
    sendImg: (imgInfo) => dispatch(sendImg(imgInfo)),
    getDriverInfo: () => dispatch(getDriverInfo()),
    getRegInfo: () => dispatch(getRegInfo()),
    updDriverInfo: (driverInfo) => dispatch(updDriverInfo({driverInfo})),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    getAvtos: () => dispatch(getAvtos()),
    updRegistrationStep: (registrationStep) =>
      dispatch(updRegistrationStep(registrationStep)),
    getInfoCircularStep: (circlarProgressInfo) =>
      dispatch(getInfoCircularStep(circlarProgressInfo)),
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withNavigation(UploadPhotoDriver)),
)

import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  registration,
  clearError,
  rememberInfo,
  clearUsePhone,
  getDriverInfo,
  setFirstRegistration,
  sendCoordsToRpc,
} from "./registration.actions";
import { clearLogin, setCountryPhoneCode } from "../LoginPage/login.actions";

import CounterSelector from "../../components/CountrySelector";
import { Loader } from "../Loader";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import { getCityList } from "../Profile/profile.actions";

import { ReactComponent as CloseIcon } from "../../images/close.svg";
import { ReactComponent as GlobeIcon } from "../../images/globe.svg";

import { withTranslation } from "react-i18next";
import { t } from "i18next";

import { classNames } from "../../shared/lib/classNames/classNames";
import Select from "../../shared/ui/Select";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { Input } from "../../shared/ui/Input/inputs";

import "./registration.scss";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";
import { SUPPORTED_LANGUAGES } from "src/shared/const/languages";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";

const nameDefaultRegex = new RegExp("^[а-яёєїіґ'‘]{0,20}$", "i");
const internationalNameRegex = new RegExp(/^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F'-]+$/u);

const chkEmail = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
);

const chkPassword = new RegExp(/^[\S]{0,16}$/);

class RegistrationPage extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    errorN: "",
    wrongName: "",
    errorS: "",
    wrongSName: "",
    errorE: "",
    wrongEmail: "",
    errorPN: "",
    wrongPhone: "",
    errorP: "",
    wrongPassword: "",
    errorCP: "",
    wrongCPassword: "",
    mainAlertText: "",
    continueRegister: false,

    isCorrectPhone: false,
    languageId: 2,
    isOpen: false,
    isOpenCity: false,
    isOpenLanguage: false,
  };

  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");
    const language = localStorage.getItem(LANGUAGE_NAME);

    if (!this.props.rpcCountryInfo?.country_code) {
      this.props.sendCoordsToRpc();
    }

    if (language) {
      const currentLang = SUPPORTED_LANGUAGES.find((lang) => lang.locale === language);
      this.setState({
        languageId: currentLang?.id,
      });
    }

    if (accessToken) {
      this.props.getDriverInfo();
    }

    this.props.getCityList();
    this.props.clearLogin();

    this.setState({
      phone: this.props.tryRegInfo?.phone?.replace(/\++/, "") || "",
      email: this.props.tryRegInfo.email ? this.props.tryRegInfo.email : "",
      firstName: this.props.tryRegInfo.firstName ? this.props.tryRegInfo.firstName : "",
      lastName: this.props.tryRegInfo.lastName ? this.props.tryRegInfo.lastName : "",
      password: this.props.tryRegInfo.password ? this.props.tryRegInfo.password : "",
      cpassword: this.props.tryRegInfo.cpassword ? this.props.tryRegInfo.cpassword : "",
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.languageId !== this.state.languageId) {
      const language = localStorage.getItem(LANGUAGE_NAME);
      this.props.i18n.changeLanguage(language);
    }
  }

  ChgInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  ChgEmail = (event) => {
    if (this.state.email.length < 254) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  isNameValid = (name) => {
    const { rpcCountryInfo } = this.props;
    if (!rpcCountryInfo?.country_code || rpcCountryInfo?.country_code === "UA") {
      return name.length >= 2 && nameDefaultRegex.test(name);
    } else {
      return name.length >= 2 && internationalNameRegex.test(name);
    }
  };

  ChgFIO = ({ target }) => {
    const name = target.value.replace(/\s+/g, "").trim();
    const typeField = target.name;
    const isValid = this.isNameValid(name);

    this.setState({
      [typeField]: target.value,
      ...(typeField === "firstName" && { errorN: !isValid }),
      ...(typeField === "lastName" && { errorS: !isValid }),
    });
  };

  chgPass = (event) => {
    if (chkPassword.test(event.target.value) || event.target.value.length === 0) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  ChgPhone = (phoneValue) => {
    this.setState({
      phone: phoneValue,
    });
  };

  tryReg = async () => {
    const language = localStorage.getItem(LANGUAGE_NAME);

    if (this.state.firstName.length < 2) {
      this.setState({
        errorN: true,
        wrongName: t("name_is_not_entered_correctly"),
      });
      return false;
    } else {
      this.setState({
        errorN: false,
        wrongName: "",
      });
    }

    if (this.state.lastName.length < 2) {
      this.setState({
        errorS: true,
        wrongSName: t("last_name_is_not_entered_correctly"),
      });
      return false;
    } else {
      this.setState({
        errorS: false,
        wrongSName: "",
      });
    }

    //if ((this.state.email.length < 7) || (chkEmail.test(this.state.email) === false)) {
    if (this.state.email.length > 0) {
      if (chkEmail.test(this.state.email) === false) {
        this.setState({
          errorE: true,
          wrongEmail: t("email_is_not_entered_correctly"),
        });
        return false;
      } else {
        this.setState({
          errorE: false,
          wrongEmail: "",
        });
      }
    }

    if (this.state.phone.length <= 9) {
      this.setState({
        errorPN: true,
        wrongPhone: t("incorrect_phone"),
      });
      return false;
    } else {
      this.setState({
        errorPN: false,
        wrongPhone: "",
      });
    }

    if (this.state.password.length < 4) {
      this.setState({
        errorP: true,
        wrongPassword: t("password_must_be_least_4_characters"),
      });
      return false;
    } else {
      this.setState({
        errorP: false,
        wrongPassword: "",
      });
    }

    if (this.state.cpassword !== this.state.password) {
      this.setState({
        errorCP: true,
        wrongCPassword: t("passwords_do_not_match"),
      });
      return false;
    } else {
      this.setState({
        errorCP: false,
        wrongCPassword: "",
      });
    }

    const driverInfo = {
      branchId: 1,
      phone: `+${this.state.phone}`,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password,
      country: this.props.rpcCountryInfo?.country_code || "UA",
      currency: this.props.rpcCountryInfo?.currency_code || "UAH",
      language,
    };
    const response = await this.props.registration(driverInfo);
    if (!response) {
      return null;
    }
    this.setState({
      continueRegister: true,
    });
  };

  tryClearError = () => {
    this.props.clearError();
  };

  tryRememberInfo = () => {
    const info = {
      phone: this.state.phone,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password,
      cpassword: this.state.cpassword,
    };
    this.props.rememberInfo(info);
  };

  getCurrentLanguageId = (langId) => {
    this.setState({
      languageId: langId,
    });
  };

  toggleSelectCity = () => {
    this.setState({
      isOpenCity: !this.state.isOpenCity,
      isOpen: !this.state.isOpen,
    });
  };

  toggleSelectLanguage = () => {
    this.setState({
      isOpenLanguage: !this.state.isOpenLanguage,
      isOpen: !this.state.isOpen,
    });
  };

  validateRegInfo = () => {
    const { phone, lastName, firstName, password, cpassword, email } = this.state;
    if (phone?.length < 9 || !lastName || !firstName || !password || !cpassword || !email) {
      return true;
    }

    return false;
  };

  tryRegAndRemeberInfo = () => {
    this.tryRememberInfo();
    this.tryReg();
  };

  clearInputValue = (name) => {
    this.setState({
      [name]: "",
    });
  };

  render() {
    const registrationStep = JSON.parse(localStorage.getItem("registrationStep"));

    const { regStatus, errorStatus, phoneInUse, showMAlert, rpcLoaded, rpcCountryInfo, rpcError } =
      this.props;

    if (rpcError) {
      return <Navigate to="/" />;
    }

    if (rpcLoaded) {
      return <Loader />;
    }

    if (localStorage.getItem("accessToken") && registrationStep >= 2) {
      return <Navigate to="/orders" />;
    }

    const currentLanguage = SUPPORTED_LANGUAGES.find((lang) => {
      const language = localStorage.getItem(LANGUAGE_NAME);
      if (language && language !== "undefined") {
        return lang.locale === language;
      }

      return lang.id === this.state.languageId;
    });

    if (regStatus && this.state.continueRegister) {
      return <Navigate to="/checkcode" />;
    }

    if (phoneInUse) {
      this.setState({
        mainAlertText: t("number_already_registered"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.clearUsePhone();
    }

    const isLanguageSelector = this.state.isOpenLanguage ? (
      <div className="main-language_city">
        <Select
          currentId={this.state.languageId}
          listItem={SUPPORTED_LANGUAGES}
          onSelectOptionId={this.getCurrentLanguageId}
          title={t("choice_language")}
          toggleSelect={this.toggleSelectLanguage}
          topCustomComponent={<MobileTopBarHeight />}
        />
      </div>
    ) : null;

    return (
      <>
        {showMAlert ? <ModalAlert completeIcon={true} mainAlertText={this.state.mainAlertText} /> : null}
        <div className="registration-section">
          <div className="container">
            {isLanguageSelector}
            <div
              className={classNames("registration-section__main", { hidden_block: this.state.isOpen }, [])}
            >
              <MobileTopBarHeight />
              <div className="register_icon">
                <CloseIcon className="close-icon" onClick={() => this.props.navigate("/")} />
              </div>

              <div className="register-section__login">
                <div className="login_title">
                  <p className="login-title__register">{t("registration_driver")}</p>
                  <p className="login-title__info">
                    {t("choose_city_for_work")} <br />
                    {t("give_your_phone_number")}
                  </p>
                </div>
              </div>

              <div className="register_phone-block">
                <CounterSelector
                  country={rpcCountryInfo?.country_code?.toLowerCase() || "sk"}
                  countryCodeEditable={false}
                  enableSearch
                  error={this.state.errorPN}
                  errorText={this.state.wrongPhone}
                  handleClearValue={() => this.clearInputValue("phone")}
                  onChange={this.ChgPhone}
                  placeholder={t("phone_number")}
                  setCountryPhoneCode={this.props.setCountryPhoneCode}
                  value={this.state.phone}
                />
              </div>

              <div className="register_info-block">
                <p>{t("name")}</p>
                <Input
                  className={classNames("input_field", { error_field: this.state.errorN }, [])}
                  handleClearValue={() => this.clearInputValue("firstName")}
                  name="firstName"
                  onChange={this.ChgFIO}
                  placeholder={t("name")}
                  value={this.state.firstName}
                />
                {this.state.errorN ? <p id="error">{this.state.wrongName}</p> : null}
              </div>

              <div className="register_info-block">
                <p>{t("last_name")}</p>
                <Input
                  className={classNames("input_field", { error_field: this.state.errorS }, [])}
                  handleClearValue={() => this.clearInputValue("lastName")}
                  maxLength={50}
                  name="lastName"
                  onChange={this.ChgFIO}
                  placeholder={t("last_name")}
                  value={this.state.lastName}
                />
                {this.state.errorS ? <p id="error">{this.state.wrongSName}</p> : null}
              </div>

              <div className="register_info-block">
                <p>{t("email")}</p>
                <Input
                  className={classNames("input_field", { error_field: this.state.errorE }, [])}
                  handleClearValue={() => this.clearInputValue("email")}
                  name="email"
                  onChange={this.ChgEmail}
                  placeholder="example@gmail.com"
                  value={this.state.email}
                />
                {this.state.errorE ? <p id="error">{this.state.wrongEmail}</p> : null}
              </div>

              <div className="register_info-block">
                <p>{t("password")}</p>
                <Input
                  className={classNames("input_field", { error_field: this.state.errorP }, [])}
                  handleClearValue={() => this.clearInputValue("password")}
                  isPassword
                  name="password"
                  onChange={this.chgPass}
                  placeholder="*********"
                  type="password"
                  value={this.state.password}
                />
                {this.state.errorP ? <p id="error">{this.state.wrongPassword}</p> : null}
              </div>

              <div className="register_info-block">
                <p>{t("repeat_password")}</p>
                <Input
                  className={classNames("input_field", { error_field: this.state.errorCP }, [])}
                  handleClearValue={() => this.clearInputValue("cpassword")}
                  isPassword
                  name="cpassword"
                  onChange={this.chgPass}
                  placeholder="*********"
                  type="password"
                  value={this.state.cpassword}
                />
                {this.state.errorCP ? <p id="error">{this.state.wrongCPassword}</p> : null}
              </div>

              <div className="next-block_btn">
                <Button
                  className={classNames("next-btn", { btn_disabled: this.validateRegInfo() }, [])}
                  disabled={this.validateRegInfo()}
                  onClick={this.tryRegAndRemeberInfo}
                >
                  {t("continue")}
                </Button>
              </div>

              <div className="block-rules">
                <p>
                  {t("clicking_continue_you_agree")} <br />
                  <Link onClick={this.tryRememberInfo} to="/rules">
                    {t("terms_of_offers")}
                  </Link>{" "}
                  {t("and")}{" "}
                  <Link onClick={this.tryRememberInfo} to="/policy">
                    {t("politics")} <br /> {t("confidentiality_1")}
                  </Link>
                </p>
              </div>

              <div className="language-select_block">
                <Button className={classNames("lang_btn", {}, [])} onClick={this.toggleSelectLanguage}>
                  <GlobeIcon className="language_icon" color="#fff" />
                  {!currentLanguage ? "Українська" : currentLanguage?.name}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    regStatus: state.registration.regStatus,
    phone: state.login.phone,
    errorStatus: state.registration.errorStatus,
    errorMsg: state.registration.errorMsg,
    tryRegInfo: state.registration.tryRegInfo,
    showMAlert: state.modalAlert.showMAlert,
    phoneInUse: state.registration.phoneInUse,
    cityList: state.ProfileReducer.cityList,
    driver: state.registration.profinfo,
    isFirstRegistration: state.registration.isFirstRegistration,

    // RPC
    countryCode: state.login.countryCode,
    rpcLoaded: state.registration.rpcLoaded,
    rpcCountryInfo: state.registration.rpcCountryInfo,
    rpcError: state.registration.rpcError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registration: (driverInfo) => dispatch(registration(driverInfo)),
    clearLogin: () => dispatch(clearLogin()),
    clearError: () => dispatch(clearError()),
    rememberInfo: (info) => dispatch(rememberInfo(info)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    setFirstRegistration: (isFirstRegistration) => dispatch(setFirstRegistration(isFirstRegistration)),
    clearUsePhone: () => dispatch(clearUsePhone()),
    getCityList: () => dispatch(getCityList()),
    getDriverInfo: () => dispatch(getDriverInfo()),
    sendCoordsToRpc: () => dispatch(sendCoordsToRpc()),
    setCountryPhoneCode: (countryCode) => dispatch(setCountryPhoneCode(countryCode)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withNavigation(RegistrationPage)),
);

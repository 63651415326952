import { useCallback, useState } from "react";
import { mapViewNum } from "src/shared/constants";
import * as dark3DTheme from "./map-views/dark-3d-styles.json";
import * as light3DTheme from "./map-views/light-3d-styles.json";

const view3DStyles = {
  lightTheme: light3DTheme,
  darkTheme: dark3DTheme,
};

// contain in dark-3d-styles.json
const buildingsIn3DId = "building-3d";

export const useChangeMapView = ({ map, mapLoaded, currentTheme }) => {
  // change map view between 2D and 3D
  const [mapView, setMapView] = useState(mapViewNum["2D"]);

  const changeMapView = useCallback(() => {
    if (mapLoaded && map.current) {
      if (mapView === mapViewNum["3D"]) {
        map.current.removeLayer(buildingsIn3DId);
        setMapView(() => mapViewNum["2D"]);
        return;
      }
      // Insert the layer beneath any symbol layer.
      const layers = map.current.getStyle().layers;

      let labelLayerId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.current.addLayer(view3DStyles[currentTheme], labelLayerId);

      setMapView(() => mapViewNum["3D"]);
    }
  }, [currentTheme, map, mapLoaded, mapView]);

  return { mapView, changeMapView };
};

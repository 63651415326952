import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceWithToken } from "../../axios.config";
import { apinew, apiImg } from "../../config";

export const GET_INSPECTION = "GET_INSPECTION";
export const NO_INSPECTION = "NO_INSPECTION";
export const REQUEST_TO_ID = "REQUEST_TO_ID";
export const CLEAR = "CLEAR";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const getAllInspections = createAsyncThunk(
  "inspections/getAllInspections",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${apinew}/inspection/?carId=${id}&skip=0&take=100`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200 || response.status === 204) {
        const arrInsp = response.data.data;
        if (arrInsp.length !== 0) {
          const lastInspId = arrInsp[arrInsp.length - 1].id;
          const inspectionResponse = await axiosInstance.get(`${apinew}/inspection/${lastInspId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (inspectionResponse.status === 200 || inspectionResponse.status === 204) {
            dispatch({ type: GET_INSPECTION, payload: inspectionResponse.data.data });
          }
        } else {
          dispatch({ type: NO_INSPECTION });
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const clearInspection = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR,
    });
  };
};

export const CLEAR_ADD_NEW_TO_STATUS = "CLEAR_ADD_NEW_TO_STATUS";
export const clearAddNewTOStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ADD_NEW_TO_STATUS,
    });
  };
};

export const BTN_ACTIVE = "BTN_ACTIVE";
export const ADD_NEW_TO_STATUS = "ADD_NEW_TO_STATUS";
export const sendTOImgs = createAsyncThunk(
  "inspections/sendTOImgs",
  async (info, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      // Get a new inspection ID
      const response = await axiosInstanceWithToken({
        method: "post",
        url: `${apinew}/inspection`,
        data: {
          driverId: info.driverId,
          carId: info.carId,
        },
      });

      if (response.status === 200 || response.status === 204) {
        dispatch({ type: BTN_ACTIVE, payload: false });
        const newTOId = response.data.data.id;

        // Upload images to the server and associate them with the new inspection
        for (let i = 0; i < info.fileArray.length; i++) {
          try {
            const uploadFile = await axiosInstanceWithToken({
              method: "post",
              url: `${apiImg}/files`,
              data: {
                name: info.fileArray[i].name,
                fileArray: info.fileArray[i].data,
                fileTypeId: 1,
              },
            });

            if (uploadFile.status === 200 || uploadFile.status === 204) {
              const imgUrl = `${apiImg}/download/${uploadFile.data.data.id}`;
              await axiosInstance({
                method: "post",
                url: `${apinew}/inspection/${newTOId}/file`,
                headers: { Authorization: `Bearer ${accessToken}` },
                data: { fileName: imgUrl },
              });
            }
          } catch (error) {
            dispatch({ type: BTN_ACTIVE, payload: true });
            return rejectWithValue(error);
          }

          if (i === info.fileArray.length - 1) {
            dispatch({ type: ADD_NEW_TO_STATUS });
          }
        }
      }
    } catch (error) {
      dispatch({ type: BTN_ACTIVE, payload: true });
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

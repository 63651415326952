import React from "react";
import { paymentGate } from "src/config";
import PaymentForm from "./components/PaymentForm";
import { paymentsTypes } from "src/shared/constants";
import { onTopPaddingRequested } from "src/shared/funcForMobile";

const CreditCard = ({ cardFormData, userPhone, isLimitedCards, className }) => {
  const renderPaymentForm = () => {
    switch (true) {
      case cardFormData?.gatewayType === paymentsTypes.STRIPE && !isLimitedCards: {
        return (
          <PaymentForm
            action={cardFormData.redirectUrl}
            cardId={cardFormData.cardId}
            className={className}
            method="GET"
            onClick={() => onTopPaddingRequested(true)}
          />
        );
      }

      case cardFormData?.gatewayType === paymentsTypes.WAYFORPAY && !isLimitedCards: {
        const wayforpayInputs = [
          { name: "merchantAccount", value: cardFormData.merchantAccount },
          { name: "language", value: cardFormData.language },
          { name: "merchantDomainName", value: cardFormData.merchantDomainName },
          { name: "merchantAuthType", value: cardFormData.merchantAuthType },
          { name: "merchantSignature", value: cardFormData.merchantSignature },
          { name: "orderReference", value: cardFormData.orderReference },
          { name: "amount", value: cardFormData.amount },
          { name: "returnUrl", value: cardFormData.returnUrl },
          { name: "currency", value: cardFormData.currency },
          {
            name: "serviceUrl",
            value: `${paymentGate}${cardFormData.serviceUrl}`,
          },
          { name: "clientPhone", value: userPhone },
        ];

        return (
          <PaymentForm
            action={cardFormData.paymentSite}
            cardId={cardFormData.cardId}
            className={className}
            formInputs={wayforpayInputs}
            method="POST"
            onClick={() => onTopPaddingRequested(true)}
          />
        );
      }
      default:
        return null;
    }
  };

  return renderPaymentForm();
};

export default CreditCard;

import { createAsyncThunk } from "@reduxjs/toolkit";

import { CLEAR_TOKEN } from "../../app/app.actions";
import { axiosInstance } from "../../axios.config";
import { orderApi } from "../../config";
import {
  CURRENT_ORDER_INFO_RETRY_INTERVAL_MS,
  CURRENT_ORDER_INFO_MAX_RETRY_COUNT,
  CURRENT_ORDER_STATUS_CHECK_INTERVAL_MS,
  CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS,
  ORDER_REQUEST_RETRY_DELAY_MS,
} from "../../config-in-git";
import { checkPushOrderAccepted } from "../Orders/orders.actions";
// helper
const textFormator = (text) => {
  const arrAdress = text.split(", ");
  let minusCountElement = 1;
  let mainText = arrAdress[0] + " ";

  if (parseInt(arrAdress[1])) {
    mainText += arrAdress[1];
    minusCountElement++;
  }

  return {
    mainText,
    secondaryText: arrAdress.slice(minusCountElement).join(", "),
  };
};

// helper

export const INCOMING_ORDER_INFO = "INCOMING_ORDER_INFO";
export const DECLINE_INCOMING_ORDER = "DECLINE_INCOMING_ORDER";
export const CLOSE_MODAL_DECLINED_INCOMING_ORDER = "CLOSE_MODAL_DECLINED_INCOMING_ORDER";
export const MODAL_DECLINED_INCOMING_ORDER = "MODAL_DECLINED_INCOMING_ORDER";
export const ACCEPT_INCOMING_ORDER = "ACCEPT_INCOMING_ORDER";
export const CURRENT_ORDER_INFO = "CURRENT_ORDER_INFO";
export const REMOVE_ORDER_REQUEST = "REMOVE_ORDER_REQUEST";
export const PAID_ORDER = "PAID_ORDER";
export const SEND_ORDER_POINT = "SEND_ORDER_POINT";
export const ORDER_STATUS = "ORDER_STATUS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const NO_ORDER = "NO_ORDER";
export const CURRENT_ORDER_ERROR_INFO = "CURRENT_ORDER_ERROR_INFO";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const CURRENT_ORDER_DISPLAY_ERROR_STATUS = "CURRENT_ORDER_DISPLAY_ERROR_STATUS";

// Accept Order logic
export const incomingOrderInfo = (newOrderData) => {
  return (dispatch, getState) => {
    try {
      let order = newOrderData;

      const currentOrder = localStorage.getItem("currentOrder")
        ? JSON.parse(localStorage.getItem("currentOrder"))
        : null;

      let readyForOrder = JSON.parse(localStorage.getItem("readyForOrder"));
      const orderStage = localStorage.getItem("orderStage");
      const state = getState();

      // check if we have new and current order
      if (!newOrderData && !currentOrder) {
        return;
      }

      // if we have active order, remove another incomes orders
      if (currentOrder) {
        order = currentOrder;
        localStorage.removeItem("orderShowRequestBody");
      } else {
        // remove order time on income order
        localStorage.removeItem("orderTime");

        // set new order
        localStorage.setItem("currentOrder", JSON.stringify(newOrderData));
        localStorage.setItem("orderShowRequestBody", JSON.stringify(newOrderData));
      }

      if (state.order.incomingOrderStatus || !readyForOrder || orderStage) {
        return;
      }

      dispatch({
        type: INCOMING_ORDER_INFO,
        payload: order,
      });

      if (window.location.pathname !== "/orders") {
        window.location.href = "/orders";
      }
    } catch (error) {
      alert(`error took order, in incomingOrderInfo`);
    }
  };
};

export const closeMissedModalOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_MODAL_DECLINED_INCOMING_ORDER,
    });
  };
};

export const acceptIncomingOrder = createAsyncThunk(
  "orders/acceptIncomingOrder",
  async (info, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/accept`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          orderId: info.orderId,
          lat: info.lat,
          lon: info.lon,
        },
      });

      if (response?.status === 200 || response?.status === 204) {
        localStorage.setItem("orderStage", 1);
        localStorage.removeItem("timeForOrder");
        localStorage.removeItem("orderShowRequestDelaySec");

        dispatch({ type: ACCEPT_INCOMING_ORDER });
      }
    } catch (error) {
      localStorage.removeItem("orderShowRequestBody");
      localStorage.removeItem("orderShowRequestDelaySec");
      localStorage.removeItem("pushOrderAccepted");

      dispatch(clearOrder());
      dispatch(checkPushOrderAccepted());

      if (error.response && error.response?.status === 400) {
        dispatch({
          type: NO_ORDER,
          payload: true,
        });
        return;
      }

      return rejectWithValue(error);
    }
  },
);

export const declineIncomingOrder = createAsyncThunk(
  "orders/declineIncomingOrder",
  async ({ orderId, reason, declineType = true }, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/decline`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          orderId: orderId,
          reason: reason,
        },
      });

      if (response?.status === 200 || response?.status === 204) {
        localStorage.removeItem("timeForOrder");

        if (declineType === null) {
          localStorage.setItem("readyForOrder", true);
          return;
        }

        if (declineType) {
          localStorage.setItem("readyForOrder", true);
          dispatch({ type: DECLINE_INCOMING_ORDER });
        } else {
          localStorage.setItem("readyForOrder", true);
          dispatch({ type: MODAL_DECLINED_INCOMING_ORDER });
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const rejectOrder = createAsyncThunk(
  "orders/rejectOrder",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/${data.orderId}/reject`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data,
      });

      if (response?.status === 200 || response?.status === 204) {
        localStorage.removeItem("orderStage");
        localStorage.removeItem("orderShowRequestDelaySec");
        localStorage.removeItem("orderShowRequestBody");
        localStorage.setItem("readyForOrder", true);
        dispatch(clearOrder());
      }
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data &&
        error.response?.data.Data === "order_finished"
      ) {
        localStorage.removeItem("orderStage");
        localStorage.setItem("readyForOrder", true);
        dispatch({
          type: DECLINE_INCOMING_ORDER,
        });
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const arriveOrder = createAsyncThunk(
  "order/arriveOrder",
  async (orderId, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/${orderId}/arrive`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch (error) {
      if (error.message === "Network Error") {
        setTimeout(() => dispatch(arriveOrder(orderId)), ORDER_REQUEST_RETRY_DELAY_MS);
      } else if (error.response?.status === 400 && error.response?.data?.Data === "order_finished") {
        dispatch(rejectOrder(orderId));
      }

      return rejectWithValue(error);
    }
  },
);

export const clientOnBoard = createAsyncThunk(
  "order/clientOnBoard",
  async (orderId, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/${orderId}/clientonboard`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch (error) {
      if (error.message === "Network Error") {
        setTimeout(() => dispatch(clientOnBoard(orderId)), ORDER_REQUEST_RETRY_DELAY_MS);
      }

      return rejectWithValue(error);
    }
  },
);

export const completeOrder = createAsyncThunk(
  "order/completeOrder",
  async (orderId, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/${orderId}/complete`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch (error) {
      if (error.message === "Network Error") {
        setTimeout(() => dispatch(completeOrder(orderId)), ORDER_REQUEST_RETRY_DELAY_MS);
      }

      return rejectWithValue(error);
    }
  },
);

export const paidOrder = createAsyncThunk(
  "order/paidOrder",
  async (orderId, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/${orderId}/paid`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: PAID_ORDER,
        });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setTimeout(() => dispatch(paidOrder(orderId)), ORDER_REQUEST_RETRY_DELAY_MS);
      }

      return rejectWithValue(error);
    }
  },
);

export const currentOrderInfo = (navigate, statusId, nextStatusId, retryOnError = false) => {
  const accessToken = localStorage.getItem("accessToken");
  let countOrderInfoRequest = 0;
  let errorTimeId = null;
  let timeoutId = null;
  let timeId = null;

  const makeRequest = (dispatch) => {
    axiosInstance
      .get(orderApi + "/driver/current", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        clearTimeout(timeoutId);
        clearTimeout(errorTimeId);
        //  console.log("currentOrderInfo response in orders ACTIONS:", response)

        if (response?.status === 200 || response?.status === 204) {
          response.data.data.destinationPoints = response.data.data.destinationPoints.map((adress) => {
            if (adress.mainText) {
              return adress;
            }
            return {
              ...adress,
              ...textFormator(adress.name),
            };
          });

          dispatch({
            type: CURRENT_ORDER_INFO,
            payload: response?.data.data,
          });

          const getOrderStatusId = response?.data.data.statusId;
          if (!statusId && !nextStatusId) {
            localStorage.setItem("orderStage", response?.data.data.statusId);
            localStorage.setItem("readyForOrder", false);
          }

          if (location.pathname !== "/order") {
            console.log("pathname.endsWith");
            if (navigate) {
              navigate("/order");
            }
          }

          if (nextStatusId) {
            dispatch({
              type: CURRENT_ORDER_DISPLAY_ERROR_STATUS,
              payload: countOrderInfoRequest,
            });

            if (
              getOrderStatusId === statusId &&
              countOrderInfoRequest < CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS
            ) {
              countOrderInfoRequest = 0;
              localStorage.setItem("orderStage", nextStatusId);
              clearTimeout(timeId);
            } else if (countOrderInfoRequest < CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS) {
              timeId = setTimeout(() => makeRequest(dispatch), CURRENT_ORDER_STATUS_CHECK_INTERVAL_MS);
              countOrderInfoRequest += 1;
            } else {
              dispatch({
                type: CURRENT_ORDER_DISPLAY_ERROR_STATUS,
                payload: countOrderInfoRequest,
              });
              clearTimeout(timeId);
            }
          }
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          errorTimeId = setTimeout(() => makeRequest(dispatch), ORDER_REQUEST_RETRY_DELAY_MS);
          // countOrderInfoRequest += 1
        } else if (
          error.response &&
          error.response?.statusText == "Unauthorized" // eslint-disable-line
        ) {
          dispatch({
            type: CLEAR_TOKEN,
            payload: false,
          });
        } else {
          if (retryOnError) {
            if (countOrderInfoRequest <= CURRENT_ORDER_INFO_MAX_RETRY_COUNT) {
              timeoutId = setTimeout(() => makeRequest(dispatch), CURRENT_ORDER_INFO_RETRY_INTERVAL_MS);
              countOrderInfoRequest++;
            } else {
              dispatch({
                type: CURRENT_ORDER_ERROR_INFO,
                payload: true,
              });
              countOrderInfoRequest = 0;
            }
          }
        }
      });
  };
  return (dispatch) => {
    makeRequest(dispatch);
  };
};

export const removeOrderRequest = () => {
  // console.log("Enter removeOrderRequest in order.actions")
  //  localStorage.setItem('readyForOrder', true)  //  fixOrderInfo
  return (dispatch) => {
    dispatch({
      type: REMOVE_ORDER_REQUEST,
      payload: false,
    });
  };
};

export const sendOrderPoint = (pos) => {
  return (dispatch) => {
    dispatch({
      type: SEND_ORDER_POINT,
      payload: pos,
    });
  };
};

export const orderStatus = (data) => {
  // console.log("orderStatus:", data)
  if (data.value === 2) {
    localStorage.setItem("readyForOrder", true); //  fixOrderInfo
    //  console.log("Клиент отменил поездку!:", data)
    return (dispatch) => {
      dispatch({
        type: ORDER_STATUS,
      });
    };
  }
};

export const RESET_EXIT_ORDER = "RESET_EXIT_ORDER";
export const resetExitOrder = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_EXIT_ORDER,
    });
  };
};

export const paidByCard = () => {
  return (dispatch) => {
    localStorage.removeItem("orderStage");
    dispatch({
      type: PAID_ORDER,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

export const resetNoOrder = () => {
  return (dispatch) => {
    dispatch({
      type: NO_ORDER,
      payload: false,
    });
  };
};

export const clearOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER,
    });
  };
};

export const UPDATE_PRICE = "UPDATE_PRICE";
export const updatePrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRICE,
      payload: price,
    });
  };
};

import { Component } from "react";
import { connect } from "react-redux";

import { t } from "i18next";
import withNavigation from "src/hoc/NavigateForClass";

import { getValuteSymbol } from "src/services/getValuteSymbol";
import { sortItemsByDate } from "src/services/sortItemsByDate";

import { classNames } from "src/shared/lib/classNames/classNames";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

import { ReactComponent as BackIcon } from "src/images/back.svg";
import { ReactComponent as CheckIcon } from "src/images/check.svg";
import { ReactComponent as ClockIcon } from "src/images/clock-v2.svg";
import { ReactComponent as CloseIcon } from "src/images/close.svg";

import "./gtransactions.scss";

import { getWithdrawT, clearError } from "./gtransactions.actions";

class GTransactions extends Component {
  componentDidMount() {
    this.props.getWithdrawT();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gTransactionsList?.length !== this.props.gTransactionsList?.length) {
      sortItemsByDate([...this.props.gTransactionsList]);
    }
  }

  getTransactionDescription = (transactionInfo) => {
    if (transactionInfo.isApproved === true) {
      return t("operation_performed");
    }

    if (transactionInfo.isApproved === null) {
      return t("operation_pending_confirmation");
    }

    if (transactionInfo.isApproved === false) {
      return t("operation_rejected");
    }
  };

  getTransactionIcon = (transactionInfo) => {
    if (transactionInfo.isApproved === true) {
      return <CheckIcon className="ArrowInfo" />;
    }

    if (transactionInfo.isApproved === null) {
      return <ClockIcon className="ArrowInfo" />;
    }

    if (transactionInfo.isApproved === false) {
      return <CloseIcon className="ArrowInfo rejected-operation" />;
    }
  };

  getTransactionItemClass = (transactionInfo) => {
    let classNamesBg = "";
    if (transactionInfo.isApproved === true) {
      classNamesBg += "light_green";
    }

    if (transactionInfo.isApproved === null) {
      classNamesBg += "light_yellow";
    }

    if (transactionInfo.isApproved === false) {
      classNamesBg += "light_red";
    }

    return `transaction-item ${classNamesBg}`;
  };

  tryClearError = () => {
    this.props.clearError();
  };

  render() {
    const { gTransactionsList } = this.props;

    const groupedItems = sortItemsByDate([...gTransactionsList]);

    let transactionList;
    if (gTransactionsList) {
      transactionList = Object.keys(groupedItems).map((transaction) => (
        <div className="transaction-list-block" key={transaction}>
          <h3>{transaction}</h3>
          {groupedItems[transaction].map((item) => (
            <div className={this.getTransactionItemClass(item)} key={item.id}>
              <div className="info-block">
                {this.getTransactionIcon(item)}
                <div>
                  <p
                    className={classNames(
                      "info-title",
                      { "rejected-operation": item.isApproved === false },
                      [],
                    )}
                  >
                    {this.getTransactionDescription(item)}
                  </p>
                </div>
              </div>
              <p
                className={classNames(
                  "current-amount",
                  { "rejected-operation": item.isApproved === false },
                  [],
                )}
              >
                {item.amount}
                {getValuteSymbol(this.props.profinfo.currency)}
              </p>
            </div>
          ))}
        </div>
      ));
    }

    return (
      <div className="container-fluid p-0 m-0">
        <div className="row m-0">
          <div className="gtransactions-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <div className="top-nav">
              <div onClick={() => this.props.navigate(-1)}>
                <BackIcon className="ArrowLeft" />
              </div>
              <p className="top-nav-text">{t("transaction_history")}</p>
            </div>
            {gTransactionsList.length === 0 ? (
              <p className="no-transactions">{t("had_no_transactions")}</p>
            ) : (
              <ul className="transactions-list">{transactionList}</ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gTransactionsList: state.gtransactions.gTransactionsList,
    errorStatus: state.gtransactions.errorStatus,
    errorMsg: state.gtransactions.errorMsg,
    profinfo: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWithdrawT: () => dispatch(getWithdrawT()),
    clearError: () => dispatch(clearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(GTransactions));

import React, { Component, memo } from "react";
import { connect } from "react-redux";
import noImg from "../../media/noimg.jpg";
import { logout } from "../LoginPage/login.actions";

import { ReactComponent as WalletIcon } from "../../images/wallet.svg";
import { ReactComponent as ModeIcon } from "../../images/mode.svg";
import { ReactComponent as PlanetIcon } from "../../images/planet.svg";
import { ReactComponent as CarIcon } from "../../images/car.svg";
import { ReactComponent as StarIcon } from "../../images/star.svg";
import { ReactComponent as PasswordIcon } from "../../images/password.svg";
import { ReactComponent as HeadphonesIcon } from "../../images/headphones.svg";
import { ReactComponent as WarningOctagon } from "../../images/warning-octagon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right.svg";
import { ReactComponent as DiamondIcon } from "../../images/diamond.svg";
import { ReactComponent as ExitIcon } from "../../images/exit.svg";
import { ReactComponent as DeleteAccountIcon } from "../../images/DeleteAccountIcon.svg";
import { ReactComponent as DocumentsIcon } from "../../images/DocumentsIcon.svg";
import FeedIcon from '@mui/icons-material/Feed';
import CachedIcon from '@mui/icons-material/Cached';

import styles from "./dashboard.module.scss";
import { t } from "i18next";
import { switchColorTheme } from "../../app/app.actions";
import { Link, NavLink } from "react-router-dom";
import ModalConfirm from "../Modals/ModalConfirm";
import BottomMenu from "../BottomMenu";
import { showModalConfirm } from "../Modals/ModalConfirm/modalConfirm.actions";

import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import Loader from "../Loader";
import { getDriverInfo } from "../RegistrationPage/registration.actions";
import { SUPPORTED_LANGUAGES } from "src/shared/const/languages";
import getCommission from "./helpers/getCommission";
import { getAvtos } from "../Cars/cars.actions";
import { defaultCommission } from "src/shared/constants";
import { getHistory } from "../History/store/history.actions";
import { getDriverInvoices } from "../Invoices/invoices.actions";

class Dashboard extends Component {
  state = {
    appShowOnTop: JSON.parse(localStorage.getItem("appShowOnTop")),
    isOffAppShowOnTop: false,
    appVersion: localStorage.getItem("appVersion"),
    isOpenLanguage: false,
    showLoader: false,
    currentCommission: null,
    isFetchingCommission: false,
  };

  componentDidMount() {
    this.props.getHistory(1000);
    this.props.getDriverInfo();
    this.props.getAvtos();
    this.props.getDriverInvoices();

    if (this.state.appShowOnTop) {
      this.setState({
        isOffAppShowOnTop: true,
      });
    }
  }

  // delete this on merge motivation
  async componentDidUpdate(prevProps, prevState) {
    // Check if cars prop or currentCommission state has changed
    if (JSON.stringify(prevProps.cars) !== JSON.stringify(this.props.cars) || !this.state.currentCommission) {
      const coords = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;
      const selectedCar = this.props.cars.selectedCar;

      // Check if selectedCar is available and coordinates are available
      if (selectedCar?.classId && coords && !this.state.isFetchingCommission) {
        this.setState({ isFetchingCommission: true });
        const rpcCommission = await getCommission({
          coords,
          carClassId: selectedCar.classId,
        });

        this.setState({
          currentCommission: rpcCommission ? rpcCommission : defaultCommission,
          isFetchingCommission: false,
        });
      }
    }
  }

  switchColorTheme = () => {
    let theme = !this.props.colorTheme;
    this.props.switchColorTheme(theme);
  };

  moveBack = () => {
    this.setState({ showLoader: true });
    this.props.logout();
    document.location.href = "/";
  };

  showConfirm = () => {
    this.setState({
      mainConfirmText: t("want_to_get_out"),
      tryLogout: true,
    });
    this.props.showModalConfirm(true);
  };

  acceptPermition = () => {
    window.location.href = "settings:appear_on_top";
    this.intervalId = setInterval(this.checkLocalStoragePermition, 1000);
  };

  showConfirmPermition = () => {
    const appPermitionShowOnTop = localStorage.getItem("appPermitionShowOnTop");

    this.setState({
      tryLogout: false,
    });

    if (appPermitionShowOnTop === "false") {
      this.setState({
        mainConfirmText: t("need_permission_message"),
      });
      let status = !this.props.showMConfirm;
      this.props.showModalConfirm(status);
    } else {
      const appShowOnTop = JSON.parse(localStorage.getItem("appShowOnTop"));
      localStorage.setItem("appShowOnTop", !appShowOnTop);
      this.setState({
        appShowOnTop: !appShowOnTop,
      });
      const appType = localStorage.getItem("appType");

      // set show app popup
      try {
        if (appType === "android") {
          // eslint-disable-next-line no-undef
          Native.appShowOnTop(!appShowOnTop);
        }
      } catch (e) {
        console.log("THIS DEVICE IS NOT AN ANDROID OR IPHONE");
      }
    }
  };

  cancelModalConfirm = () => {
    let status = !this.props.showMConfirm;
    this.props.showModalConfirm(status);
  };

  checkLocalStoragePermition = () => {
    const appShowOnTop = JSON.parse(localStorage.getItem("appShowOnTop"));

    if (appShowOnTop) {
      this.setState({ appShowOnTop: appShowOnTop });
      clearInterval(this.intervalId);
    }
  };

  toggleSelectLanguage = () => {
    this.setState({
      isOpenLanguage: !this.state.isOpenLanguage,
      isOpen: !this.state.isOpen,
    });
  };

  reloadAppWithClearCache = () => {
    window.location.replace('/?timestamp= '+ new Date().getTime())
  }

  render() {
    const { driver, showMConfirm } = this.props;
    const appType = localStorage.getItem("appType");

    const currentLanguage = SUPPORTED_LANGUAGES.find(
      (item) => item.locale === String(this.props.profinfo.language) || null,
    );

    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="menu-main-container">
          <MobileTopBarHeight />
          <div className="profile-container">
            <div className="profile-section">
              <div className="profile-img-block">
                <img alt="" className="profile-img" src={driver.photo ? driver.photo : noImg} />
              </div>
              <div className="profile-info">
                <div className="label-info">
                  {driver.firstName} {driver.lastName}
                </div>
              </div>
              <NavLink to="/editprofile">
                <div className="profile-info">{t("edit")}</div>
              </NavLink>
            </div>
            <div className="profile-details">
              <div className="profile-detail-item">
                <div className="label-prof">{t("call_sign")}</div>
                <div className="value-prof">{driver.callSign}</div>
              </div>
              <div className="profile-detail-item">
                <div className="label-prof">{t("trips_count")}</div>
                {/* TODO: need to add correct value here. */}
                <div className="value-prof">{this.props.orders?.length || 0}</div>
              </div>
              <div className="profile-detail-item">
                <div className="label-prof">{t("driver_commission")}</div>

                <div className="value-prof">
                  {this.state.currentCommission ? `${this.state.currentCommission}%` : "..."}
                </div>
              </div>
            </div>
          </div>
          <div className="label">{t("main_settings")}</div>

          {!this.state.isOffAppShowOnTop && appType === "android" && (
            <div className="menu-element" onClick={this.showConfirmPermition}>
              <div className="icon-container">
                <DiamondIcon className="icon" />
              </div>
              <div className="title">
                <div>{t("show_on_top_other_windows")}</div>
                <div className="themelabel">{this.state.appShowOnTop ? t("enable") : t("disabled")}</div>
              </div>
              <div className="arrow">
                <ArrowRightIcon className="arrow-icon" />
              </div>
            </div>
          )}

          {/* <Link className="menu-element" to="/fines">
            <div className="icon">
              <FinesWarning />
            </div>
            <div className="title">{t('penalties')}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link> */}

          <Link className="menu-element" to="/money">
            <div className="icon">
              <WalletIcon className="icon WalletIcon" />
            </div>
            <div className="title">{t("financial_management")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/cars">
            <div className="icon">
              <CarIcon className="icon" />
            </div>
            <div className="title">{t("auto")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/invoices">
            <div className="stroke-default-icon">
              <DocumentsIcon className="stroke-default-icon" />
            </div>
            <div className="title">{t("invoices")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/myreting">
            <div className="icon">
              <StarIcon className="icon star-icon" />
            </div>
            <div className="title">{t("driver_rating")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/editpassword">
            <div className="icon">
              <PasswordIcon className="icon" />
            </div>
            <div className="title">{t("password")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/contactus">
            <div className="icon">
              <HeadphonesIcon className="icon" />
            </div>
            <div className="title">{t("customer_service")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/rules">
            <div className="icon">
              <WarningOctagon className="icon" />
            </div>
            <div className="title">{t("legal_info")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/delete-account">
            <div className="icon stroke-light-icon">
              <DeleteAccountIcon className="icon" />
            </div>
            <div className="title">{t("delete_account")}</div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <Link className="menu-element" to="/choose-language">
            <div className="default-icon">
              <PlanetIcon />
            </div>
            <div className="title">
              <div>{t("language_interface")}</div>
              <div className="themelabel">{currentLanguage?.name}</div>
            </div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
          <div className="menu-element" onClick={this.switchColorTheme}>
            <div className="icon-container">
              <ModeIcon className="icon" />
            </div>
            <div className="title">
              <div>{t("int_design")}</div>
              <div className="themelabel">{this.props.colorTheme ? t("light_theme") : t("dark_theme")}</div>
            </div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </div>

          <div className="menu-element" onClick={this.reloadAppWithClearCache}>
            <div className="icon-container">
              <CachedIcon className="icon" />
            </div>
            <div className="title">
              <div>{t("reload_app")}</div>
            </div>
          </div>

          {this.state.isOffAppShowOnTop && appType === "android" && (
            <div className="menu-element" onClick={this.showConfirmPermition}>
              <div className="icon-container">
                <DiamondIcon className="icon" />
              </div>
              <div className="title">
                <div>{t("show_on_top_other_windows")}</div>
                <div className="themelabel">{this.state.appShowOnTop ? t("enable") : t("disabled")}</div>
              </div>
              <div className="arrow">
                <ArrowRightIcon className="arrow-icon" />
              </div>
            </div>
          )}

          <Link className="menu-element" to="/user-support-info">
            <div className="default-icon">
              <FeedIcon />
            </div>
            <div className="title">
              <div>{t("inform_for_stp")}</div>
            </div>
            <div className="arrow">
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>

          <div className="menu-element" onClick={this.showConfirm}>
            <div className="icon-container">
              <ExitIcon className="icon" />
            </div>
            <div className="title">{t("exit")}</div>
          </div>
          {this.state.appVersion !== "false" && (
            <div className={styles.versionApp}>
              <p>AVersion {this.state.appVersion}</p>
            </div>
          )}
        </div>
        <BottomMenu />
        {showMConfirm &&
        !this.state.tryLogout &&
        localStorage.getItem("appPermitionShowOnTop") === "false" ? (
          <ModalConfirm
            cancelModalConfirm={this.cancelModalConfirm}
            confirmHandler={this.acceptPermition}
            isCustomCancel={true}
            mainConfirmText={this.state.mainConfirmText}
          />
        ) : null}
        {showMConfirm && this.state.tryLogout ? (
          <ModalConfirm confirmHandler={this.moveBack} mainConfirmText={this.state.mainConfirmText} />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    driver: state.registration.profinfo,
    goBack: state.menu.goBack,
    loginStatus: state.login.loginStatus,
    colorTheme: state.app.colorTheme,
    showMConfirm: state.modalConfirm.showMConfirm,
    orders: state.history.doneOrders,
    profinfo: state.registration.profinfo,
    cars: state.cars,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    switchColorTheme: (theme) => dispatch(switchColorTheme(theme)),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    getHistory: (isCount) => dispatch(getHistory(isCount)),
    getDriverInfo: () => dispatch(getDriverInfo()),
    getAvtos: () => dispatch(getAvtos()),
    getDriverInvoices: () => dispatch(getDriverInvoices()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Dashboard));

import React, { useEffect } from "react";
import { t } from "i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../images/back.svg";
import { getRedirectPage } from "../../services/getRedirectPage";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";
import ZendeskWidget, { ZendeskAPI } from "src/components/ZendeskWidget";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Button/button";
import { zendeskKey } from "src/config";
import { getZendeskToken } from "../Profile/profile.actions";
import useZendeskStyles from "src/components/ZendeskWidget/hooks/useZendeskStyles";
import "./contactus.scss";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";

const ContactUs = (props) => {
  const { location, driver, zendeskToken, zendeskTokenLoading, getZendeskToken } = props;

  const { adjustIframePadding } = useZendeskStyles();

  const payload = location.state?.payload;

  const addConversationFields = () => {
    const { callSign, firstName, lastName, phone } = driver;

    const fields = [
      { id: "11275948206236", value: String(callSign) },
      { id: "12351325214620", value: String(phone) },
      { id: "12335717889052", value: String(firstName) },
      { id: "12335694744348", value: String(lastName) },
    ];

    ZendeskAPI("messenger:set", "conversationFields", fields);
  };

  const handleCloseZendesk = () => {
    ZendeskAPI("messenger", "close");
  };

  const handleOpenZendesk = () => {
    const languageName = localStorage.getItem(LANGUAGE_NAME)?.toLowerCase();

    ZendeskAPI("messenger", "open");
    ZendeskAPI("messenger:set", "locale", languageName);

    adjustIframePadding();

    window.addEventListener("popstate", handleCloseZendesk);
  };

  const handleLoadedZendesk = () => {
    addConversationFields();
  };

  useEffect(() => {
    const fetchDataAndLogin = async () => {
      getZendeskToken();

      if (zendeskToken) {
        ZendeskAPI("messenger", "loginUser", function (cb) {
          cb(zendeskToken);
        });
      }
    };

    fetchDataAndLogin();

    return () => {
      ZendeskAPI("messenger", "close");
      window.removeEventListener("popstate", handleCloseZendesk);
    };
  }, [getZendeskToken, zendeskToken]);

  return (
    <>
      <ZendeskWidget defer onLoaded={handleLoadedZendesk} zendeskKey={zendeskKey} />
      <div className="container-fluid">
        <div>
          <div className="contactus-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <div className="top-nav">
              <NavLink to={payload ? getRedirectPage("/orders") : getRedirectPage("/dashboard")}>
                <BackIcon className="ArrowLeft" />
              </NavLink>
              <p className="top-nav-text">{t("contact_us")}</p>
              <p className="contact-text">{t("support_welcome")}</p>
            </div>

            <div className="contact-zendesk_block">
              <Button
                className={classNames("continue_btn", {}, [])}
                disabled={zendeskTokenLoading}
                isLoading={zendeskTokenLoading}
                onClick={handleOpenZendesk}
              >
                {t("chat_with_tech_support")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    driver: state.registration.profinfo,
    order: state.order.order,
    zendeskToken: state.ProfileReducer.zendeskToken,
    zendeskTokenLoading: state.ProfileReducer.zendeskTokenLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getZendeskToken: () => dispatch(getZendeskToken()),
  };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(ContactUs));

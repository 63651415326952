import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { tryLogin, clearLogin, clearError, setCountryPhoneCode } from "./login.actions";
import { refStatusPass } from "../Forgot/forgot.actions";
import { clearOrder } from "../Order/order.actions";
import { clearCars } from "../Cars/cars.actions";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";

import { clearReg } from "../RegistrationPage/registration.actions";
import CounterSelector from "../../components/CountrySelector";

import { t } from "i18next";

// MY
import { ReactComponent as CloseIcon } from "../../images/close.svg";
import { ReactComponent as BackIcon } from "../../images/back.svg";

import { withTranslation } from "react-i18next";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { classNames } from "../../shared/lib/classNames/classNames";
import { Input } from "../../shared/ui/Input/inputs";
import { clearRpcError, sendCoordsToRpc } from "../RegistrationPage/registration.actions";
import Loader from "../Loader";
import "./loginpage.scss";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";
import { clearCards } from "src/components/CreditCard/store/ccard.action";
import { fromMobileSetOtp } from "src/shared/funcForMobile";

//import 'materialize-css'

class LoginPage extends Component {
  state = {
    phone: localStorage.getItem("userPhone") || "",
    errorN: false,
    wrongPhone: "",
    password: "",
    errorP: false,
    wrongPassword: "",
    showTpl: false,
    isCorrectPhone: false,
  };

  // for call android
  fromMobileSetOtp = fromMobileSetOtp;

  componentDidMount() {
    const userPhone = localStorage.getItem("userPhone") || null;

    if (userPhone) {
      this.setState({ phone: userPhone, isCorrectPhone: true });
    }

    this.props.sendCoordsToRpc();
    this.props.clearReg();
    this.props.refStatusPass();
    this.props.clearLogin();
    this.props.clearOrder();
    this.props.clearCars();
    this.props.clearCards();

    localStorage.removeItem("userHash");
  }

  componentDidUpdate() {
    if (this.props.checkphone) {
      this.props.navigate("/login/sms-verefication");
    }
  }

  componentWillUnmount() {
    this.props.clearRpcError();
  }

  ChgPhone = (phoneValue) => {
    this.setState({
      phone: phoneValue,
    });

    if (this.state.phone.length <= 9) {
      this.setState({
        isCorrectPhone: false,
      });
      return false;
    } else {
      this.setState({
        isCorrectPhone: true,
      });
    }
  };

  PasswordInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  tryEnter = () => {
    const loginInfo = {
      phone: `+${this.state.phone}`,
    };
    this.setState({
      errorN: false,
      wrongPhone: "",
    });
    this.props.tryLogin(loginInfo);
  };

  showTpl = () => {
    this.setState({
      showTpl: true,
    });
  };

  hideTpl = () => {
    if (this.state.phone === "") {
      this.setState({
        showTpl: false,
      });
    }
  };

  handleClick = () => {
    this.props.navigate("/login/sms-verefication");
  };

  tryClearError = () => {
    this.props.clearError();
  };

  clearInputField = () => {
    this.setState({
      phone: "",
    });
  };

  render() {
    const { loginStatus, blackList, showMAlert, errorStatus, rpcLoaded, rpcCountryInfo } = this.props;

    if (rpcLoaded) {
      return <Loader />;
    }

    if (loginStatus) {
      return <Navigate to="/orders" />;
    }

    if (blackList) {
      this.setState({
        mainAlertText: t("account_blocked"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.clearLogin();
    }

    return (
      <>
        {showMAlert ? <ModalAlert mainAlertText={this.state.mainAlertText} /> : null}

        <div className="main-section">
          <div className="container">
            <div className="auth-section__main">
              <MobileTopBarHeight />
              <div className="auth_icon">
                {!this.state.isCorrectPhone ? (
                  <BackIcon className="back-icon" onClick={() => this.props.navigate("/")} />
                ) : (
                  <CloseIcon className="close-icon" onClick={() => this.props.navigate("/")} />
                )}
              </div>
              <div className="auth-section__login">
                <div className="login_title">
                  <p className="login-title__auth">{t("authorization")}</p>
                  <p className="login-title__info">{t("enter_phone_number_for_login")}</p>
                </div>
              </div>
              <div className="auth_phone-block">
                <CounterSelector
                  country={rpcCountryInfo?.country_code?.toLowerCase() || "sk"}
                  countryCodeEditable={false}
                  enableSearch
                  error={this.props.registration === false}
                  errorText={t("user_not_found")}
                  handleClearValue={this.clearInputField}
                  onChange={this.ChgPhone}
                  placeholder={t("phone_number")}
                  required
                  setCountryPhoneCode={this.props.setCountryPhoneCode}
                  value={this.state.phone}
                />
              </div>
              <div className="auth-btn_continue_block">
                <Button
                  className={classNames("continue_btn", { btn_disabled: !this.state.isCorrectPhone }, [])}
                  disabled={!this.state.isCorrectPhone}
                  onClick={() => this.tryEnter()}
                >
                  {t("continue")}
                </Button>
              </div>
              <div className="footer-info">
                <p>{t("not_driver")} CARMA TAXI?</p>
                <Link className="link_register" to="/registration">
                  {t("sing_up")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.login.loginStatus,
    registration: state.login.registration,
    checkphone: state.login.checkphone,
    wrongPass: state.login.wrongPass,
    goBack: state.chkCode.goBack,
    message: state.login.message,
    blackList: state.login.blackList,
    showMAlert: state.modalAlert.showMAlert,
    errorStatus: state.login.errorStatus,
    errorMsg: state.login.errorMsg,
    countryCode: state.login.countryCode,
    rpcLoaded: state.registration.rpcLoaded,
    rpcCountryInfo: state.registration.rpcCountryInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tryLogin: (loginInfo) => dispatch(tryLogin(loginInfo)),
    refStatusPass: () => dispatch(refStatusPass()),
    clearLogin: () => dispatch(clearLogin()),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    clearError: () => dispatch(clearError()),
    clearReg: () => dispatch(clearReg()),
    clearOrder: () => dispatch(clearOrder()),
    clearCars: () => dispatch(clearCars()),
    clearCards: () => dispatch(clearCards()),
    sendCoordsToRpc: () => dispatch(sendCoordsToRpc()),
    clearRpcError: () => dispatch(clearRpcError()),
    setCountryPhoneCode: (countryCode) => dispatch(setCountryPhoneCode(countryCode)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withNavigation(LoginPage)));

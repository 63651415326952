import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../axios.config";
import { apinew, orderApi } from "../../config";

export const GET_BRANDS = "GET_BRANDS";
export const GET_MODELS = "GET_MODELS";
export const GET_COLORS = "GET_COLORS";
export const GET_CLASSES = "GET_CLASSES";
export const GET_PREFS = "GET_PREFS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CIRCULAR_INFO = "CIRCULAR_INFO";

export const getBrands = createAsyncThunk("brands/getBrands", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/catalog/brands`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200 || response.status === 204) {
      dispatch({ type: GET_BRANDS, payload: response.data.data });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getInfoCircularStep = (circlarProgressInfo) => {
  return (dispatch) => {
    dispatch({
      type: CIRCULAR_INFO,
      payload: circlarProgressInfo,
    });
  };
};

export const getModels = createAsyncThunk("models/getModels", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/catalog/models`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200 || response.status === 204) {
      dispatch({ type: GET_MODELS, payload: response.data.data });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getColors = createAsyncThunk("catalog/getColors", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/catalog/colors`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200 || response.status === 204) {
      dispatch({ type: GET_COLORS, payload: response.data.data });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getClasses = createAsyncThunk("catalog/getClasses", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/catalog/classes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200 || response.status === 204) {
      dispatch({ type: GET_CLASSES, payload: response.data.data });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPreferences = createAsyncThunk(
  "driver/getPreferences",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${orderApi}/driver/preferences`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200 || response.status === 204) {
        dispatch({ type: GET_PREFS, payload: response.data.data });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

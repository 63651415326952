import React from "react";
import styles from "./styles.module.scss";
import { classNames } from "../../shared/lib/classNames/classNames";

const PageLayout = ({ children, className }) => {
	return (
		<section className={classNames(styles.PageLayoutWrapper, {}, [className])}>
			{children}
		</section>
	);
};

export default PageLayout;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { clearLocalStorageExceptKeys } from "src/services/clearLocalStorageExceptKeys";

import { notRemovedItemsLocalStore } from "src/shared/constants";

import { CLEAR_TOKEN } from "../../app/app.actions";
import { axiosInstance } from "../../axios.config";
import { api } from "../../config";
import { onLogin } from "src/shared/funcForMobile";

export const SHOW_PRELOADER = "SHOW_PRELOADER";
export const HIDE_PRELOADER = "HIDE_PRELOADER";
export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const LOGGED_FAILD = "LOGGED_FAILD";
export const UPD_USER_HASH = "UPD_USER_HASH";
export const NO_REG = "NO_REG";
export const AUTH = "AUTH";
export const CHK_PHONE = "CHK_PHONE";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const BLACK_LIST = "BLACK_LIST";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const UPD_LOGIN = "UPD_LOGIN";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const SET_COUNTRY_PHONE_CODE = "SET_COUNTRY_PHONE_CODE";

export const setCountryPhoneCode = (countryCode) => {
  return (dispatch) => {
    dispatch({
      type: SET_COUNTRY_PHONE_CODE,
      payload: countryCode,
    });
  };
};

export const logout = () => {
  clearLocalStorageExceptKeys(notRemovedItemsLocalStore);
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
    /*  dispatch({
        type: CLEAR_ORDER,
      })*/
    dispatch({
      type: HIDE_PRELOADER,
    });
  };
};

export const updAccessToken = createAsyncThunk(
  "login/updAccessToken",
  async (depFuncs, { dispatch, rejectWithValue }) => {
    if (!localStorage.getItem("refreshToken")) {
      localStorage.clear();
      dispatch({ type: CLEAR_LOGIN });
    } else {
      const refreshToken = localStorage.getItem("refreshToken");

      try {
        const newTokens = await axiosInstance.post(`${api}/tokens/${refreshToken}/refresh`, {});

        localStorage.setItem("accessToken", newTokens.data.accessToken);

        if (depFuncs) {
          depFuncs();
        }
      } catch (error) {
        //!TODO: CTD-204
        if (error.message === "Network Error") {
          alert("Произошла непредвиденная ошибка, проверьте ваше интернет соеденение");
        } else {
          localStorage.clear();
          dispatch({ type: CLEAR_LOGIN });
        }
        return rejectWithValue(error);
      }
    }
  },
);

export const tryLogin = createAsyncThunk(
  "driver/tryLogin",
  async (loginInfo, { dispatch, rejectWithValue }) => {
		localStorage.setItem("userPhone", loginInfo.phone);
    dispatch({ type: SHOW_PRELOADER });

    try {
      if (loginInfo.password === undefined) {
        const response = await axiosInstance.post(api + "/driver/check", {
					phone: loginInfo.phone,
        });
        if (response?.status === 200 || response?.status === 204) {
          dispatch({
            type: CHK_PHONE,
            payload: loginInfo.phone,
          });
        } else {
          dispatch({
            type: LOGGED_FAILD,
            payload: {
              loginStatus: false,
              message: "Номер не зарегистрирован!",
              phone: loginInfo.phone,
              wrongPass: false,
            },
          });
        }
        dispatch({ type: HIDE_PRELOADER });
      } else {
        const response = await axiosInstance.post(api + "/driver/sign-in", {
          phone: loginInfo.phone,
          password: loginInfo.password,
          branchId: 1,
        });
        if (response?.status === 200 || response?.status === 204) {
          localStorage.setItem("accessToken", response?.data.accessToken);
          localStorage.setItem("refreshToken", response?.data.refreshToken);
          dispatch({
            type: LOGGED_IN,
            payload: true,
          });
        }

        onLogin();
        dispatch({ type: HIDE_PRELOADER });
      }
    } catch (error) {
      if (error.response && error.response?.data.Data === "user_in_blacklist") {
        dispatch({
          type: BLACK_LIST,
        });
      } else if (error.response && error.response?.data.Data === "user_blocked") {
        dispatch({
          type: BLACK_LIST,
        });
      } else if (error.response && error.response?.data.Data === "invalid_password") {
        dispatch({
          type: LOGGED_FAILD,
          payload: {
            loginStatus: false,
            message: "Неверный логин или пароль",
            phone: loginInfo.login,
            wrongPass: true,
          },
        });
      } else {
        dispatch({
          type: NO_REG,
          payload: loginInfo.login,
        });
      }

      return rejectWithValue(error);
    }
  },
);

export const updUserHashInStore = (userHash) => {
  return (dispatch) => {
    dispatch({
      type: UPD_USER_HASH,
      payload: userHash,
    });
  };
};

export const clearLogin = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LOGIN,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

import {
  SET_COORDS,
  CLEAR_TOKEN,
  ORDER_REQUEST_MANAGE,
  CHANGE_COLOR_THEME,
} from "./app.actions";

const initialState = {
  coords: {
    lat: 49.0275,
    lng: 31.48278,
  },
  tokenStatus: true,
  redirectStatus: false,
  colorTheme: localStorage.getItem("theme")
    ? localStorage.getItem("theme") === "true"
    : true,
  newOrderRequestManage: true,
};

function app(state = initialState, action) {
  switch (action.type) {
    case SET_COORDS:
      return { ...state, coords: { ...action.payload } };
    case CLEAR_TOKEN:
      return { ...state, tokenStatus: action.payload };
    case CHANGE_COLOR_THEME:
      let mode = !state.colorTheme;
      return { ...state, colorTheme: mode };
    case ORDER_REQUEST_MANAGE:
      return { ...state, newOrderRequestManage: action.payload };
    default:
      return { ...state };
  }
}
export default app;

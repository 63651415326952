import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as BackIcon } from '../../images/back.svg'

import { t } from 'i18next'

import './apkrules.scss'
import { getRedirectPage } from '../../services/getRedirectPage'
import MobileTopBarHeight from 'src/shared/ui/MobileTopBarHeight'
import withNavigation from 'src/hoc/NavigateForClass'

class ApkRules extends Component {
  goBack = () => {
    this.props.navigate(-1)
  }

  render() {
    return (
      <div className="container-fluid p-0 m-0">
        <div className="row m-0">
          <div className="apkrules-section col-sm-12 p-0">
            <MobileTopBarHeight />
            <div className="top-nav">
              <div className="top-nav_back" onClick={this.goBack}>
                <BackIcon className="ArrowLeft" />
              </div>
              <div className="top-nav_text">
                <p>{t('terms_of_offer')}</p>
              </div>
            </div>
            <div className="main-text">
              <p className="paragraph">{t('main_text_paragraph')}</p>
              <p className="paragraph">{t('main_text_paragraph_first')}</p>
              <p className="paragraph">{t('main_text_paragraph_second')}</p>
              <p className="paragraph">{t('main_text_paragraph_third')}</p>
              <p className="new-line">{t('main_text_paragraph_fourth')}</p>
              <p className="paragraph">{t('main_text_paragraph_fifth')}</p>
              <p className="new-line">{t('main_text_paragraph_sixth')}</p>
              <p className="paragraph">{t('main_text_paragraph_seventh')}</p>
              <p className="paragraph">{t('main_text_paragraph_eighth')}</p>
              <p className="paragraph">{t('main_text_paragraph_ninth')}</p>
              <p className="paragraph">{t('main_text_paragraph_tenth')}</p>
              <p className="paragraph">{t('main_text_paragraph_eleventh')}</p>
              <p className="paragraph">{t('main_text_paragraph_twelfth')}</p>
              <p className="new-line">{t('main_text_paragraph_thirteenth')}</p>
              <p className="paragraph">{t('main_text_paragraph_fourteenth')}</p>
              <p className="paragraph">{t('main_text_paragraph_fifteenth')}</p>
              <p className="new-line">{t('main_text_paragraph_sixteenth')}</p>
              <p className="paragraph">
                {t('main_text_paragraph_seventeenth')}
              </p>
              <p className="paragraph">{t('exemption_from_liability')}</p>
              <p className="new-line">{t('main_text_paragraph_eighteenth')}</p>
              <p className="new-line">
                {t('main_text_breach_user_agreement_first')}
              </p>
              <p className="new-line">
                {t('main_text_breach_user_agreement_second')}{' '}
              </p>
              <p className="new-line">
                {t('main_text_breach_user_agreement_third')}
              </p>
              <p className="new-line">{t('main_text_paragraph_twentieth')}</p>
              <p className="new-line">{t('main_text_paragraph_twentyFirst')}</p>
              <p className="new-line">
                {t('main_text_paragraph_twentySecond')}
              </p>
              <p className="paragraph">
                {t('main_text_paragraph_twentyThird')}
              </p>
              <p className="new-line">{t('main_text_paragraph_twentyFour')}</p>
              <p className="paragraph">{t('main_text_paragraph_twentyFour')}</p>
              <p className="paragraph">
                {t('main_text_paragraph_twentyFifth')}
              </p>
              <p className="paragraph">{t('main_text_paragraph_twentySix')}</p>
              <p className="paragraph">
                {t('main_text_paragraph_twentySeventh')}
              </p>
              <p className="paragraph">
                {t('main_text_paragraph_twentyEight')}
              </p>
              <p className="new-line">{t('main_text_paragraph_twentyNinth')}</p>
              <p className="paragraph">{t('main_text_paragraph_thirtieth')}</p>
              <p className="new-line">{t('main_text_paragraph_thirtyFirst')}</p>
              <p className="new-line">
                {t('main_text_paragraph_thirtySecond')}
              </p>
              <p className="paragraph">{t('confidentiality')}</p>
              <p className="new-line">{t('main_text_paragraph_thirtyThird')}</p>
              <p className="paragraph">{t('concluding_provisions')}</p>
              <p className="new-line">{t('main_text_paragraph_thirtyFour')}</p>
              <p className="new-line">{t('main_text_paragraph_thirtyFifth')}</p>
              <p className="new-line">{t('main_text_paragraph_thirtySix')}</p>
              <p className="paragraph">
                {t('main_text_paragraph_thirtySeventh')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.login.loginStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigation(ApkRules))

import React, {Component, Fragment } from 'react'
import {NavLink} from 'react-router-dom'
import { connect } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'
import moment from 'moment/moment'

import './notifications.scss'
import { t } from 'i18next'

const formatDateFunc = date => {
    const format = {
      date: 'DD.MM.YYYY | HH:mm',
    }
    return `${moment(moment.utc(date).toDate()).format(format.date)}`
  }

class Notifications extends Component {

render() {
    const { article } = this.props
    
return (
<Fragment>
<div className="container-fluid p-0 m-0">
        <div className="row m-0">
            <div className="article-section col-sm-12 p-0">
                <div className="top-nav">
                    <NavLink to="/mainnews"><FaArrowLeft className="ArrowLeft"/></NavLink>
                    <p className="top-nav-text">{t('notifications')}</p>
                </div>
                <div className="article-block">
                    <p className="theme">{article.title}</p>
                    <p className="text">{article.content}</p>
                    <p className="date">{formatDateFunc(article.date)}</p>  
                </div>
            </div>
        </div>
	</div>    
</Fragment>
)

}
}
const mapStateToProps = state => {
    return {
        article: state.mainnews.notification,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
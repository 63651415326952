import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ErrorTypes } from "src/shared/const/errors";
import { Button } from "src/shared/ui/Button/button";
import Icon from "src/shared/ui/Icon/Icon";
import { Modal, ModalPosition } from "src/shared/ui/Modal";
import Text, { TextAlign, TextSize, TextTheme, TextWeight } from "src/shared/ui/Text";

import { ReactComponent as RepairIcon } from "src/images/error-icons/repair-icon.svg";
import { ReactComponent as WarningIcon } from "src/images/error-icons/warning-icon.svg";
import { ReactComponent as HeadphonesIcon } from "src/images/headphones.svg";

import styles from "./styles.module.scss";

const ModalError = (props) => {
  const { isOpen, maintenanceError, title, errorType, showSupportBtn, onClose } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToSupportPage = () => {
    navigate("/contactus");
    onClose();
  };

  const errorMessageTranslates = {
    [ErrorTypes.NETWORK_ERROR]: t("no_internet_connection"),
    [ErrorTypes.UNEXPECTED_ERROR]: t("generic_maintenance_message"),
    [ErrorTypes.MAINTENANCE_ERROR]: t("server_maintenance_message"),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} position={ModalPosition.BOTTOM}>
      <div className={styles.modalError}>
        <Icon Svg={maintenanceError ? RepairIcon : WarningIcon} className={styles.icon} />

        {title && (
          <Text
            align={TextAlign.CENTER}
            size={TextSize.FONT_24}
            text={t("unexpected_error_occurred")}
            weight={TextWeight.BOLD}
          />
        )}

        <Text
          align={TextAlign.CENTER}
          size={TextSize.FONT_16}
          text={errorMessageTranslates[errorType]}
          weight={TextWeight.LIGHT}
        />

        <div className={styles.btns}>
          <Button className={styles.okBtn} fullWidth onClick={onClose}>
            <Text
              align={TextAlign.CENTER}
              size={TextSize.FONT_18}
              text={t("ok")}
              weight={TextWeight.MEDIUM}
              theme={TextTheme.INVERTED}
            />
          </Button>

          {showSupportBtn && (
            <Button className={styles.supportBtn} fullWidth onClick={navigateToSupportPage} variant="outline">
              <Icon Svg={HeadphonesIcon} invertedFill />
              <Text
                align={TextAlign.CENTER}
                className={styles.supportBtnTitle}
                size={TextSize.FONT_18}
                text={t("customer_service")}
                weight={TextWeight.LIGHT}
              />
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalError);

import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { t } from "i18next";
import moment from "moment/moment";

import CounterSelector from "src/components/CountrySelector";

import { imageDefaultQuality } from "src/shared/constants";
import { compressImage } from "src/shared/helpers/compressImage";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

import { showUpdPassForm, sendImg, clearAddImgStatus, clearError } from "./editprofile.actions";
import { MAX_IMAGE_SIZE_MB } from "../../config-in-git";
import { ReactComponent as BackIcon } from "../../images/back.svg";
import { ReactComponent as FillCameraIcon } from "../../images/fill-camera.svg";
import noImg from "../../media/noimg.jpg";
import { updDriverInfo, resetUpdProfileStatus } from "../RegistrationPage/registration.actions";
import ChangePassword from "../ChangePassword";
import ModalConfirm from "../Modals/ModalConfirm";
import { showModalConfirm } from "../Modals/ModalConfirm/modalConfirm.actions";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";

import "./editprofile.scss";
import { getRedirectPage } from "../../services/getRedirectPage";
import { getDriverInfo } from "../RegistrationPage/registration.actions";
import { Button } from "../../shared/ui/Buttons/DefautlButton/button";
import { classNames } from "../../shared/lib/classNames/classNames";
import { Input } from "../../shared/ui/Input/inputs";
import Loader from "../Loader";

const nameDefaultRegex = new RegExp("^[а-яёєїіґ'‘]{0,20}$", "i");
const internationalNameRegex = new RegExp(/^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F'-]+$/u);
const chkEmail = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
);

const formatDateFunc = (date) => {
  const format = {
    date: "DD.MM.YYYY",
  };
  return `${moment(moment.utc(date).toDate()).format(format.date)}`;
};
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.fileUserInput = React.createRef();
  }

  state = {
    firstName: "",
    errorN: "",
    wrongName: "",

    lastName: "",
    errorS: "",
    wrongSName: "",

    middleName: "",
    errorM: "",
    wrongMName: "",

    email: "",
    errorE: "",
    wrongEmail: "",

    phone: "",
    errorPN: "",
    wrongPhone: "",

    dateOfBirth: "",
    errorBD: "",
    wrongBD: "",

    gender: "",
    photo: "",
    driverLicenseF: "",
    regionCode: null,

    showSub: false,
    showBotSub: false,
    showDocFrontSub: false,
    showDocBackSub: false,

    mainConfirmText: "",
    mainAlertText: "",

    isOpen: false,
    isButtonDisabled: true,
    loader: false,
  };

  componentDidMount() {
    this.props.getDriverInfo();
    this.setDriverData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.driver) !== JSON.stringify(this.props.driver)) {
      this.setDriverData();
    }
  }

  setDriverData = () => {
    let driver = this.props.driver;
    console.log("setDriverData");

    console.log("this.props.driver", this.props.driver);

    if (driver) {
      let dateOfBirth;
      if (driver.dateOfBirth) {
        dateOfBirth = formatDateFunc(driver.dateOfBirth);
      } else {
        dateOfBirth = "";
      }
      this.setState({
        firstName: driver.firstName,
        lastName: driver.lastName,
        middleName: driver.middleName,
        dateOfBirth: dateOfBirth,
        phone: driver.phone,
        email: driver.email,
        gender: driver.gender,
        photo: driver.photo,
        driverLicenseF: driver.driverLicense,
        regionCode: driver.regionCode,
      });
    }
  };

  subMenu = () => {
    this.setState({
      showSub: !this.state.showSub,
    });
  };

  ChgEmail = (event) => {
    if (this.state.email?.length < 254) {
      this.setState({
        [event.target.name]: event.target.value,
        isButtonDisabled: false,
      });
    }
  };

  isNameValid = (name) => {
    const { driver } = this.props;

    if (!driver?.country || driver.country === "UA") {
      return name.length >= 2 && nameDefaultRegex.test(name);
    } else {
      return name.length >= 2 && internationalNameRegex.test(name);
    }
  };

  ChgFIO = ({ target }) => {
    const name = target.value.replace(/\s+/g, "").trim();
    const typeField = target.name;
    const isValid = this.isNameValid(name);

    this.setState({
      [typeField]: target.value,
      isButtonDisabled: !isValid,
      ...(typeField === "firstName" && { errorN: !isValid }),
      ...(typeField === "lastName" && { errorS: !isValid }),
      ...(typeField === "middleName" && { errorM: !isValid }),
    });
  };

  ChgBDate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updPass = () => {
    let showUPF = !this.props.showUPF;
    this.props.showUpdPassForm(showUPF);
  };

  botSub = () => {
    console.log("1");
    this.setState({
      showBotSub: !this.state.showBotSub,
    });
  };

  botDocFrontSub = () => {
    this.setState({
      showDocFrontSub: !this.state.showDocFrontSub,
    });
  };

  botDocBackSub = () => {
    this.setState({
      showDocBackSub: !this.state.showDocBackSub,
    });
  };

  showConfirm = () => {
    if (this.props.atwork) {
      this.setState({
        mainAlertText: t("finish_your_work_before_change"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    } else {
      this.setState({
        mainConfirmText: t("update"),
      });
      let status = !this.props.showMConfirm;
      this.props.showModalConfirm(status);
    }
  };

  updateDriverInfo = () => {
    if (this.props.atwork) {
      this.setState({
        mainAlertText: t("finish_your_work_before_change"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      return;
    }

    if (this.state.firstName.length < 2) {
      this.setState({
        errorN: true,
        wrongName: t("name_is_not_entered_correctly"),
      });
      return false;
    } else {
      this.setState({
        errorN: false,
        wrongName: "",
      });
    }

    if (this.state.lastName.length < 2) {
      this.setState({
        errorS: true,
        wrongSName: t("last_name_is_not_entered_correctly"),
      });
      return false;
    } else {
      this.setState({
        errorS: false,
        wrongSName: "",
      });
    }

    if (this.state.lastName.length < 2) {
      this.setState({
        errorM: true,
        wrongMName: t("middle_name_is_not_entered_correctly"),
      });
      return false;
    } else {
      this.setState({
        errorM: false,
        wrongMName: "",
      });
    }

    //    if ((this.state.email.length < 7) || (chkEmail.test(this.state.email) === false)) {
    if (this.state.email.length > 0) {
      if (chkEmail.test(this.state.email) === false) {
        this.setState({
          errorE: true,
          wrongEmail: t("email_is_not_entered_correctly"),
        });
        return false;
      } else {
        this.setState({
          errorE: false,
          wrongEmail: "",
        });
      }
    }

    console.log("his.props.driverPhoto", this.props.driverPhoto);
    console.log("this.state.photo", this.state.photo);

    const driverInfo = {
      phone: this.state.phone,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      middleName: this.state.middleName,
      gender: this.state.gender,
      photo: this.props.driverPhoto !== "" ? this.props.driverPhoto : this.state.photo,
      driverLicense: this.props.driverLicense !== "" ? this.props.driverLicense : this.state.driverLicenseF,
      dateOfBirth: this.props.dateOfBirth,
    };
    this.props.updDriverInfo(driverInfo);
  };

  photoToBase64 = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.name;
    const type = file.type.split("/")[0];

    if (file.size > 15728640 || type !== "image") {
      this.setState({
        mainAlertText:
          type !== "image" ? (
            t("only_photos_can_uploaded")
          ) : (
            <Trans i18nKey="image_exceeded_max_size" values={{ maxSize: MAX_IMAGE_SIZE_MB }} />
          ),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    } else {
      try {
        this.setState({
          loader: true,
        });
        const compressedFile = await compressImage(
          file,
          imageDefaultQuality.width,
          imageDefaultQuality.height,
          imageDefaultQuality.quality,
        );

        const reader = new FileReader();
        reader.onloadend = () => {
          let imgArr = reader.result.split(",");
          let imgInfo = {
            name: file.name,
            fileArray: imgArr[1],
            inputName: fileName,
          };
          this.props.sendImg(imgInfo);
          this.setState({
            isButtonDisabled: false,
          });
        };

        reader.readAsDataURL(compressedFile);
        this.setState({
          loader: false,
        });
      } catch (error) {
        console.error("Image compression error:", error);
        this.setState({
          mainAlertText: t("image_compression_error"),
        });
        let status = !this.props.showMAlert;
        this.props.showModalAlert(status);
      }
    }
  };

  clearInputValue = (name) => {
    this.setState({
      [name]: "",
    });
  };

  tryClearError = () => {
    this.props.clearError();
  };

  render() {
    const { showUPF, showMConfirm, addImgStatus, showMAlert, updProfileStatus, errorStatus, driver } =
      this.props;

    if (addImgStatus) {
      this.setState({
        mainAlertText: (
          <Trans i18nKey="image_uploaded_click_updateInfo_to_save" values={{ actionLabel: t("confirm") }} />
        ),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.clearAddImgStatus();
    }

    if (updProfileStatus) {
      this.setState({
        mainAlertText: t("information_updated"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.resetUpdProfileStatus();
    }
    return (
      <>
        {showUPF ? <ChangePassword /> : null}
        {showMAlert ? <ModalAlert mainAlertText={this.state.mainAlertText} /> : null}
        {showMConfirm ? (
          <ModalConfirm
            confirmHandler={this.updateDriverInfo.bind(this)}
            mainConfirmText={this.state.mainConfirmText}
          />
        ) : null}

        <div className="edit-profile-section">
          <MobileTopBarHeight />
          <div className="container">
            <div
              className={classNames("edit-profile-section__main", { hidden_block: this.state.isOpen }, [])}
            >
              <Link className="navigation-block" to={getRedirectPage("/dashboard")}>
                <BackIcon className="icon-nav" />
              </Link>
              <div className="title-info_block">
                <div>
                  <h1>{t("edit_profile")}</h1>
                </div>
                <div
                  className="profile-img-block"
                  onClick={() => {
                    if (driver.partnerId) {
                      return;
                    }
                    this.fileUserInput.current.click();
                  }}
                >
                  <img alt="" className="profile-img" src={driver.photo ? driver.photo : noImg} />
                  <div className="camera-icon_block">
                    <FillCameraIcon className="camera_icon" />
                  </div>
                </div>
                <input
                  name="photo"
                  onChange={this.photoToBase64}
                  ref={this.fileUserInput}
                  style={{ display: "none" }}
                  type="file"
                />
              </div>

              <div className="edit-profile_block">
                <p>{t("name")}</p>
                <Input
                  className={classNames("", { error_field: this.state.errorN }, [])}
                  handleClearValue={() => this.clearInputValue("firstName")}
                  name="firstName"
                  onChange={this.ChgFIO}
                  placeholder={t("name")}
                  value={this.state.firstName}
                />
                {this.state.errorN ? <p id="error">{this.state.wrongName}</p> : null}
              </div>

              <div className="edit-profile_block">
                <p>{t("last_name")}</p>
                <Input
                  className={classNames("", { error_field: this.state.errorS }, [])}
                  handleClearValue={() => this.clearInputValue("lastName")}
                  maxLength={50}
                  name="lastName"
                  onChange={this.ChgFIO}
                  placeholder={t("last_name")}
                  value={this.state.lastName}
                />
                {this.state.errorS ? <p className="error">{this.state.wrongSName}</p> : null}
              </div>

              <div className="edit-profile_block">
                <p>{t("middle_name")}</p>
                <Input
                  className={classNames("", { error_field: this.state.errorM }, [])}
                  handleClearValue={() => this.clearInputValue("middleName")}
                  name="middleName"
                  onChange={this.ChgFIO}
                  placeholder={t("middle_name")}
                  value={this.state.middleName}
                />
                {this.state.errorM ? <p className="error">{this.state.wrongMName}</p> : null}
              </div>

              <div className="phone-block">
                <p>{t("mobile")}</p>
                <CounterSelector
                  disableDropdown
                  disabled
                  errorText={this.state.wrongPhone}
                  handleClearValue={() => this.clearInputValue("phone")}
                  onChange={this.ChgPhone}
                  placeholder={t("phone_number")}
                  readOnly
                  value={this.state.phone}
                />
              </div>

              <div className="edit-profile_block">
                <p>{t("email")}</p>
                <Input
                  className={classNames("", { error_field: this.state.errorE }, [])}
                  handleClearValue={() => this.clearInputValue("email")}
                  name="email"
                  onChange={this.ChgEmail}
                  placeholder={t("email")}
                  type="text"
                  value={this.state.email}
                />
                {this.state.errorE ? <p className="error">{this.state.wrongEmail}</p> : null}
              </div>

              <div className="edit-profile_block">
                <p>{t("city")}</p>
                <Input
                  disabled
                  name="city"
                  placeholder={t("city")}
                  readOnly
                  value={t(this.state.regionCode) || t("undefined")}
                />
              </div>

              <div className="confirm_block">
                <Button
                  className={classNames("confirm_btn", { btn_disabled: this.state.isButtonDisabled }, [])}
                  disabled={this.state.isButtonDisabled}
                  onClick={this.updateDriverInfo}
                >
                  {t("confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showUPF: state.editprofile.showUPF,
    driverPhoto: state.editprofile.driverPhoto,
    driverLicense: state.editprofile.driverLicense,
    updProfInfoStatus: state.registration.updProfInfoStatus,
    showMConfirm: state.modalConfirm.showMConfirm,
    addImgStatus: state.editprofile.addImgStatus,
    showMAlert: state.modalAlert.showMAlert,
    updProfileStatus: state.registration.updProfileStatus,
    errorStatus: state.editprofile.errorStatus,
    errorMsg: state.editprofile.errorMsg,
    atwork: state.orders.workStatus,
    cityList: state.ProfileReducer.cityList,
    driver: state.registration.profinfo,
    countryCode: state.login.countryCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showUpdPassForm: (showUPF) => dispatch(showUpdPassForm(showUPF)),
    updDriverInfo: (driverInfo) => dispatch(updDriverInfo({ driverInfo })),
    sendImg: (imgInfo) => dispatch(sendImg(imgInfo)),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    clearAddImgStatus: () => dispatch(clearAddImgStatus()),
    resetUpdProfileStatus: () => dispatch(resetUpdProfileStatus()),
    clearError: () => dispatch(clearError()),
    getDriverInfo: () => dispatch(getDriverInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

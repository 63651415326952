import { GET_DRIVER_INVOICES } from './invoices.actions'

const initialState = {
  driverInvoices: []
}

function invoices(state = initialState, action) {
  switch (action.type) {
    case GET_DRIVER_INVOICES:
      return { ...state, driverInvoices: action.payload }
    default:
      return { ...state }
  }
}
export default invoices

import React from "react";

import { ReactComponent as DoubleArrowDark } from "src/images/DoubleArrowDark.svg";

import styles from "./styles.module.scss";

interface IProps {
  highDemandColor?: string;
}

const HighDemandColor = (props: IProps) => {
  const { highDemandColor = "#ffff" } = props;
  return (
    <div  className={styles.orderHeaderDoubleArrowWrap}>
      <DoubleArrowDark
        className={styles.orderHeaderDoubleArrow}
        style={{ fill: highDemandColor, stroke: highDemandColor }}
      />
    </div>
  );
};

export default HighDemandColor;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useClearUrlParams = () => {
    const navigate = useNavigate();

  const clearUrlParams = () => {
    const currentUrl = location.pathname; // Get the current pathname
    navigate(currentUrl);
  };

  return clearUrlParams;
};

export default useClearUrlParams;
